import { IProductOptions } from "../../../types/IProductOptions";
import { IonCard, IonCardTitle, IonCardHeader } from "@ionic/react";
import "./OptionCard.css";

interface IOptionCardParams {
  selectedStyle: IProductOptions | undefined;
  setSelectedStyle: (style: IProductOptions) => void;
  style: IProductOptions;
}

const OptionCard: React.FC<IOptionCardParams> = ({
  selectedStyle,
  setSelectedStyle,
  style,
}) => {
  return (
    <IonCard
      className={`option-card ion-no-margin ${
        selectedStyle?.id === style.id ? "option-card-selected" : ""
      }`}
      onClick={() => setSelectedStyle(style)}
    >
      <div className="option-card-item-img-container">
        <img
          alt="wristband"
          src={`storage/${style.swatchValue}`}
          className="ion-card-image"
        />
      </div>

      <div className="option-card-divider"></div>
      <IonCardHeader className="option-card-title-container ion-no-padding">
        <IonCardTitle className="option-card-title">
          {style.adminName}
        </IonCardTitle>
      </IonCardHeader>
    </IonCard>
  );
};
export default OptionCard;
