/*
 *Getting data about Prices' groups
 */
import axios, { AxiosResponse } from "axios";

/*Getting Access Token*/
const getProductPrices = (slug: string): Promise<AxiosResponse> => {
  /*Getting the product by slug*/
  return axios
    .request({
      method: "POST",
      url: `/graphql`,
      headers: {
        "Content-type": "application/json",
      },
      data: JSON.stringify({
        query: `    query product ($slug: String!){
          productBySlug(slug: $slug) {
            product {
              variants {
                id
                superAttributesValues{
                  admin_name
                  value
                  code
                }
                customerGroupPrices {
                  qty
                  value
                }
                productFlats {
                  price
                  name
                }
              }
            }
          }
        }
    `,
        variables: {
          slug: `${slug}`,
        },
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export default getProductPrices;
