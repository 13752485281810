// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field:not(:last-child) {
    margin-bottom: 1rem !important;
}
 
.customInput {
  margin-top: 0.25rem;
  transition: all 0.2s linear;
  max-width: 300px;
  min-width: 50px;
  border: 1px solid #eaeaea;
  color: #92949c;
  margin: 7px 7px 0 0;
  background-color: #fff;
}

.customSelect {
    margin-top: 0.25rem;
    max-width: 300px;
    min-width: 50px;
    border: 1px solid #eaeaea;
    background-color: #fff;
}

.customTextarea {
    margin-top: 0.25rem;
    max-width: 300px;
    min-width: 50px;
    border: 1px solid #eaeaea;
    background-color: #fff;
}

.errorBorder {
    border: 1px solid #eb445a;
}

.errorText {
    color: #eb445a !important;
    font-size: 13px !important;
    margin-top: 5px !important;
}

/****Media****/
@media (max-width: 991px) {
    .field ion-label {
        padding-left: 0.2rem;
        padding-right: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
    }
    .customInput .customSelect .customTextarea {
        max-width: 270px;
    }
}

@media (max-width: 650px) {
    .customInput .customSelect .customTextarea {
        max-width: 270px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/FormInputs/CustomField.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA,cAAc;AACd;IACI;QACI,oBAAoB;QACpB,qBAAqB;QACrB,aAAa;QACb,8BAA8B;QAC9B,qBAAqB;QACrB,mBAAmB;IACvB;IACA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".field:not(:last-child) {\n    margin-bottom: 1rem !important;\n}\n \n.customInput {\n  margin-top: 0.25rem;\n  transition: all 0.2s linear;\n  max-width: 300px;\n  min-width: 50px;\n  border: 1px solid #eaeaea;\n  color: #92949c;\n  margin: 7px 7px 0 0;\n  background-color: #fff;\n}\n\n.customSelect {\n    margin-top: 0.25rem;\n    max-width: 300px;\n    min-width: 50px;\n    border: 1px solid #eaeaea;\n    background-color: #fff;\n}\n\n.customTextarea {\n    margin-top: 0.25rem;\n    max-width: 300px;\n    min-width: 50px;\n    border: 1px solid #eaeaea;\n    background-color: #fff;\n}\n\n.errorBorder {\n    border: 1px solid #eb445a;\n}\n\n.errorText {\n    color: #eb445a !important;\n    font-size: 13px !important;\n    margin-top: 5px !important;\n}\n\n/****Media****/\n@media (max-width: 991px) {\n    .field ion-label {\n        padding-left: 0.2rem;\n        padding-right: 0.5rem;\n        display: flex;\n        justify-content: space-between;\n        align-content: center;\n        align-items: center;\n    }\n    .customInput .customSelect .customTextarea {\n        max-width: 270px;\n    }\n}\n\n@media (max-width: 650px) {\n    .customInput .customSelect .customTextarea {\n        max-width: 270px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
