import React, { useState, useEffect, useRef } from "react";

import { IonItem, IonList, IonImg, IonIcon } from "@ionic/react";
import "./MessageForm.css";
import { IProductOptions } from "../../../../types/IProductOptions";
import OptionButton from "../../../../components/Store/OptionButton/OptionButton";
import inputIcon from "../../../../assets/icon/input-icon.svg";

interface IMessageFormParams {
  messageStyle: IProductOptions | undefined;
  setMessageStyle: (style: IProductOptions) => void;
  messageFront: string;
  setMessageFront: (messageFront: string) => void;
  messageBack: string;
  setMessageBack: (messageBack: string) => void;
  wraparoundMessage: string;
  setWraparoundMessage: (wraparoundMessage: string) => void;
  fontFamily: IProductOptions | undefined;
  setFontFamily: (fontFamily: IProductOptions) => void;
  fontSize: IProductOptions | undefined;
  setFontSize: (fontSize: IProductOptions) => void;
  fontFamilyOptions: IProductOptions[];
  fontSizeOptions: IProductOptions[];
  fontSizeValues: number[];
  selectedFontSizeValue: number;
  setSelectedFontSizeValue: (selectedSizeValue: number) => void;
  insideMessage: string;
  setInsideMessage: (insideMessage: string) => void;
  messageTypes: IProductOptions[] | undefined;
}

const MessageForm: React.FC<IMessageFormParams> = (props) => {
  const [isListOfPreviewsVisible, setIsListOfPreviewsVisible] = useState(false);

  //Setup positions for Font Sizes
  const sliderRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const updateLabelPosition = () => {
    const slider = sliderRef.current;
    const label = labelRef.current;
    if (slider && label) {
      const sliderWidth = slider.offsetWidth;
      const labelWidth = label.offsetWidth;
      const thumbWidth = 20; // Approximate width of the thumb. Adjust as needed.

      const max = props.fontSizeValues[props.fontSizeValues.length - 1];
      const min = props.fontSizeValues[0];
      const value = props.selectedFontSizeValue;

      // Calculate the thumb's percentage position along the slider
      const percent = (value - min) / (max - min);

      // Adjust the label position: calculate the center of the thumb and then subtract half the label's width
      const centerOfThumb =
        percent * (sliderWidth - thumbWidth) + thumbWidth / 2;
      const newLeftPosition = centerOfThumb - labelWidth / 2 + 15;

      // Update the label position
      label.style.left = `${newLeftPosition}px`;
    }
  };

  useEffect(() => {
    updateLabelPosition();
    window.addEventListener("resize", updateLabelPosition);
    return () => window.removeEventListener("resize", updateLabelPosition);
  }, [props.selectedFontSizeValue, props.fontSizeValues]);

  return (
    <div>
      <section className="order-section">
        <div className="order-section-header">
          <div className="order-page-container">
            <h2 className="order-section-title">Your Message:</h2>
          </div>
        </div>
        <div className="wide-options-container">
          <div className="option-message-container">
            <div className="message-options-style-container message-options-style-container-wide ">
              {props.messageTypes?.map(
                (optionItem: IProductOptions, index: number) => (
                  <OptionButton
                    key={index}
                    selectedOptionItem={props.messageStyle}
                    optionItem={optionItem}
                    setOptionItem={props.setMessageStyle}
                  />
                ),
              )}
            </div>
            {/*Front and Back Message Style*/}
            {props.messageStyle?.swatchValue === "front_and_back" && (
              <div className="messages-container message-container-two-inputs">
                <div className="input-message">
                  <label className="input-message-label">Front:</label>
                  <input
                    type="text"
                    className="input-message-field"
                    value={props.messageFront}
                    onChange={(e) => props.setMessageFront(e.target.value)}
                  />
                </div>
                <div className="input-message">
                  <label className="input-message-label">Back:</label>
                  <input
                    type="text"
                    className="input-message-field"
                    value={props.messageBack}
                    onChange={(e) => props.setMessageBack(e.target.value)}
                  ></input>
                </div>
              </div>
            )}
            {/*Wrap around Message Style*/}
            {props.messageStyle?.swatchValue === "wrap_around" && (
              <div className="messages-container message-container-one-input">
                <div className="wraparound-messages-container">
                  <label className="input-message-label">Message:</label>
                  <input
                    className="input-message-field input-message-field-wrap-around"
                    value={props.wraparoundMessage}
                    onChange={(e) => props.setWraparoundMessage(e.target.value)}
                  />
                </div>
              </div>
            )}
            {/*Font Sizes Options*/}
            <div className="font-style-options">
              <div className="font-size-slider">
                <label className="input-message-label">Font Size:</label>
                <div className="range-slider-container">
                  {props.selectedFontSizeValue != props.fontSizeValues[0] && (
                    <>
                      <div className="min-option-circle"></div>{" "}
                      <label className="min-label-option">
                        {props.fontSizeValues[0]}pt
                      </label>
                    </>
                  )}
                  <input
                    ref={sliderRef}
                    type="range"
                    min={props.fontSizeValues[0]}
                    max={props.fontSizeValues[props.fontSizeValues.length - 1]}
                    step="2"
                    value={props.selectedFontSizeValue}
                    onChange={(e) => {
                      props.setSelectedFontSizeValue(parseInt(e.target.value));
                    }}
                    className="range-slider"
                  />
                  <label
                    ref={labelRef}
                    htmlFor="fontSizeRange"
                    className="font-size-label"
                  >
                    {`${props.selectedFontSizeValue}pt`}
                  </label>
                  {props.selectedFontSizeValue !=
                    props.fontSizeValues[props.fontSizeValues.length - 1] && (
                    <>
                      <label className="max-label-option">
                        {props.fontSizeValues[props.fontSizeValues.length - 1]}
                        pt
                      </label>
                      <div className="max-option-circle"></div>
                    </>
                  )}
                </div>
              </div>

              {/*Font Style Options*/}

              <div className="message-font-style">
                <label className="input-message-label">Font Style:</label>
                <div className="previews-container">
                  <div
                    className="input-message-field input-message-font"
                    onClick={() =>
                      setIsListOfPreviewsVisible(!isListOfPreviewsVisible)
                    }
                  >
                    {props.fontFamily ? (
                      <IonImg
                        className="font-preview"
                        src={`storage/${props.fontFamily.attributeOptionPreview.image}`}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* Icon at the end of the input */}
                  <div className="input-icon">
                    <IonIcon icon={inputIcon} className="input-icon-style" />
                  </div>
                  {isListOfPreviewsVisible && (
                    <IonList className="preview-dropdown-list">
                      {props.fontFamilyOptions.map(
                        (font: IProductOptions, key: number) => (
                          <IonItem
                            className="font-preview-item"
                            key={key}
                            onClick={() => {
                              props.setFontFamily(font);
                              setIsListOfPreviewsVisible(false);
                            }}
                          >
                            <IonImg
                              className="font-preview"
                              src={`storage/${font.attributeOptionPreview.image}`}
                            />
                          </IonItem>
                        ),
                      )}
                    </IonList>
                  )}
                </div>
              </div>
            </div>

            {/*Inside Message Section*/}
            <div className="inside-message-container">
              <div className="input-message">
                <label className="input-message-label">Inside:</label>
                <input
                  type="text"
                  className="input-message-field"
                  value={props.insideMessage}
                  onChange={(e) => props.setInsideMessage(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default MessageForm;
