import { IonButton, IonIcon } from "@ionic/react";
import "./Button.css";
import arrow from "../../assets/icon/arrow.svg";

interface IButtonParams {
  id?: string | undefined;
  text: string;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string | undefined;
  onClick?(event: any): void;
  color?: string | undefined;
  disabled?: boolean | undefined;
}

const Button: React.FC<IButtonParams> = (props) => {
  let classNames = "button filled-btn ion-text-wrap";
  if (props.className && typeof props.className === "string") {
    classNames = `${classNames} ${props.className}`;
  }

  let buttonType = undefined;

  if (props.type) {
    buttonType = props.type;
  }

  let defaultColor = "secondary";

  if (props.color && typeof props.color === "string") {
    defaultColor = props.color;
  } else if (props.color === "") {
    defaultColor = props.color;
  }

  return (
    <IonButton
      id={props.id}
      type={buttonType}
      className={classNames}
      onClick={props.onClick}
      color={defaultColor}
      disabled={props.disabled}
    >
      <IonIcon className="filled-btn-arrow" slot="end" icon={arrow} />
      <span className="button-text">{props.text}</span>
    </IonButton>
  );
};

export default Button;
