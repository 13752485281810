import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { FormEvent, useEffect, useState, useContext } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useParams } from "react-router";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import CustomField from "../../components/FormInputs/CustomField";
import Header from "../../components/Header/Header";
import SectionTitle from "../../components/Titles/SectionTitle";
import { useRegisterFields } from "../../functions/authInputFields";
import "./Auth.css";
import { IFormInputError } from "../../types/IFormInputError";
import { validateForm, googleLogin } from "../../functions/utils";
import axios from "axios";
import { Action } from "../../components/FormInputs/Action";
import { IFormValidationRule } from "../../types/IFormValidationRule";
import { IFormField } from "../../types/IFormField";
import { CurrentCustomerContext } from "../../contexts/currentCustomer";
import SocialButton from "../../components/Button/SocialButton";

const Register: React.FC = () => {
  const params = useParams();
  const fields = useRegisterFields();
  const initialErrors: IFormInputError[] = [];
  const [errors, setErrors] = useState(initialErrors);
  const [, setToken] = useLocalStorage("token");
  const [, setCustomer] = useLocalStorage("customer");
  const [, dispatch] = useContext(CurrentCustomerContext);
  const [loading, setLoading] = useState(false);

  const getValidationRules = (fields: IFormField[]): IFormValidationRule[] => {
    const rules: IFormValidationRule[] = [];

    // name rules
    const firstNameRule: IFormValidationRule = {
      fieldId: "first_name",
      validate(field: IFormField): IFormInputError | null | undefined {
        const name: string | undefined = field.input.state.value;
        const id = "first_name";
        if (!(name && name.length && name.trim() !== "")) {
          return { id, message: "Please enter your first name." };
        }

        if (name.length < 2) {
          return { id, message: "First name must be at least 2 characters." };
        }

        return null;
      },
    };

    const lastNameRule: IFormValidationRule = {
      fieldId: "last_name",
      validate(field: IFormField): IFormInputError | null | undefined {
        const name: string | undefined = field.input.state.value;
        const id = "last_name";
        if (!(name && name.length && name.trim() !== "")) {
          return { id, message: "Please enter your last name." };
        }

        if (name.length < 2) {
          return { id, message: "Last name must be at least 2 characters." };
        }

        return null;
      },
    };

    const passwordRules: IFormValidationRule = {
      fieldId: "password",
      validate(field: IFormField): IFormInputError | null | undefined {
        const password: string | undefined = field.input.state.value;
        const id = "password";

        if (!(password && password.length && password.trim() !== "")) {
          return { id, message: "The password is required." };
        }

        if (password.length < 6) {
          return { id, message: "Password must be at least 6 characters." };
        }

        return null;
      },
    };

    const passwordConfirmation: IFormValidationRule = {
      fieldId: "password_confirmation",
      validate(field: IFormField): IFormInputError | null | undefined {
        const confirm: string | undefined = field.input.state.value;
        const passField = fields.find((f) => f.id === "password");
        const password = passField && passField.input.state.value;
        const id = "password_confirmation";

        if (!(confirm && confirm.length && confirm.trim() !== "")) {
          return { id, message: "Please retype the same password." };
        }

        if (confirm.length < 6) {
          return { id, message: "Password must be at least 6 characters." };
        }

        if (password !== confirm) {
          return { id, message: "Password mismatch." };
        }

        return null;
      },
    };

    const emailRule: IFormValidationRule = {
      fieldId: "email",
      validate(field: IFormField): IFormInputError | null | undefined {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const id = "email";
        const email = field.input.state.value;

        if (!(email && email.length)) {
          return { id, message: "The email is required." };
        }

        if (!email.match(emailRegex)) {
          return { id, message: "Please enter a valid email address." };
        }

        return null;
      },
    };

    rules.push(firstNameRule);
    rules.push(lastNameRule);
    rules.push(passwordRules);
    rules.push(passwordConfirmation);
    rules.push(emailRule);

    return rules;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      registerHandler(event as unknown as FormEvent<HTMLFormElement>);
    }
  };

  const registerHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createAccount(event);
  };

  const createAccount = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validateForm(fields, getValidationRules(fields));
    setErrors(errors);

    if (errors.length) {
      return;
    }

    const formData: { [key: string]: string | undefined } = {};

    fields.forEach((field) => {
      formData[field.name] = field.input.state.value;
    });
    setLoading(true);
    axios
      .request({
        method: "POST",
        url: "/graphql",
        headers: {
          "Content-type": "application/json",
        },
        data: JSON.stringify({
          query: `mutation ($first_name: String!, $last_name: String!, $email: String!, $password: String!, $password_confirmation: String!) {
          customerRegister(input : {
          firstName: $first_name
          lastName: $last_name
          email: $email
          password: $password
          passwordConfirmation: $password_confirmation
        }) 
        {
          status
          accessToken
          customer {
            id
            firstName
            lastName
            email
            addresses {
              id
              address1
              address2
              city
              state
              postcode
            }
          }
        }
      }`,
          variables: {
            first_name: `${formData.first_name}`,
            last_name: `${formData.last_name}`,
            email: `${formData.email}`,
            password: `${formData.password}`,
            password_confirmation: `${formData.password_confirmation}`,
          },
        }),
      })
      .then((res) => {
        setLoading(false);
        if (res.data && res.data.errors) {
          if (res.data.errors[0] && res.data.errors[0].debugMessage) {
            throw Error(res.data.errors[0].debugMessage);
          } else {
            throw Error(JSON.stringify(res.data.errors[0]));
          }
        } else {
          if (res.data && res.data.data) {
            /** RESPONSE FORMAT of res.data.data
             * {
              "customerRegister": {
                "status": true,
                "accessToken": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vcmFwaWQyLXdlYnNlcnZlci9ncmFwaHFsIiwiaWF0IjoxNjc4MjkxNzc0LCJleHAiOjE2NzgyOTUzNzQsIm5iZiI6MTY3ODI5MTc3NCwianRpIjoiaEgzalpqaERoWDdLR2JLcCIsInN1YiI6IjIiLCJwcnYiOiI4ZmNhMDg4YWJhZTJmOWE4Zjg0YTVmMGJmNmE2NTI0NDkwNTViZTAwIn0.ZTq_OvSVLYSuLTpTH5u7POhYz6_LIxYGbPM2eYOjIF0",
                "customer": {
                  "id": "1",
                  "firstName": "Jane",
                  "lastName": "Doe",
                  "email": "jane@hello.com"
                }
              }
            }
              */
            if (res.data.data.customerRegister.status) {
              const accessToken = res.data.data.customerRegister.accessToken;
              const customerData = res.data.data.customerRegister.customer;

              if (accessToken && customerData) {
                // Set token in local storage or state
                setToken(accessToken);

                // Serialize customer data and set in local storage or state
                setCustomer(JSON.stringify(customerData));

                // Dispatching to context or Redux store
                dispatch({
                  type: "SET_AUTHORIZED",
                  payload: customerData,
                });

                // After setting both customer and token, navigate to the account page
                window.location.href = "/account";
              }
            }
          }
        }
      })
      .catch((err) => {
        const inputErrors: IFormInputError[] = [];
        if (err.message && typeof err.message === "string") {
          const tryParsingJson = (jsonString: string) => {
            try {
              const obj = JSON.parse(jsonString);

              if (obj && typeof obj === "object") {
                return obj;
              }
            } catch (e) {}

            return false;
          };
          const testObj = tryParsingJson(err.message);

          const checkForWords = (str: string, words: string[]) => {
            for (let i = 0; i < words.length; ++i) {
              if (str.toLowerCase().includes(words[i])) {
                return {
                  found: true,
                  word: words[i],
                };
              }
            }

            return { found: false, word: null };
          };

          const handleGenericErrors = () => {
            for (const [, value] of Object.entries(fields)) {
              if (typeof value === "object" && value.id) {
                inputErrors.push({
                  id: value.id,
                  message: "Error processing request.",
                });
              }
            }
          };

          if (typeof testObj === "object" && Object.keys(testObj).length > 0) {
            // if it contains things like extensions and locations, it shouldn't be shown to the user
            if ("extensions" in testObj && "locations" in testObj) {
              let fieldError = false;

              if (
                typeof testObj.extensions === "object" &&
                testObj.extensions.category &&
                testObj.extensions.category === "validation"
              ) {
                const registerErrorMap: {
                  [key: string]: {
                    id: string;
                    name: string;
                    other?: string | undefined;
                  };
                } = {
                  "input.email": { id: "email", name: "Email" },
                  "input.firstName": {
                    id: "first_name",
                    name: "First Name",
                    other: "input.first name",
                  },
                  "input.lastName": {
                    id: "last_name",
                    name: "Last Name",
                    other: "input.last name",
                  },
                  "input.password": { id: "password", name: "Password" },
                };

                const validationErrors: { [key: string]: string[] } =
                  testObj.extensions.validation;

                Object.keys(validationErrors).forEach((key) => {
                  const eId = registerErrorMap[key];
                  let msg = validationErrors[key][0].replaceAll(
                    key,
                    eId.name.toLowerCase(),
                  );

                  if (eId.other) {
                    msg = msg.replaceAll(eId.other, eId.name.toLowerCase());
                  }

                  inputErrors.push({
                    id: eId.id,
                    message: msg,
                  });

                  fieldError = true;
                });
              } else if (
                testObj.message &&
                typeof testObj.message === "string"
              ) {
                const keys = fields.map((item) => item.id);
                const found = checkForWords(testObj.message, keys);

                if (found.found && found.word) {
                  fieldError = true;
                  inputErrors.push({
                    id: found.word,
                    message: testObj.message,
                  });
                }
              }

              if (!fieldError) {
                for (const [, value] of Object.entries(fields)) {
                  if (typeof value === "object" && value.id) {
                    const msg =
                      (testObj.extensions && testObj.extensions.reason) ||
                      "Error processing request.";
                    inputErrors.push({
                      id: value.id,
                      message: msg.toLowerCase(),
                    });
                  }
                }
              }
            } else {
              handleGenericErrors();
            }
          } else {
            handleGenericErrors();
          }
        } else {
          console.log("NOT_DECIDED_ON_THE_AUTH_ERRORS", {
            error: err.response ? err.response : err,
          });
        }

        setErrors(inputErrors);
      });
  };

  useEffect(() => {
    return () => {
      fields.forEach((field) => field.input.state.reset(""));
      setErrors([]);
    };
  }, [params]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light" className="register-container">
        {loading && (
          <div className="form-overlay">
            <IonSpinner name="lines" />
          </div>
        )}
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Create Account</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid className="ion-padding">
              <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
                <IonCol
                  size="12"
                  size-md="6"
                  size-lg="6"
                  offset-lg="3"
                  offset-md="3"
                  className="text-align-left"
                >
                  <form id="registerForm" onSubmit={createAccount}>
                    {fields.map((field) => {
                      return (
                        <CustomField
                          key={field.id}
                          field={field}
                          errors={errors}
                          {...(field.id === "password_confirmation"
                            ? { onKeyDown: handleKeyPress }
                            : {})}
                        />
                      );
                    })}
                    <Button
                      id="registerBtn"
                      className="auth-cta"
                      text="Register"
                    ></Button>
                    <Action
                      message="Already have an account?"
                      text="Sign In"
                      link="/login"
                    />
                  </form>

                  <div className="split-line">
                    <hr />
                    <span>OR</span>
                    <hr />
                  </div>
                  <SocialButton
                    iconName="icon-google"
                    color="google-color"
                    className="google-login"
                    id="socialLoginBtn"
                    text="Login with Google"
                    onClick={googleLogin}
                  ></SocialButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Register;
