/*
 *Displaying main information about certain product for the Prices Page
 */
import React from "react";
import { IonHeader } from "@ionic/react";
import ButtonBlue from "../../../../components/Button/ButtonBlue";
import { IProductOptions } from "../../../../types/IProductOptions";
import { IProductVariants } from "../../../../types/IProductVariants";
import ProductPrice from "./ProductPrice/ProductPrice";

interface IPriceComponentParams {
  productGroup: IProductVariants[];
  activeStyle: IProductOptions;
}

const PriceComponent: React.FC<IPriceComponentParams> = (props) => {
  return (
    <>
      <IonHeader className="ion-no-border wristband-prices wristband-prices-desktop">
        <h3 className="prices-subtitle">{props.activeStyle.adminName}</h3>
        <ButtonBlue text={`Order ${props.activeStyle.adminName}`} />
      </IonHeader>
      {/*Getting the list of the small tables with the prices for certain sizes*/}
      {props.productGroup.map((item: IProductVariants, index: number) => (
        <ProductPrice key={index} productSize={item} />
      ))}
    </>
  );
};

export default PriceComponent;
