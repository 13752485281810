import { IonModal, IonCard, IonCardHeader, IonButton } from "@ionic/react";
import ColorCard from "../../../../../components/Store/ColorCard/ColorCard";
import React, { useState, useEffect } from "react";
import OptionButton from "../../../../../components/Store/OptionButton/OptionButton";
import { IAttributes } from "../../../../../types/IAttributes";
import { IProductOptions } from "../../../../../types/IProductOptions";
import { IColorCombination } from "../../../../../types/IColorCombination";
import { ICombinationField } from "../../../../../types/ICombinationField";
import { IAttributeOption } from "../../../../../types/IAttributeOption";

interface IQuantityFormParams {
  setStyleColorSelected: (style: IAttributes | undefined) => void;
  styleColorSelected: IAttributes | undefined;
  colorsCollection: IProductOptions[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  selectedCombination: number;
  updateCombination: (
    index: number,
    field: ICombinationField,
    value: number | IAttributeOption,
  ) => void;
  colorCombinations: IColorCombination[];
}

const ModalWindow: React.FC<IQuantityFormParams> = (props) => {
  const [colorsToShow, setColorsToShow] = useState<IProductOptions[]>([]);

  useEffect(() => {
    if (props.styleColorSelected) {
      setColorsToShow(props.styleColorSelected.options);
    } else {
      setColorsToShow(featuredColorsForMore);
    }
  }, [props.styleColorSelected]);

  const selectEightColorsFromEachGroup = (gallery: any) => {
    return gallery.reduce(
      (acc: any, group: any) => acc.concat(group.options.slice(0, 8)),
      [],
    );
  };
  const featuredColorsForMore = selectEightColorsFromEachGroup(
    props.colorsCollection,
  );

  return (
    <IonModal
      style={{ "--backdrop-opacity": "0" }}
      isOpen={props.showModal}
      onDidDismiss={() => props.setShowModal(false)}
      className="modal-container"
    >
      {/* Modal content here */}

      <div className="order-section-header">
        <div className="order-page-container">
          <h2 className="order-section-title">Band Color:</h2>
        </div>
      </div>
      <div className="order-page-container">
        <div className="color-styles-container">
          <IonButton
            fill={!props.styleColorSelected ? "outline" : "clear"}
            className={`option-button ion-no-margin ${
              !props.styleColorSelected ? "option-button-selected" : ""
            }`}
            onClick={() => props.setStyleColorSelected(undefined)}
          >
            Featured
          </IonButton>
          {props.colorsCollection.map(
            (collection: IProductOptions, key: number) => {
              return (
                <OptionButton
                  key={key}
                  selectedOptionItem={props.styleColorSelected}
                  optionItem={collection}
                  setOptionItem={props.setStyleColorSelected}
                />
              );
            },
          )}
        </div>
      </div>

      <div className="wide-options-container">
        <div className="order-block-content color-card-content">
          {colorsToShow.map((color: any, key: number) => {
            return (
              <ColorCard
                key={key}
                color={color}
                setShowModal={props.setShowModal}
                selectedCombination={props.selectedCombination}
                updateCombination={props.updateCombination}
                colorCombinations={props.colorCombinations}
              />
            );
          })}

          <IonCard
            className="color-card ion-no-margin"
            onClick={() => props.setShowModal(false)}
          >
            <div className="option-card-item-img-container">
              <img
                alt="wristband"
                src="assets/img/white.jpg"
                className="ion-card-image"
              />
              <div className="overlay-text-back">
                <img
                  alt="Go Back"
                  src="assets/icon/goback.svg"
                  className="order-icon"
                />
                <span>Go Back</span>
              </div>{" "}
              {/* Overlay text */}
            </div>
            <IonCardHeader className="color-card-title-container ion-no-padding"></IonCardHeader>
          </IonCard>
        </div>
      </div>
    </IonModal>
  );
};
export default ModalWindow;
