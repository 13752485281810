import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonButton,
} from "@ionic/react";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import axios from "axios";
/*Components*/
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SectionTitle from "../../components/Titles/SectionTitle";
import MainInfoForm from "./AccountForms/MainInfoForm";
import PasswordForm from "./AccountForms/PasswordForm";
import AddressForm from "./AccountForms/AddressForm";
/*Custom hooks */
import useLocalStorage from "../../hooks/useLocalStorage";
/*Contexts*/
import { CurrentCustomerContext } from "../../contexts/currentCustomer";
/*Types*/
import { ICustomer } from "../../types/ICustomer";
import { CartContext } from "../../contexts/cart";
import "./Account.css";

const Account: React.FC = () => {
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [currentCustomerState] = useContext(CurrentCustomerContext);
  const [, dispatch] = useContext(CurrentCustomerContext);
  const [, dispatchCart] = useContext(CartContext);
  const [token, , removeToken] = useLocalStorage("token");
  const [, , removeStoredCustomer] = useLocalStorage("customer");
  const [, , removeCart] = useLocalStorage("cart");

  const history = useHistory();

  /*Checking user authorization*/
  useEffect(() => {
    if (currentCustomerState.currentCustomer && token) {
      setCustomer(currentCustomerState.currentCustomer);
    } else {
      history.push("/login");
    }
  }, [currentCustomerState, token]);

  const logout: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    axios
      .request({
        method: "POST",
        url: "/graphql",
        withCredentials: true,
        headers: {
          Authorization: token,
          "Content-type": "application/json",
        },
        data: JSON.stringify({
          query: `    mutation customerLogout {
            customerLogout {
                status
                success
            }
        }
    `,
        }),
      })
      .then(() => {
        removeCart();
        dispatchCart({ type: "CLEAR_CART" });
        removeToken();
        removeStoredCustomer();
        dispatch({ type: "SET_UNAUTHORIZED" });
        history.push("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <IonPage>
      <Header />
      {customer && (
        <IonContent fullscreen color="light">
          <section className="page-section">
            <div className="fixed-container">
              <SectionTitle
                title={
                  <h2>
                    <span className="title-bold">Account</span>
                  </h2>
                }
                class="title-dark"
              />
            </div>
            <div className="fixed-container fixed-container-white">
              <IonGrid>
                <IonRow>
                  {/*organising forms depending on screen size*/}
                  <IonCol size="12" size-md="6">
                    <MainInfoForm currentCustomer={customer} />

                    <div className="show-on-large">
                      <PasswordForm currentCustomer={customer} />
                    </div>
                    <div className="show-on-small">
                      <AddressForm currentCustomer={customer} />
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="show-on-large" id="large-address">
                      <AddressForm currentCustomer={customer} />
                    </div>
                    <div className="show-on-small">
                      <PasswordForm currentCustomer={customer} />
                    </div>
                    <form onSubmit={logout}>
                      <IonButton
                        fill="clear"
                        type="submit"
                        className="account-form-action"
                      >
                        <span className="button-text">Logout</span>
                      </IonButton>
                    </form>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </section>
          <Footer />
        </IonContent>
      )}
    </IonPage>
  );
};

export default Account;
