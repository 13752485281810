import { IonContent, IonPage } from "@ionic/react";
import Header from "../../components/Header/Header";

const Wristbands: React.FC = () => {
  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <h2>Wristbands</h2>
      </IonContent>
    </IonPage>
  );
};

export default Wristbands;
