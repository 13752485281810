import {
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import "./ColorCard.css";
import { IColorCombination } from "../../../types/IColorCombination";
import { IAttributeOption } from "../../../types/IAttributeOption";
import { ICombinationField } from "../../../types/ICombinationField";

interface IOptionCardParams {
  setShowModal: (show: boolean) => void;
  selectedCombination: number;
  updateCombination: (
    index: number,
    field: ICombinationField,
    value: number | IAttributeOption,
  ) => void;
  colorCombinations: IColorCombination[];
  color: IAttributeOption;
}

const ColorCard: React.FC<IOptionCardParams> = ({
  color,
  setShowModal,
  selectedCombination,
  colorCombinations,
  updateCombination,
}) => {
  return (
    <IonCard
      className={`color-card ion-no-margin ${
        colorCombinations[selectedCombination].color?.id === color.id
          ? "option-card-selected"
          : ""
      }`}
      onClick={() => {
        updateCombination(selectedCombination, "color", color);
        setShowModal(false);
      }}
    >
      <div className="color-card-item-img-container">
        <img
          alt="wristband"
          src={`storage/${color.attributeOptionPreview?.image}`}
          className="ion-card-image"
        />
        {/*Temporary overlay to show hex codes*/}
        <div className="overlay">{color.attributeOptionPreview?.hexCodes}</div>
      </div>

      <div className="option-card-divider"></div>
      <IonCardHeader className="color-card-title-container ion-no-padding">
        <IonCardTitle className="option-card-title color-card-title">
          {color.adminName}
        </IonCardTitle>
        <IonCardSubtitle
          className={`color-card-subtitle ${
            colorCombinations[selectedCombination].color?.id === color.id
              ? "color-card-subtitle-show"
              : ""
          }`}
        >
          {color.swatchValue}
        </IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
};
export default ColorCard;
