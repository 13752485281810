/*
 *displaying array with the products from TopSelling section
 */
import { IonRow } from "@ionic/react";
import TopSellingProductItem from "./components/TopSellingProductItem/TopSellingProductItem";
import { IProductsTemp } from "../../types/IProductsTemp";

interface ITopSellingProductsListParams {
  list: IProductsTemp[];
}

const ProductList: React.FC<ITopSellingProductsListParams> = ({ list }) => {
  return (
    <IonRow>
      {list.map((product: IProductsTemp) => (
        <TopSellingProductItem key={product.id} item={product} />
      ))}
    </IonRow>
  );
};

export default ProductList;
