/*
 *Getting data about  from API
 */
import axios, { AxiosResponse } from "axios";

const getFaqs = (section: string): Promise<AxiosResponse> => {
  /*Getting the list of sections's faqs*/
  return axios.request({
    method: "POST",
    url: "/graphql",
    headers: {
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: `  query faqs ($section: String!) {
          faqs (input: {section: $section}){
            data {
              id
              section
              translations {
                question
                answer
              }       
            }
          }
        }`,
      variables: {
        section: `${section}`,
      },
    }),
  });
};

export default getFaqs;
