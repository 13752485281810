// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trusting-companies {
    --ion-grid-columns: 14;
}

@media (max-width:1200px){
    .trusting-companies {
        --ion-grid-columns: 12;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/TrustingCompaniesList/TrustingCompaniesList.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".trusting-companies {\r\n    --ion-grid-columns: 14;\r\n}\r\n\r\n@media (max-width:1200px){\r\n    .trusting-companies {\r\n        --ion-grid-columns: 12;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
