// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy h2 {
    color: #185B88;
    text-align: left;
    margin-left:50px;
}
.privacy h3 {
    margin: 40px 0 30px;
    font-weight:700;
}
.privacy h4 {
    margin: 25px 0 15px;
}
.privacy p {
    font-size:16px;
    margin-bottom: 10px;
}

/***Media***/
@media (max-width: 700px){
    .privacy h2 {
        margin-left:20px;
    }
}
@media (max-width:500px) {
    .privacy {
    text-align: center;
    }
    .privacy p {
        text-align: center;
    }
    .privacy h2 {
        text-align: center;
        margin-left:0;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Privacy/Privacy.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA,YAAY;AACZ;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI;IACA,kBAAkB;IAClB;IACA;QACI,kBAAkB;IACtB;IACA;QACI,kBAAkB;QAClB,aAAa;IACjB;AACJ","sourcesContent":[".privacy h2 {\r\n    color: #185B88;\r\n    text-align: left;\r\n    margin-left:50px;\r\n}\r\n.privacy h3 {\r\n    margin: 40px 0 30px;\r\n    font-weight:700;\r\n}\r\n.privacy h4 {\r\n    margin: 25px 0 15px;\r\n}\r\n.privacy p {\r\n    font-size:16px;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n/***Media***/\r\n@media (max-width: 700px){\r\n    .privacy h2 {\r\n        margin-left:20px;\r\n    }\r\n}\r\n@media (max-width:500px) {\r\n    .privacy {\r\n    text-align: center;\r\n    }\r\n    .privacy p {\r\n        text-align: center;\r\n    }\r\n    .privacy h2 {\r\n        text-align: center;\r\n        margin-left:0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
