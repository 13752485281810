/*
 * Mocs to dispolay ClipartBar
 */
const DeafaultClipartList: any = [
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/9605-84.png",
    term: "Ribbon",
    id: "9605",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/431-84.png",
    term: "Star",
    id: "431",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/822-84.png",
    term: "Heart",
    id: "822",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/2437-84.png",
    term: "Judaism",
    id: "2437",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/2438-84.png",
    term: "islam",
    id: "2438",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/2439-84.png",
    term: "Christianity",
    id: "2439",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/2099-84.png",
    term: "Ying Yang",
    id: "2099",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/32126-84.png",
    term: "Church",
    id: "32126",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/2441-84.png",
    term: " Cemetery",
    id: "2441",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/39585-84.png",
    term: " Coffin",
    id: "39585",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/29403-84.png",
    term: " Grave",
    id: "29403",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/71317-84.png",
    term: "Angel",
    id: "71317",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/34954-84.png",
    term: " Budda",
    id: "34954",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/6759-84.png",
    term: "Dove",
    id: "6759",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/76186-84.png",
    term: "Paw",
    id: "76186",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/100086-84.png",
    term: "Paw Print",
    id: "100086",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/8426-84.png",
    term: "Eagle",
    id: "8426",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/98518-84.png",
    term: " bull",
    id: "98518",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/41858-84.png",
    term: " bull",
    id: "41858",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/51181-84.png",
    term: "Viking",
    id: "51181",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/105329-84.png",
    term: "Bear",
    id: "105329",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/2551-84.png",
    term: " Backpack",
    id: "2551",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/8220-84.png",
    term: " Graduate Cap",
    id: "8220",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/12825-84.png",
    term: " Beaker",
    id: "12825",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/20545-84.png",
    term: " Teacher",
    id: "20545",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/37929-84.png",
    term: " classroom",
    id: "37929",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/30543-84.png",
    term: " Mortar Board",
    id: "30543",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/35626-84.png",
    term: " Globe",
    id: "35626",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/11230-84.png",
    term: " Apple",
    id: "11230",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/15210-84.png",
    term: " Theater",
    id: "15210",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/101900-84.png",
    term: "Dancing Shark",
    id: "101900",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/60579-84.png",
    term: "clover",
    id: "60579",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/38616-84.png",
    term: " Palm Tree",
    id: "38616",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/63157-84.png",
    term: " Suitcase",
    id: "63157",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/36018-84.png",
    term: "Smile",
    id: "36018",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/8278-84.png",
    term: "Basketball",
    id: "8278",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/145354-84.png",
    term: "Baseball",
    id: "145354",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/54547-84.png",
    term: " Baseball Glove",
    id: "54547",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/10684-84.png",
    term: "Soccer Ball",
    id: "10684",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/8872-84.png",
    term: "Football",
    id: "8872",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/10024-84.png",
    term: "Heart",
    id: "10024",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/3716-84.png",
    term: "wedding",
    id: "3716",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/36903-84.png",
    term: "Ring",
    id: "36903",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/115756-84.png",
    term: "Gift",
    id: "115756",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/12026-84.png",
    term: "Balloon",
    id: "12026",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/29734-84.png",
    term: "Pinata",
    id: "29734",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/16713-84.png",
    term: " candy",
    id: "16713",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/44567-84.png",
    term: "Recycle",
    id: "44567",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/47201-84.png",
    term: "grow",
    id: "47201",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/126421-84.png",
    term: "wheelchair",
    id: "126421",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/16650-84.png",
    term: "Ribbon",
    id: "16650",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/14372-84.png",
    term: "Ribbon",
    id: "14372",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/6505-84.png",
    term: "Puzzle",
    id: "6505",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/4215-84.png",
    term: "Health",
    id: "4215",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/11461-84.png",
    term: " Peace",
    id: "11461",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/29198-84.png",
    term: " Peace",
    id: "29198",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/75329-84.png",
    term: "USA",
    id: "75329",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/39101-84.png",
    term: "solider",
    id: "39101",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/2644-84.png",
    term: " Airplane",
    id: "2644",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/21102-84.png",
    term: " Fortune Cookie",
    id: "21102",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/180-84.png",
    term: " Flower",
    id: "180",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/35405-84.png",
    term: " Flower",
    id: "35405",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/36206-84.png",
    term: " Flower",
    id: "36206",
  },
  {
    url: "https://d30y9cdsu7xlg0.cloudfront.net/png/78672-84.png",
    term: " Fleur de Lis",
    id: "78672",
  },
];

export default DeafaultClipartList;
