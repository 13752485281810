import axios, { AxiosResponse } from "axios";

const savePaymentMethod = (
  selectedMethod: any,
  token: string,
  guestId: string | null = null,
  cartId: number | string = 0,
): Promise<AxiosResponse> => {
  const headers = {
    Authorization: token,
    "Content-type": "application/json",
    Accept: "application/json",
  };
  const cart_id =
    cartId === 0 || typeof cartId === "number" ? 0 : parseInt(cartId, 10);

  if (guestId && !token.includes("Bearer")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["Guest-Identifier"] = guestId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["x-cart-id"] = cart_id;
  }

  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers,
    data: JSON.stringify({
      query: ` mutation shippingMethods ($selectedMethod: String!) {
        savePayment (input: {
          payment: {
                   method: $selectedMethod
               }          
           })  {
            success         
          }
        }
      `,
      variables: {
        selectedMethod: selectedMethod,
      },
    }),
  });
};

export default savePaymentMethod;
