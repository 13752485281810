// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tyvek-page h2{
margin-bottom: 35px;
}

/***Media***/

@media (max-width:800px) {
    .tyvek-page h2{
        margin-bottom: 30px;
        }
}

@media (max-width:600px) {
    .tyvek-page h2{
        margin-bottom: 20px;
        }
}

@media (max-width:500px) {
    .tyvek-page h2{
        margin-bottom: 15px;
        }
}

`, "",{"version":3,"sources":["webpack://./src/pages/TyvekWristbands/TyvekWristbands.css"],"names":[],"mappings":"AAAA;AACA,mBAAmB;AACnB;;AAEA,YAAY;;AAEZ;IACI;QACI,mBAAmB;QACnB;AACR;;AAEA;IACI;QACI,mBAAmB;QACnB;AACR;;AAEA;IACI;QACI,mBAAmB;QACnB;AACR","sourcesContent":[".tyvek-page h2{\nmargin-bottom: 35px;\n}\n\n/***Media***/\n\n@media (max-width:800px) {\n    .tyvek-page h2{\n        margin-bottom: 30px;\n        }\n}\n\n@media (max-width:600px) {\n    .tyvek-page h2{\n        margin-bottom: 20px;\n        }\n}\n\n@media (max-width:500px) {\n    .tyvek-page h2{\n        margin-bottom: 15px;\n        }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
