import axios, { AxiosResponse } from "axios";

const saveCheckoutAddresses = (
  address: number,
  token: string,
  guestId: string | null = null,
  guestAddress: object | null = null,
  cartId: number | string = 0,
): Promise<AxiosResponse> => {
  const headers = {
    Authorization: token,
    "Content-type": "application/json",
  };

  if (guestId && !token.includes("Bearer")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["Guest-Identifier"] = guestId;
  }

  let finalGuestAddress = null;

  if (guestAddress) {
    finalGuestAddress = { ...guestAddress, id: undefined };
  }

  const cart_id =
    cartId === 0 || typeof cartId === "number" ? 0 : parseInt(cartId, 10);

  return axios.request({
    method: "POST",
    url: "/graphql",
    headers,
    data: JSON.stringify({
      query: ` mutation saveCheckoutAddresses ($address: Int!, $guestAddress: GuestAddressInput, $cartId: Int!) {
        saveCheckoutAddresses(
          input: { billingAddressId: $address shippingAddressId: $address type: "customer" address: $guestAddress cartId: $cartId}
        ) {
          success
          cart {
            id
            customerEmail
            customerFirstName
            customerLastName
            shippingMethod
            taxTotal
            grandTotal
            baseGrandTotal
            discountAmount
            selectedShippingRate {
              price
            }
            isTaxExempt
            itemsQty
            itemsCount
            subTotal
            items {
              id
              quantity
              sku
              type
              name
              price
              basePrice
              total
              baseTotal
              taxPercent
              taxAmount
              baseTaxAmount
              cartId
              productId
              productFlat {
                id
              }
              designPersonalizations {
                attributeId
                textValue
                jsonValue
                integerValue
                attributeOption {
                  id
                  adminName
                  swatchValue
                  attributeOptionPreview{
                    image
                    hexCodes
                  }
                }
                attribute {
                  adminName
                  type
                  code
                }
              }
              additional 
          }  
        }
          
        }
      }
      `,
      variables: {
        address: address,
        guestAddress: finalGuestAddress,
        cartId: cart_id,
      },
    }),
  });
};

export default saveCheckoutAddresses;
