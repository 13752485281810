/*
 *Displatying Free Sample Page
 */
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonLabel,
  IonTextarea,
  IonSpinner,
} from "@ionic/react";

/***Import Components***/
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SectionTitle from "../../components/Titles/SectionTitle";

/***Import CSS*****/
import "../../components/FormInputs/CustomField.css";
import Button from "../../components/Button/Button";
import { useForm, Controller } from "react-hook-form";
import { IFormInputError } from "../../types/IFormInputError";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useContext, useEffect, useRef, useState } from "react";
import { CurrentCustomerContext } from "../../contexts/currentCustomer";
import { ICustomer } from "../../types/ICustomer";
import { useHistory } from "react-router";
import OrderItem from "../Receipt/components/OrderItem/OrderItem";
import { CartContext } from "../../contexts/cart";
import { ICart } from "../../types/Cart/ICart";
import requestQuote from "../../services/checkout/requestQuote";
import React from "react";

interface ISampleRequestFormInputs {
  name: string;
  phone: string;
  address1: string;
  address2: string;
  state: string;
  company: string;
  email: string;
  city: string;
  postal_code: string;
  comments: string;
}

const GetQuote: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [backendErrors, setBackendErrors] = useState<IFormInputError[]>([]);
  const [token] = useLocalStorage("token");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [customer, setCustomer] = useState<ICustomer>();
  const [currentCustomerState] = useContext(CurrentCustomerContext);
  const history = useHistory();
  const [cartState] = useContext(CartContext);
  const [cart, setCart] = useState<ICart>();

  //Getting Cart from context
  useEffect(() => {
    if (cartState.cart) {
      setCart(cartState.cart);
    }
  }, [cartState]);

  /*Checking user authorization*/
  useEffect(() => {
    if (currentCustomerState.currentCustomer && token) {
      setCustomer(currentCustomerState.currentCustomer);
    } else {
      history.push("/login");
    }
  }, [currentCustomerState, token]);

  const handleButtonClick = () => {
    // trigger the formRef submission
    if (formRef.current && cart && cart.items.length > 0) {
      formRef.current.dispatchEvent(new Event("submit", { bubbles: true }));
      setLoading(true);
    }
  };

  const submitQuoteRequest = (data: ISampleRequestFormInputs) => {
    requestQuote(
      token,
      data.name,
      data.company || null, // Default to null if not provided
      data.email,
      data.comments || null, // Default to null if not provided
      cart ? cart.id : 0,
    )
      .then((response) => {
        setSuccessMessage(response.data.data.requestQuote.message);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Quote Request Failed:", error);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<ISampleRequestFormInputs>({
    defaultValues: {
      name: customer ? customer.firstName : "",
      company: "",
      email: customer ? customer.email : "",
    },
    mode: "all",
  });

  useEffect(() => {
    if (customer) {
      reset({
        name: customer.firstName || "",
        company: "",
        email: customer.email || "",
        comments: "",
      });
    }
  }, [customer, reset]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section section-about">
          <div className="fixed-container">
            {loading && (
              <div className="form-overlay">
                <IonSpinner name="lines" />
              </div>
            )}
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Request a Quote</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="6">
                  <form
                    id="sampleRequestForm"
                    method="POST"
                    onSubmit={handleSubmit(submitQuoteRequest)}
                    ref={formRef}
                  >
                    {backendErrors.length > 0 &&
                      backendErrors.map((error, index) => (
                        <React.Fragment key={error.id}>
                          <span className="errorText">{error.message}</span>
                          {index < backendErrors.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    {successMessage && (
                      <span className="successText">{successMessage}</span>
                    )}

                    <div className="field">
                      <IonLabel className="fieldLabel">
                        Your Name <span className="required-asterisk">*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name="name"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonInput={field.onChange}
                            className={`customInput ${
                              errors.name ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.name && (
                          <span className="errorText">
                            {errors.name.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>

                    <div className="field">
                      <IonLabel className="fieldLabel">Company Name</IonLabel>
                      <Controller
                        control={control}
                        name="company"
                        rules={{ required: false }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonInput={field.onChange}
                            className={`customInput ${
                              errors.company ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.company && (
                          <span className="errorText">
                            {errors.company.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>

                    <div className="field">
                      <IonLabel className="fieldLabel">
                        Email Address{" "}
                        <span className="required-asterisk">*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name="email"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonInput={field.onChange}
                            className={`customInput ${
                              errors.email ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.email && (
                          <span className="errorText">
                            {errors.email.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>

                    <div className="field">
                      <IonLabel className="fieldLabel">
                        Additional Questions Or Comments
                      </IonLabel>
                      <Controller
                        control={control}
                        name="comments"
                        rules={{ required: false }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonTextarea
                            id="comments"
                            name="comments"
                            value={value}
                            onIonChange={onChange}
                            onBlur={onBlur}
                            className={`customTextarea ${
                              errors.comments ? "errorBorder" : ""
                            }`}
                            rows={4}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.comments && (
                          <span className="errorText">
                            {errors.comments.message ||
                              "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                    <Button
                      id="requestBtn"
                      text="Submit"
                      onClick={handleButtonClick}
                      disabled={isValid === false}
                    />
                  </form>
                </IonCol>

                <IonCol size="12" size-md="6">
                  {cart && cart.items.length > 0 && (
                    <div className="order-item-details-block">
                      {cart.items.map((item: any, key: number) => (
                        <OrderItem orderItem={item} key={key} />
                      ))}
                    </div>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default GetQuote;
