// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additional-button {
  color: #000;
  padding: 8px 12px;
  text-align: center;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 1em;
  background-color: #2cc4e7;
}
`, "",{"version":3,"sources":["webpack://./src/components/Store/AdditionalButton/AdditionalButton.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".additional-button {\n  color: #000;\n  padding: 8px 12px;\n  text-align: center;\n  font-family: \"Open Sans\";\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 1em;\n  background-color: #2cc4e7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
