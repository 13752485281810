import axios, { AxiosResponse } from "axios";

const checkout = (
  token: string,
  guestId: string | null = null,
  cartId: number | string = 0,
): Promise<AxiosResponse> => {
  const headers = {
    Authorization: token,
    "Content-type": "application/json",
    Accept: "application/json",
  };

  const cart_id =
    cartId === 0 || typeof cartId === "number" ? 0 : parseInt(cartId, 10);

  if (guestId && !token.includes("Bearer")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["Guest-Identifier"] = guestId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["x-cart-id"] = cart_id;
  }

  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers,
    data: JSON.stringify({
      query: `mutation placeOrder {
        placeOrder {
          success
          redirectUrl
          order {
            id
            referenceId
            shippingTitle
            customerEmail
            createdAt
            billingAddress {
              firstName
              lastName
              companyName
              address1
              address2
              postcode
              city
              state
              country
              phone
            }
            shippingAddress {
              firstName
              lastName
              companyName
              address1
              address2
              postcode
              city
              state
              country
              phone
            }
            baseGrandTotal
            totalQtyOrdered
            totalItemCount
            subTotal
            items {
              id
              qtyOrdered
              sku
              type
              name
              price
              basePrice
              total
              baseTotal
              taxPercent
              taxAmount
              baseTaxAmount
              productId
              additional
              designPersonalizations {
                attributeId
                textValue
                jsonValue
                integerValue
                attributeOption {
                  id
                  adminName
                  swatchValue
                  attributeOptionPreview {
                    image
                  }
                }
                attribute {
                  adminName
                  type
                  code
                }
              }
            }
          }
        }
      }`,
    }),
  });
};

export default checkout;
