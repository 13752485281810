import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonContent,
} from "@ionic/react";
import { useParams } from "react-router";
import { IProducts } from "../../types/IProduct";
import { ID } from "../../types/ID";
import RouteProductStyle from "./RouteProductStyle";
import getProducts from "../../services/getProducts";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LinksWidget from "../../components/LinksWidget/LinksWidget";
import getProductStylePicture from "../../functions/getProductStylePicture";
import getProductStyleText from "../../functions/getProductStyleText";
import TextBlock from "../../components/TextBlocks/TextBlock";
import Button from "../../components/Button/Button";
import "./ProductStylePage.css";

const ProductStylePage: React.FC = () => {
  const { link } = useParams<ID>();
  const url: any = process.env.REACT_APP_DEMO_URL;
  /*Getting needed product**/
  const [productStyle, setProductStyle] = useState<IProducts | null>(null);
  useEffect(() => {
    getProducts().then((response) => {
      setProductStyle(
        response.data.data.data?.find(
          (item: IProducts) => item.attributes["product.url"] === link,
        ),
      );
    });
  }, [link]);
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    getProducts().then((response) => {
      setProductData(response.data.data.included);
    });
  }, []);
  return (
    <IonPage>
      <Header />
      <IonContent color="light">
        <div className="fixed-container container-product-style">
          <div className="side-content">
            <LinksWidget />
            <Button text="Order Now" />
          </div>

          {productStyle && productData ? (
            <IonCard className="product-style-card">
              <IonCardHeader>
                <IonCardTitle
                  className="product-style-title"
                  class="ion-text-center"
                  color="primary"
                >
                  {productStyle.attributes["product.label"]}
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <TextBlock
                  text={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getProductStyleText(productStyle, productData),
                      }}
                    ></div>
                  }
                />
                <img
                  className="product-style-img"
                  src={`${url}/${getProductStylePicture(
                    productStyle,
                    productData,
                  )}`}
                  alt={productStyle.attributes["product.label"]}
                />
              </IonCardContent>
              <div className="buttons-options">
                <Button
                  text={`Design ${productStyle.attributes["product.label"]}`}
                />
              </div>
            </IonCard>
          ) : (
            <></>
          )}
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export { RouteProductStyle };
export default ProductStylePage;
