import { IonRouterLink } from "@ionic/react";

const ThirdBlokMocs: JSX.Element = (
  <div>
    {" "}
    <p>
      Our excellent customer service team will provide you efficient service to
      help you design and order now your dream wristband over the phone or
      through online chat.
    </p>
    <p>
      Visit our{" "}
      <IonRouterLink
        routerLink="/faq"
        routerDirection="none"
        className="text-important"
      >
        FAQ
      </IonRouterLink>{" "}
      page for answers to questions regularly asked by our customers. Contact us
      for a quick response or reach out to our live chat support team!
    </p>
  </div>
);

export default ThirdBlokMocs;
