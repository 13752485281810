/*Component to display address validation status
 *Displayed window depends in the status
 */

import { IonButton, IonIcon } from "@ionic/react";
import { useState } from "react";
import { chevronDown, alertCircleOutline } from "ionicons/icons";
import SecondaryAddressProblem from "./SecondaryAddressProblem";
import { IAddressData } from "../../../../types/Forms/IAddressData";
import "../AccountForm.css";

interface IAddressValidationFormParams {
  state: string;
  saveAddress: any;
  editAddress: { (): void };
  addressDataEntered: IAddressData;
  addressDataValidated: IAddressData;
  secondaryAddressProblem: boolean;
  secondaryAddressProblemMessage: string;
  addressMismatchLineOne: boolean;
  addressMismatchLineTwo: boolean;
}

const AddressValidationForm: React.FC<IAddressValidationFormParams> = (
  props,
) => {
  //define variable, which controls showing details for modified address
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [validatedAddress, setValidatedAddress] = useState<string>(
    `${props.addressDataValidated.address1} ${props.addressDataValidated.address2} ${props.addressDataValidated.city}, ${props.addressDataValidated.state}, ${props.addressDataValidated.postcode}`,
  );
  const validatedAddressLineOne = `${props.addressDataValidated.address1} ${props.addressDataValidated.address2}`;
  const validatedAddressLineTwo = `${props.addressDataValidated.city}, ${props.addressDataValidated.state}, ${props.addressDataValidated.postcode}`;
  const enteredAddress = `${props.addressDataEntered.address1} ${
    props.addressDataEntered.address2
      ? ` ${props.addressDataEntered.address2}`
      : ""
  } ${props.addressDataEntered.city}, ${props.addressDataEntered.state} ${
    props.addressDataEntered.postcode
  }`;
  return (
    <div className="validated-address-form">
      {props.state === "validated" ? (
        <div className="confirmed">
          <div className="inline-information">
            {" "}
            <IonIcon icon={chevronDown} color="success" size="large"></IonIcon>
            <p className="address-data">{validatedAddress}</p>
          </div>
          {props.secondaryAddressProblem ? (
            <SecondaryAddressProblem
              message={props.secondaryAddressProblemMessage}
            />
          ) : (
            <></>
          )}
        </div>
      ) : null}

      {props.state === "modified" ? (
        <div className="modified">
          <div className="inline-information">
            {" "}
            <IonIcon icon={chevronDown} color="success" size="large"></IonIcon>
            <p className="address-data">{validatedAddress}</p>
          </div>

          <p>
            <span className="form-attention">
              Your address has been modified
            </span>
            &nbsp; to match carrier specifications.{" "}
            <span
              className="validated-form-action"
              onClick={() => setShowDetails((prevState) => !prevState)}
            >
              {!showDetails ? "View Details" : "Hide Details"}
            </span>
          </p>
          {props.secondaryAddressProblem ? (
            <SecondaryAddressProblem
              message={props.secondaryAddressProblemMessage}
            />
          ) : (
            <></>
          )}
          {showDetails ? (
            <div className="show-address-details">
              <div className="button-cover">
                {" "}
                <span
                  className="close-details"
                  onClick={() => setShowDetails(false)}
                >
                  X
                </span>
              </div>
              <p>
                Address entered has been formatted to match carrier
                specifications.
              </p>
              <p>You entered: {enteredAddress}</p>

              <div className="action-cover">
                {" "}
                <IonButton
                  fill="clear"
                  className="validated-form-action thin-text-button"
                  onClick={props.editAddress}
                >
                  Edit
                </IonButton>
                <IonButton
                  fill="clear"
                  className="validated-form-action thin-text-button"
                  onClick={() => {
                    setValidatedAddress(enteredAddress);
                    props.saveAddress(props.addressDataEntered, true);
                  }}
                >
                  Use My Entered Address
                </IonButton>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : null}

      {props.state === "suggested" ? (
        <div className="suggested">
          <div className="inline-information">
            {" "}
            <IonIcon
              icon={alertCircleOutline}
              color="danger"
              size="large"
            ></IonIcon>
            <p className="address-data">Is this your address?</p>
          </div>

          <p className="suggested-address-details">
            {props.addressMismatchLineOne ? (
              <b> {validatedAddressLineOne}</b>
            ) : (
              <span>{validatedAddressLineOne} </span>
            )}
            <br />
            {props.addressMismatchLineTwo ? (
              <b> {validatedAddressLineTwo}</b>
            ) : (
              <span> {validatedAddressLineTwo}</span>
            )}
          </p>

          <div className="action-cover">
            <IonButton
              onClick={() => props.saveAddress(props.addressDataValidated)}
              fill="outline"
              color="success"
            >
              <span className="small-button">Yes</span>
            </IonButton>
          </div>
          <div className="action-cover">
            <IonButton
              onClick={() => {
                setValidatedAddress(enteredAddress);
                props.saveAddress(props.addressDataEntered);
              }}
              fill="clear"
              color="secondary"
            >
              <span className="small-button thin-text-button">
                No, use my entered address
              </span>
            </IonButton>
          </div>
          <p className="suggested-address-details">
            <b>You entered:</b>
            <br />
            {`${props.addressDataEntered.address1} ${
              props.addressDataEntered.address2
                ? ` ${props.addressDataEntered.address2}`
                : ""
            }`}
            <br />
            {`${props.addressDataEntered.city}, ${props.addressDataEntered.state} ${props.addressDataEntered.postcode}`}
          </p>
          <p>
            <span className="address-data-red">Please Note:</span> An inaccurate
            address may result in delayed or undeliverable packages.
          </p>
        </div>
      ) : null}

      {props.state === "not-found" ? (
        <div className="not-found">
          <div className="inline-information">
            {" "}
            <IonIcon
              icon={alertCircleOutline}
              color="danger"
              size="large"
            ></IonIcon>
            <p className="address-data address-data-red">
              Please Check Your Entry Since We Cannot Verify The Address
              Entered.
            </p>
          </div>
          <div className="action-cover">
            <span
              className="validated-form-action"
              onClick={() => {
                setValidatedAddress(enteredAddress);
                props.saveAddress(props.addressDataEntered);
              }}
            >
              Use My Entered Address
            </span>
            <span className="validated-form-action" onClick={props.editAddress}>
              Edit
            </span>
          </div>

          <p>
            <span className="address-data-red">Please Note:</span> An inaccurate
            address may result in delayed or undeliverable packages.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default AddressValidationForm;
