//custom hook to store and remove data in local storage
import { useState, useEffect } from "react";

const useLocalStorage = (key: string, initialValue = "") => {
  const [value, setValue] = useState(() => {
    return localStorage.getItem(key) || initialValue;
  });

  useEffect(() => {
    if (value === "") {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }, [value, key]);

  const removeItem = () => {
    localStorage.removeItem(key);
    setValue("");
  };

  return [value, setValue, removeItem] as const;
};

export default useLocalStorage;
