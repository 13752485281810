import axios, { AxiosResponse } from "axios";

const updateCustomerAddress = (
  token: string,
  id: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postcode: string,
): Promise<AxiosResponse> => {
  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: `  mutation updateAddress ($id: ID!, $address1: String!, $address2: String, $city: String!, $state: String!, $postcode: String!) {
           updateAddress(id: $id, input: {
               address1: $address1
               address2: $address2
               state: $state
               city: $city
               postcode: $postcode
           }) {
           addresses{
               id
               address1
               address2
               state
               city
               postcode
           }
         }
       }
     `,
      variables: {
        id: `${id}`,
        address1: `${address1}`,
        address2: `${address2}`,
        city: `${city}`,
        state: `${state}`,
        postcode: `${postcode}`,
      },
    }),
  });
};

export default updateCustomerAddress;
