import "./Titles.css";

interface ISectionSubTitleParams {
  subtitle: JSX.Element;
  class: string;
}

const SectionSubTitle: React.FC<ISectionSubTitleParams> = (props) => {
  return (
    <div className={`${props.class} ion-text-center`}>{props.subtitle}</div>
  );
};

export default SectionSubTitle;
