/*
 * The component is used to display blue button to upload image
 */

import "./AdditionalButton.css";

interface IAdditionalButtonParams {
  text: string;
  event: () => void;
}

const AdditionalButton: React.FC<IAdditionalButtonParams> = ({
  text,
  event,
}) => {
  return (
    <button className="additional-button" onClick={event}>
      {text}
    </button>
  );
};

export default AdditionalButton;
