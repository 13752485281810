import axios, { AxiosResponse } from "axios";

const placeSampleRequest = (
  token: string,
  name: string,
  phone: string,
  address1: string,
  address2: string,
  email: string,
  state: string,
  city: string,
  postalCode: string | number,
  comments?: string,
  company?: string,
  items: any[] = [],
): Promise<AxiosResponse> => {
  const firstName = name.split(" ")[0];
  // there may not be a last name
  const lastName = name.split(" ")[1] || "";

  return axios.request({
    method: "POST",
    url: "/graphql",
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: `mutation OrderSample($paymentMethod: String!, $companyName: String, $firstName: String!, $lastName: String!, $email: String!, $address1: String!, $address2: String, $city: String!, $country: String!, $state: String!, $postCode: String!, $phone: String!, $customerNotes: String!, $shippingMethod: String!, $items: [SampleItem!]!) {
        orderSample(
            input: {
            payment: { method: $paymentMethod }
            address: {
                companyName: $companyName
                firstName: $firstName
                lastName: $lastName
                email: $email
                address1: $address1
                address2: $address2
                city: $city
                country: $country
                state: $state
                postcode: $postCode
                phone: $phone
            }
            customer_notes: $customerNotes
            shipping: $shippingMethod
            items: $items
            }
        ) {
            success
            message
            order_id
        }
    }
      `,
      variables: {
        paymentMethod: "cashondelivery",
        shippingMethod: "free_free",
        firstName: `${firstName}`,
        lastName: `${lastName}`,
        phone: `${phone}`,
        email: `${email}`,
        address1: `${address1}`,
        address2: `${address2}`, // we haven't required this one yet
        state: `${state}`,
        company: `${company}` || "",
        city: `${city}`,
        postCode: `${postalCode}`,
        customerNotes: `${comments}`,
        items: items,
        country: "US",
      },
    }),
  });
};

export default placeSampleRequest;
