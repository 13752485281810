import { IColorCombination } from "../../types/IColorCombination";

const updateCombination = (
  colorCombinations: IColorCombination[],
  setColorCombinations: any,
  index: number,
  field: string,
  value: string,
) => {
  const newCombinations = [...colorCombinations];
  if (field === "youth" || field === "adult" || field === "large") {
    const parsedValue = parseInt(value, 10);
    newCombinations[index][field] = isNaN(parsedValue) ? "" : parsedValue;
  } else {
    newCombinations[index][field] = value;
  }
  setColorCombinations(newCombinations);
};

export default updateCombination;
