import { IonRow, IonGrid } from "@ionic/react";
import ProductItem from "./components/ProductItem/ProductItem";
import { IProducts } from "../../types/IProduct";
import { IProductData } from "../../types/IProductData";

interface IProdictsListParams {
  list: IProducts[];
  additionalData: IProductData[];
}

const ProductList: React.FC<IProdictsListParams> = (props) => {
  return (
    <IonGrid>
      <IonRow>
        {props.list?.map((product: IProducts) => (
          <ProductItem
            key={product.attributes["product.id"]}
            item={product}
            productData={props.additionalData}
          />
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default ProductList;
