const TrustingCompaniesListMocs: any = [
  {
    id: 1,
    title: "company1",
    img: "../assets/img/trusted/13.svg",
  },
  {
    id: 2,
    title: "company2",
    img: "../assets/img/trusted/7.svg",
  },
  {
    id: 3,
    title: "company3",
    img: "../assets/img/trusted/3.svg",
  },
  {
    id: 4,
    title: "company4",
    img: "../assets/img/trusted/4.svg",
  },
  {
    id: 5,
    title: "company5",
    img: "../assets/img/trusted/12.svg",
  },
  {
    id: 6,
    title: "company6",
    img: "../assets/img/trusted/6.svg",
  },
  {
    id: 7,
    title: "company7",
    img: "../assets/img/trusted/1.svg",
  },
  {
    id: 8,
    title: "company1",
    img: "../assets/img/trusted/8.svg",
  },
  {
    id: 9,
    title: "company1",
    img: "../assets/img/trusted/9.svg",
  },
  {
    id: 10,
    title: "company8",
    img: "../assets/img/trusted/10.svg",
  },
  {
    id: 11,
    title: "company9",
    img: "../assets/img/trusted/11.svg",
  },
  {
    id: 12,
    title: "company10",
    img: "../assets/img/trusted/5.svg",
  },
  {
    id: 13,
    title: "company2",
    img: "../assets/img/trusted/2.svg",
  },
  {
    id: 14,
    title: "company14",
    img: "../assets/img/trusted/14.svg",
  },
];

export default TrustingCompaniesListMocs;
