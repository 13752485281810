// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.receipt-block-title {
  font-weight: 600;
  text-transform: none;
  color: #000;
  font-size: 16px;
  margin-bottom: 7px;
}
.receipt-section {
  width: 100%;
  padding-top: 10px;
}
.receipt-section-block {
  padding: 15px 0;
}
.receipt-page-container {
  width: 80%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  background-color: #f5f7f9;
  display: flex;
  flex-wrap:wrap;
}
.receipt-column {
  width: 50%;
}

@media (max-width: 950px) {
  .receipt-page-container {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .receipt-page-container {
    width: 100%;
  }
  .receipt-column {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Receipt/Receipt.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,cAAc;AAChB;AACA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".receipt-block-title {\n  font-weight: 600;\n  text-transform: none;\n  color: #000;\n  font-size: 16px;\n  margin-bottom: 7px;\n}\n.receipt-section {\n  width: 100%;\n  padding-top: 10px;\n}\n.receipt-section-block {\n  padding: 15px 0;\n}\n.receipt-page-container {\n  width: 80%;\n  max-width: 800px;\n  height: 100%;\n  margin: 0 auto;\n  background-color: #f5f7f9;\n  display: flex;\n  flex-wrap:wrap;\n}\n.receipt-column {\n  width: 50%;\n}\n\n@media (max-width: 950px) {\n  .receipt-page-container {\n    width: 90%;\n  }\n}\n\n@media (max-width: 800px) {\n  .receipt-page-container {\n    width: 100%;\n  }\n  .receipt-column {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
