import "./StyleForm.css";
import { IProductOptions } from "../../../../types/IProductOptions";
import OptionCard from "../../../../components/Store/OptionCard/OptionCard";

interface IStyleFormParams {
  selectedStyle: IProductOptions | undefined;
  setSelectedStyle: (style: IProductOptions) => void;
  styles: IProductOptions[];
}

const StyleForm: React.FC<IStyleFormParams> = ({
  selectedStyle,
  setSelectedStyle,
  styles,
}) => {
  return (
    <div className="order-options-container">
      <section className="order-section">
        <div className="order-section-header">
          <div className="order-page-container">
            <h2 className="order-section-title">Style:</h2>
          </div>
        </div>

        <div className="order-page-container">
          <div className="order-block-content">
            {styles.map((style: IProductOptions, index: number) => {
              return (
                <OptionCard
                  key={index}
                  style={style}
                  selectedStyle={selectedStyle}
                  setSelectedStyle={setSelectedStyle}
                />
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default StyleForm;
