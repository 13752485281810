/*
 *Component to render a single color combination from the cart item
 */

import { IonIcon } from "@ionic/react";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useHistory } from "react-router-dom";
import redCloseButton from "../../../../../../assets/icon/red-close-button.svg";
import pencil from "../../../../../../assets/icon/pencil.svg";
import { IColorCombination } from "../../../../../../types/IColorCombination";
import { ICartItem } from "../../../../../../types/Cart/ICartItem";
import "./ColorCombination.css";

interface IColorCombinationBlock {
  colorCombination: IColorCombination;
  colorCombinations: IColorCombination[];
  colorCombimbinationsAtributeId: number | null;
  cartItemId: number;
  selectCombination: (combinationId: number) => void;
  selectedCombination: number | undefined;
  setSelectedCombination: (combinationId: number | undefined) => void;
  updateCombination: any;
  deleteColorCombination: any;
  setReadyToUpdate: Dispatch<SetStateAction<boolean>>;
  cartItem: ICartItem; // Assume ICartItem is already defined elsewhere
}

const ColorCombination: React.FC<IColorCombinationBlock> = (props) => {
  const history = useHistory();

  const navigateToOrder = () => {
    sessionStorage.setItem("fromCart", "true");
    history.push("/order", {
      dataItem: props.cartItem,
      dataColorCombination: props.colorCombination,
      selectedCombination: props.selectedCombination,
      colorCombinations: props.colorCombinations,
      fromCart: true,
    });
  };

  const handleInputChange = useCallback(
    (size: string | number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      props.updateCombination(props.selectedCombination, size, e.target.value);
      props.setReadyToUpdate(true);
    },
    [
      props.updateCombination,
      props.selectedCombination,
      props.setReadyToUpdate,
    ],
  );

  return (
    <div className="cart-item-colorcombination-line">
      <div className="quantity-first-arrow-block">
        <div className="quantity-image-block">
          <img
            className="preview-icon-cart"
            src="../../../../../../assets/img/preview.png"
          />
          <div className="color-block-items">
            <div className="color-block-item">
              <p className="quantity-size-label">Band Color:</p>
              <div className="color-preview-container-cart">
                <img
                  className="color-preview-cart"
                  src={`storage/${props.colorCombination.color?.attributeOptionPreview.image}`}
                />
              </div>
            </div>
            <div>
              <p className="quantity-size-label">Text Color:</p>
              <div className="color-preview-container-cart">
                <div
                  className="color-preview-cart"
                  style={{
                    backgroundColor:
                      props.colorCombination.textColor?.attributeOptionPreview
                        .hexCodes,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <IonIcon
            className="trash-can-button"
            slot="end"
            icon={pencil}
            onClick={navigateToOrder}
          />
        </div>
        <IonIcon
          className="trash-can-button"
          slot="end"
          icon={redCloseButton}
          onClick={() => {
            props.deleteColorCombination();
            props.setReadyToUpdate(true);
          }}
        />
      </div>
      {/*Quantity fields*/}
      <div className="quantity-size-arrow">
        {["youth", "adult", "large"].map((size: string) => (
          <div className="quantity-size-input-container" key={size}>
            <span className="quantity-size-label">
              {size.charAt(0).toUpperCase() + size.slice(1)}:
            </span>
            <input
              className="quantity-size-input"
              value={props.colorCombination[size]}
              placeholder="0"
              onChange={handleInputChange(size)}
              min="0"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default ColorCombination;
