import { useEffect, useState } from "react";
import { IonCard, IonCardHeader } from "@ionic/react";
import ColorCard from "../../../../components/Store/ColorCard/ColorCard";
import ModalWindow from "./ModalWindow/ModalWindow";
import "./ColorForm.css";
import { IProductOptions } from "../../../../types/IProductOptions";
import { IColorCombination } from "../../../../types/IColorCombination";
import { IAttributeOption } from "../../../../types/IAttributeOption";
import { ICombinationField } from "../../../../types/ICombinationField";
import { IAttributes } from "../../../../types/IAttributes";

interface IColorFormParams {
  styleColorSelected: IAttributes | undefined;
  setStyleColorSelected: (style: IAttributes | undefined) => void;
  colorsCollection: IProductOptions[];
  colorCombinations: IColorCombination[];
  selectedCombination: number;
  updateCombination: (
    index: number,
    field: ICombinationField,
    value: number | IAttributeOption,
  ) => void;
  addColorCombination: () => void;
  selectCombination: (index: number) => void;
}

const ColorForm: React.FC<IColorFormParams> = ({
  styleColorSelected,
  setStyleColorSelected,
  colorsCollection,
  selectedCombination,
  colorCombinations,
  updateCombination,
}) => {
  const selectThreeColorsFromEachGroup = (gallery: IProductOptions[]) => {
    return gallery.reduce(
      (acc: any, group: IProductOptions) =>
        acc.concat(group.options?.slice(0, 3) ?? []),
      [],
    );
  };

  //temporary approach to get feauture colors while we don't have other implementation
  const [featuredColors, setFeaturedColors] = useState(
    selectThreeColorsFromEachGroup(colorsCollection),
  );

  useEffect(() => {
    if (colorCombinations[selectedCombination].color) {
      const isColorFeatured = featuredColors.some(
        (color: any) =>
          color.id === colorCombinations[selectedCombination].color?.id,
      );

      if (!isColorFeatured) {
        const updatedColors = [...featuredColors]; // Create a copy of the array
        // Add selectedColor to the beginning of the array
        updatedColors.unshift(colorCombinations[selectedCombination].color);

        // Update the state with the new array
        setFeaturedColors(updatedColors.slice(0, -1));
      }
    }
  }, [colorCombinations[selectedCombination].color, featuredColors]);

  const showMoreColors = () => {
    setShowModal(true);
  };

  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  return (
    <section className="order-section">
      <div className="order-section-header">
        <div className="order-page-container">
          <h2 className="order-section-title">Color:</h2>
        </div>
      </div>

      <div className="wide-options-container">
        <div className="order-block-content color-card-content">
          {featuredColors.map((color: any, key: number) => {
            return (
              <ColorCard
                key={key}
                color={color}
                setShowModal={setShowModal}
                selectedCombination={selectedCombination}
                updateCombination={updateCombination}
                colorCombinations={colorCombinations}
              />
            );
          })}

          <IonCard
            className="color-card ion-no-margin"
            onClick={showMoreColors}
          >
            <div className="option-card-item-img-container">
              <img
                alt="wristband"
                src="assets/img/white.jpg"
                className="ion-card-image"
              />
              <div className="overlay-text-more">More Colors</div>{" "}
              {/* Overlay text */}
            </div>

            <IonCardHeader className="color-card-title-container ion-no-padding">
              <div className="dots-container">
                <div className="black-dot"></div>
                <div className="black-dot"></div>
                <div className="black-dot"></div>
              </div>
            </IonCardHeader>
          </IonCard>
        </div>
      </div>
      <ModalWindow
        setStyleColorSelected={setStyleColorSelected}
        styleColorSelected={styleColorSelected}
        colorsCollection={colorsCollection}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedCombination={selectedCombination}
        updateCombination={updateCombination}
        colorCombinations={colorCombinations}
      />
    </section>
  );
};
export default ColorForm;
