/*
 * Page displaying FAQ
 */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SectionTitle from "../../components/Titles/SectionTitle";
import AdBanner from "../../components/AddBanner/AddBanner";
import getFaqs from "../../services/getFaqs";
import FAQBlock from "./components/FAQBlock/FAQBlock";

import "./FAQ.css";

const FAQ: React.FC = () => {
  /*Getting Sections with faqs**/
  const [orderingList, setOrderingList] = useState([]),
    [siliconeList, setSiliconeList] = useState([]),
    [tyvekList, setTyvekList] = useState([]),
    [shippingList, setShippingList] = useState([]),
    [designList, setDesignList] = useState([]),
    [checkoutList, setCheckoutList] = useState([]),
    [refundsList, setRefundsList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      orderingList.length > 0 ||
      siliconeList.length > 0 ||
      tyvekList.length > 0 ||
      shippingList.length > 0 ||
      (designList.length > 0 && checkoutList.length > 0) ||
      refundsList.length > 0
    ) {
      setIsLoading(false);
    }
  }, [
    orderingList,
    siliconeList,
    tyvekList,
    shippingList,
    designList,
    checkoutList,
    refundsList,
  ]);

  useEffect(() => {
    getFaqs("Ordering Process").then((response) => {
      setOrderingList(response.data.data.faqs.data);
    });
  }, []);

  useEffect(() => {
    getFaqs("Silicone Wristband Styles").then((response) => {
      setSiliconeList(response.data.data.faqs.data);
    });
  }, []);

  useEffect(() => {
    getFaqs("Tyvek® Wristbands").then((response) => {
      setTyvekList(response.data.data.faqs.data);
    });
  }, []);

  useEffect(() => {
    getFaqs("Shipping").then((response) => {
      setShippingList(response.data.data.faqs.data);
    });
  }, []);

  useEffect(() => {
    getFaqs("Design").then((response) => {
      setDesignList(response.data.data.faqs.data);
    });
  }, []);

  useEffect(() => {
    getFaqs("Checkout Process").then((response) => {
      setCheckoutList(response.data.data.faqs.data);
    });
  }, []);

  useEffect(() => {
    getFaqs("Refunds/Discounts").then((response) => {
      setRefundsList(response.data.data.faqs.data);
    });
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent color="light">
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold no-uppercase">FAQs</span> About
                  Ordering Wristbands Online Policy
                </h2>
              }
              class="title-dark"
            />
            <AdBanner />
          </div>
          <div className="fixed-container fixed-container-white fixed-container-white-no-padding">
            {!isLoading ? (
              <IonGrid>
                <IonRow>
                  {/**Dividing FAQ for two columns */}
                  <IonCol size="12" size-md="6">
                    {orderingList.length > 0 && (
                      <FAQBlock
                        key={1}
                        questions={orderingList}
                        title="Ordering Process"
                      />
                    )}
                    {siliconeList.length > 0 && (
                      <FAQBlock
                        key={2}
                        questions={siliconeList}
                        title="Silicone Wristband Styles"
                      />
                    )}
                    {tyvekList.length > 0 && (
                      <FAQBlock
                        key={3}
                        questions={tyvekList}
                        title="Tyvek® Wristbands"
                      />
                    )}
                    {shippingList.length > 0 && (
                      <FAQBlock
                        key={4}
                        questions={shippingList}
                        title="Shipping"
                      />
                    )}
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    {designList.length > 0 && (
                      <FAQBlock key={5} questions={designList} title="Design" />
                    )}
                    {checkoutList.length > 0 && (
                      <FAQBlock
                        key={6}
                        questions={checkoutList}
                        title="Checkout Process"
                      />
                    )}
                    {refundsList.length > 0 && (
                      <FAQBlock
                        key={7}
                        questions={refundsList}
                        title="Refunds/Discounts"
                      />
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <div className="centered">
                <IonSpinner name="lines" color="tertiary" />
              </div>
            )}
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default FAQ;
