/*
 *Getting data about  from API
 */
import axios, { AxiosResponse } from "axios";

const getCouponDetails = (code: string): Promise<AxiosResponse> => {
  /*Getting the list of sections's faqs*/
  return axios.request({
    method: "POST",
    url: "/graphql",
    headers: {
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: `  query 
        couponDetails($code: String!) {
            couponDetails (code: $code){
            id
            name
            description
            startsFrom
            endsTill
            status
            couponType
            useAutoGeneration
            usagePerCustomer
            usesPerCoupon
            timesUsed
            conditionType
            discountAmount
            discountQuantity
            discountStep
            applyToShipping
            freeShipping
            couponCode
            }
        
      }`,
      variables: {
        code: code,
      },
    }),
  });
};

export default getCouponDetails;
