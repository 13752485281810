// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/occassions/glow1.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/img/occassions/glow2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.occasions-page ion-button {
    margin-top:0;
}
.occasions-page h2 {
    margin-bottom: 30px;
}
.occasions-page p{
    text-align: left;
}
.glow-picture {
    margin:0 auto;
    max-width:600px;
    height:400px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___})  no-repeat center top / cover;
}
.glow-picture:hover {
    margin:0 auto;
    max-width:600px;
    height:400px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_1___})  no-repeat center top / cover;
}
p.text-center {
    text-align: center;
}

/***Media***/

@media (max-width: 700px) {
    .occasions-page h2 {
        margin-bottom: 15px;
    }
}
@media (max-width: 400px) {
    .occasions-page h2 {
        margin-bottom: 10px;
    }
    .glow-picture, glow-picture:hover  {
        height:250px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Occasions/Occasions.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,eAAe;IACf,YAAY;IACZ,iFAAsF;AAC1F;AACA;IACI,aAAa;IACb,eAAe;IACf,YAAY;IACZ,iFAAsF;AAC1F;AACA;IACI,kBAAkB;AACtB;;AAEA,YAAY;;AAEZ;IACI;QACI,mBAAmB;IACvB;AACJ;AACA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".occasions-page ion-button {\n    margin-top:0;\n}\n.occasions-page h2 {\n    margin-bottom: 30px;\n}\n.occasions-page p{\n    text-align: left;\n}\n.glow-picture {\n    margin:0 auto;\n    max-width:600px;\n    height:400px;\n    background: url('../../assets/img/occassions/glow1.png')  no-repeat center top / cover;\n}\n.glow-picture:hover {\n    margin:0 auto;\n    max-width:600px;\n    height:400px;\n    background: url('../../assets/img/occassions/glow2.png')  no-repeat center top / cover;\n}\np.text-center {\n    text-align: center;\n}\n\n/***Media***/\n\n@media (max-width: 700px) {\n    .occasions-page h2 {\n        margin-bottom: 15px;\n    }\n}\n@media (max-width: 400px) {\n    .occasions-page h2 {\n        margin-bottom: 10px;\n    }\n    .glow-picture, glow-picture:hover  {\n        height:250px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
