const AboutSecondBlockMocs: JSX.Element = (
  <div>
    <h3>Customization</h3>
    <p>
      We offer the widest selection of colors, sizes, fonts, and clipart
      symbols, making it easy to create unique, one-of-a-kind customized
      bracelets. You can even upload your own image.
    </p>

    <img src="../../assets/img/about/2.png" />
    <p>
      Go to the order now tab on the home page today and follow the quick, easy
      steps to find out how to make your own wristband.
    </p>
  </div>
);

export default AboutSecondBlockMocs;
