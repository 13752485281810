import { useForm, Controller } from "react-hook-form";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonSpinner,
} from "@ionic/react";
import updateCustomerInfo from "../../../services/customer/updateCustomerInfo";
import { useState, useContext } from "react";
import { CurrentCustomerContext } from "../../../contexts/currentCustomer";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { ICustomer } from "../../../types/ICustomer";
import { IFormInputError } from "../../../types/IFormInputError";
import "./AccountForm.css";

const emailRegex = /^\S+@\S+\.\S+$/;

// Define the expected inputs for the main info form
interface IMainInfoFormInputs {
  firstName: string;
  lastName: string;
  email: string;
}

// Define the expected parameters for the main info form component
interface IMainFormParams {
  currentCustomer: ICustomer;
}

// Define the main info form component
const MainInfoForm: React.FC<IMainFormParams> = ({ currentCustomer }) => {
  // Get the token and customer from local storage
  const [token] = useLocalStorage("token");
  const [, setCustomer] = useLocalStorage("customer");
  const [loading, setLoading] = useState(false);

  // Get the current customer from the context
  const [, dispatch] = useContext(CurrentCustomerContext);

  // Define the form state and validation rules using react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IMainInfoFormInputs>({
    defaultValues: {
      firstName: currentCustomer.firstName,
      lastName: currentCustomer.lastName,
      email: currentCustomer.email,
    },
    mode: "all",
  });

  // Define state variables for the edit mode, saved state, and backend errors
  const [isEditable, setIsEditable] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [backendErrors, setBackendErrors] = useState<IFormInputError[]>([]);

  // Define the form submission function
  const onSubmit = (data: IMainInfoFormInputs) => {
    setLoading(true);
    // Call the update customer info service with the new data and token
    updateCustomerInfo(token, data.firstName, data.lastName, data.email)
      .then((response) => {
        setLoading(false);
        // Check for errors in the response
        if (response.data.errors) {
          const errors: IFormInputError[] = [];
          const randomNumber = Math.floor(Math.random() * 100) + 1; // lazy approach
          response.data.errors.forEach((err: { message: string }) => {
            errors.push({
              message: err.message,
              id: `password-${randomNumber}`,
            });
          });

          setBackendErrors(errors);
        } else {
          setBackendErrors([]);
        }

        // Update the local storage and current customer context with the new data
        setCustomer(JSON.stringify(response.data.data.updateAccount.customer));
        dispatch({
          type: "SET_AUTHORIZED",
          payload: response.data.data.updateAccount.customer,
        });

        // Set the saved and edit states
        setIsSaved(true);
        setIsEditable(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <IonCard className="account-form-card">
      <IonCardTitle className="account-form-card-title">
        Profile
        {!isEditable && (
          <span
            className="account-form-action"
            onClick={() => {
              setIsEditable(true);
              setIsSaved(false);
            }}
          >
            Edit
          </span>
        )}
      </IonCardTitle>
      <IonCardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isSaved && <p>Your profile has been saved.</p>}
          {backendErrors && backendErrors.length > 0 && (
            <span className="account-form-error">
              {backendErrors.map((error) => (
                <p key={error.id}>{error.message}</p>
              ))}
            </span>
          )}
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">First Name</IonLabel>
                <IonInput
                  type="text"
                  value={field.value}
                  onIonInput={field.onChange}
                  readonly={!isEditable}
                ></IonInput>
              </IonItem>
            )}
          />
          {errors.firstName && (
            <span className="account-form-error">This field is required</span>
          )}

          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">Last Name</IonLabel>
                <IonInput
                  type="text"
                  value={field.value}
                  onIonInput={field.onChange}
                  readonly={!isEditable}
                ></IonInput>
              </IonItem>
            )}
          />
          {errors.lastName && (
            <span className="account-form-error">This field is required</span>
          )}

          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: emailRegex,
                message: "Please enter a valid email",
              },
            }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  value={field.value}
                  onIonInput={field.onChange}
                  readonly={!isEditable}
                ></IonInput>
              </IonItem>
            )}
          />
          {errors.email && (
            <span className="account-form-error">{errors.email.message}</span>
          )}
          <div className="account-form-button-container">
            {/* Render the edit button if the form is not editable, otherwise render the submit button */}
            {isEditable && (
              <IonButton type="submit" disabled={isValid === false}>
                <span className="button-text">Submit Changes</span>
              </IonButton>
            )}
            {loading && <IonSpinner name="lines" />}
          </div>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default MainInfoForm;
