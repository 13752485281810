import {
  IonRouterLink,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
  IonText,
  IonItem,
  IonList,
  IonLabel,
} from "@ionic/react";
import SmallTitle from "../Titles/SmallTitle";
/*Import icins and images */
import chat from "../../assets/icon/1.svg";
import phone from "../../assets/icon/2.svg";
import location from "../../assets/icon/3.svg";
import facebook from "../../assets/icon/social/1.svg";
import twitter from "../../assets/icon/social/2.svg";
import instagram from "../../assets/icon/social/3.svg";
import map from "../../assets/img/map.png";
import logo from "../../assets/img/light-logo.svg";
/**Import CSS */
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <IonFooter className="footer">
      <div className="container">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="6" size-xl="4" className="footer-col">
              <SmallTitle title={<h5>Contact Us</h5>} />
              <p className="footer-text">
                Need Help? Have a question? Speak with a wristband specialist
                today.
              </p>
              <IonList lines="none" className="footer-list">
                <IonItem className="ion-no-padding">
                  <IonIcon size="large" slot="start" icon={phone} />
                  <IonLabel>
                    <IonText className="footer-list-item">
                      1-800-523-8078
                    </IonText>
                  </IonLabel>
                </IonItem>
                <IonItem className="ion-no-padding">
                  <IonIcon size="large" slot="start" icon={chat} />
                  <IonLabel>
                    <IonText className="footer-list-item">Chat with Us</IonText>
                  </IonLabel>
                </IonItem>
                <IonItem className="ion-no-padding item-location">
                  <IonIcon size="large" slot="start" icon={location} />
                  <IonLabel>
                    <IonText className="ion-text-wrap footer-list-item">
                      RapidWristbands.com
                      <br />
                      1056 Hercules Ave Houston, TX 77058
                    </IonText>
                  </IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
            <IonCol size="12" size-md="6" size-xl="4">
              <IonImg className="img-map ion-padding-vertical" src={map} />
            </IonCol>
            <IonCol size="12" size-md="6" size-xl="4" push-md="6" push-xl="0">
              <div className="footer-col ion-padding-horizontal">
                <SmallTitle title={<h5>Connect Online</h5>} />
                <div className="footer-icons-group">
                  <IonIcon size="large" icon={facebook} />
                  <IonIcon size="large" icon={twitter} />
                  <IonIcon size="large" icon={instagram} />
                </div>
                <SmallTitle title={<h5>Popular Pages</h5>} />
                <p className="small-margin-bottom">
                  Need Help? Have a question? Speak to a wristband specialist
                  today.
                </p>
                <div className="footer-reviews">
                  <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="53aa8807dec7e10d38f59f32"
                    data-businessunit-id="57509cc00000ff00058e45ed"
                    data-style-height="150px"
                    data-style-width="100%"
                    data-theme="dark"
                  >
                    {" "}
                    <a
                      href="https://www.trustpilot.com/review/rapidwristbands.com"
                      target="_blank"
                      rel="noopener"
                    >
                      Trustpilot
                    </a>{" "}
                  </div>
                </div>
              </div>
            </IonCol>
            <IonCol
              size="12"
              size-md="6"
              size-xl="4"
              pull-md="6"
              pull-xl="0"
              className="footer-logo-link"
            >
              <IonRouterLink routerLink="/home" routerDirection="none">
                <img
                  className="footer-logo"
                  src={logo}
                  alt="rapidwristbands.com"
                />
              </IonRouterLink>
              <p className="footer-copyright">
                Copyright © 2019 Rapid Wristbands. All Rights Reserved.
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonFooter>
  );
};

export default Footer;
