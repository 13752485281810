/*
 *Displaying the title with left alignment
 */
import "./Titles.css";

interface ISectionTitleParams {
  title: JSX.Element;
  class: string;
}

const SectionTitleLeft: React.FC<ISectionTitleParams> = (props) => {
  return <div className={`${props.class} ion-text-start`}>{props.title}</div>;
};

export default SectionTitleLeft;
