import { IonList } from "@ionic/react";
import ListItem from "./components/ListItem/ListItem";

interface IListParams {
  list: string[];
}

const List: React.FC<IListParams> = ({ list }) => {
  return (
    <IonList lines="none">
      {list.map((item: string) => (
        <ListItem key={item} item={item} />
      ))}
    </IonList>
  );
};

export default List;
