import "./AddBlockButton.css";
import { IonButton, IonIcon } from "@ionic/react";
import orangePlus from "../../../../../../assets/icon/orange-plus.svg";

interface IAddBlockButtonParams {
  addColorCombination: any;
}

const AddBlockButton: React.FC<IAddBlockButtonParams> = (props) => {
  return (
    <div>
      <IonButton
        fill="clear"
        type="button"
        onClick={props.addColorCombination}
        className="add-block-button"
      >
        <IonIcon icon={orangePlus} className="add-button-icon" />
        <span className="add-block-button-text">Add Color Combo</span>
      </IonButton>
    </div>
  );
};
export default AddBlockButton;
