interface FileData {
  url: string;
  file: File;
  fileName: string;
}

export const handleFileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setFile: (file: FileData) => void,
) => {
  if (!event.target.files || event.target.files.length === 0) {
    alert("No file selected!");
    return;
  }

  const file = event.target.files[0];

  // File size validation (300KB)
  if (file.size > 1000 * 1024) {
    alert("File size exceeds 300KB!");
    // Reset the file input
    event.target.value = "";
    return;
  }

  const acceptedFileFormats = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For DOCX files
    "image/jpeg",
    "image/png",
  ];

  // File type validation
  if (!acceptedFileFormats.includes(file.type)) {
    alert("Only PDF, JPEG, PNG, DOC, and DOCX formats are allowed!");
    // Reset the file input
    event.target.value = "";
    return;
  }

  // If file is valid, proceed with setting the file
  const imageUrl = URL.createObjectURL(file);
  setFile({ url: imageUrl, file: file, fileName: file.name });
};
