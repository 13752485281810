/*
 *Testiomonials Widget to display on the Occassions pages
 */
import React from "react";
import { IonicSlides, IonButton } from "@ionic/react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  Autoplay,
  Keyboard,
  Navigation,
  Pagination,
  Scrollbar,
  Zoom,
} from "swiper";
import TestimonialsItem from "./components/TestimonialsItem/TestimonialsItem";
import { ITestimonials } from "../../types/ITestimonials";
import SectionSubTitle from "../Titles/SectionSubTitle";
import { IonIcon } from "@ionic/react";
import arrowNext from "../../assets/icon/next.svg";
import arrowPrev from "../../assets/icon/prev.svg";
/*CSS*/
import "swiper/css";
import "@ionic/react/css/ionic-swiper.css";
import "./TestimonialsWidget.css";

interface ITestimonialsWidgetParams {
  testimonials: ITestimonials[];
}

interface IButtonsParams {
  children: JSX.Element | any;
}

const TestimonialsWidget: React.FC<ITestimonialsWidgetParams> = (props) => {
  {
    /* Button for swipers*/
  }
  const SwiperButtonNext: React.FC<IButtonsParams> = ({ children }) => {
    const swiper = useSwiper();
    return (
      <IonButton
        className="btn-slider"
        fill="clear"
        onClick={() => swiper && swiper.slideNext()}
      >
        {children}
      </IonButton>
    );
  };
  const SwiperButtonPrev: React.FC<IButtonsParams> = ({ children }) => {
    const swiper = useSwiper();
    return (
      <IonButton
        className="btn-slider"
        fill="clear"
        onClick={() => swiper && swiper.slidePrev()}
      >
        {children}
      </IonButton>
    );
  };
  return (
    <section className="testimonials-widget">
      <SectionSubTitle
        subtitle={<h3>What our Customers Saying</h3>}
        class="title-dark"
      />
      <div className="swiper-container">
        <div id="prev">
          {" "}
          <SwiperButtonPrev>
            <IonIcon icon={arrowPrev} size="small" />
          </SwiperButtonPrev>
        </div>
        <Swiper
          className="slider"
          modules={[
            Autoplay,
            Keyboard,
            Pagination,
            Scrollbar,
            Zoom,
            Navigation,
            IonicSlides,
          ]}
          autoplay={false}
          keyboard={true}
          pagination={true}
          scrollbar={true}
          zoom={true}
          slidesPerView={1}
          loop={true}
          navigation={{ nextEl: "#next", prevEl: "#prev" }}
        >
          {/**Getting testimonials to displa on each slide */}
          {props.testimonials.map((testimonialItem: ITestimonials, index) => (
            <SwiperSlide key={index}>
              <TestimonialsItem key={index} item={testimonialItem} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div id="next">
          <SwiperButtonNext>
            <IonIcon icon={arrowNext} size="small" />
          </SwiperButtonNext>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsWidget;
