// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-about h3 {
    margin-top:0;
}
.section-about img {
   display:block;
   width:80%;
   margin: 0 auto;
}
.section-about .about-full-img {
    width:100%;
    margin-top:15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/About/About.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;GACG,aAAa;GACb,SAAS;GACT,cAAc;AACjB;AACA;IACI,UAAU;IACV,eAAe;AACnB","sourcesContent":[".section-about h3 {\n    margin-top:0;\n}\n.section-about img {\n   display:block;\n   width:80%;\n   margin: 0 auto;\n}\n.section-about .about-full-img {\n    width:100%;\n    margin-top:15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
