/*
 * Function to get text for the Product Style Page
 */
import { IProducts } from "../types/IProduct";
import { IProductData } from "../types/IProductData";

const getProductStyleText = (
  product: IProducts,
  productData: IProductData[],
): any => {
  for (const includedData of productData) {
    if (
      includedData.type == "text" &&
      includedData.id ==
        product.relationships.text.data[1].attributes["product.lists.refid"]
    ) {
      return includedData.attributes["text.content"];
    }
  }
};

export default getProductStyleText;
