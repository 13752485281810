import { IonList, IonCard } from "@ionic/react";
import LinksWidgetPricesItem from "./components/LinksWidgetPricesItem";
import "./LinksWidgetPrices.css";
import { IProductOptions } from "../../../../types/IProductOptions";

interface ILinksWidgetPrices {
  productStyles: IProductOptions[];
  activeStyle: IProductOptions;
  setActiveStyle: (style: IProductOptions | undefined) => void;
}

const LinksWidgetPrices: React.FC<ILinksWidgetPrices> = (props) => {
  return (
    <IonCard className="card-widget">
      <IonList lines="none" class="widget-list">
        {props.productStyles.map((item: IProductOptions, index: number) => (
          <LinksWidgetPricesItem
            key={index}
            item={item}
            setActiveStyle={props.setActiveStyle}
            activeStyle={props.activeStyle}
          />
        ))}
      </IonList>
    </IonCard>
  );
};

export default LinksWidgetPrices;
