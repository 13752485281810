// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-faq {
    box-shadow:none;
    border-radius:0;
    border: 1px solid #EAEAEA;
    margin-left:0;
}
.card-faq ion-list {
    padding-bottom: 0;
    border-bottom:none;
}
.card-faq p{
    text-align: left;
}

/**Media**/
 @media (max-width: 767px) {
    .card-faq {
        margin-right:0;
    } 
 }`, "",{"version":3,"sources":["webpack://./src/pages/FAQ/components/FAQBlock/FAQBlock.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IACf,yBAAyB;IACzB,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;;AAEA,UAAU;CACT;IACG;QACI,cAAc;IAClB;CACH","sourcesContent":[".card-faq {\n    box-shadow:none;\n    border-radius:0;\n    border: 1px solid #EAEAEA;\n    margin-left:0;\n}\n.card-faq ion-list {\n    padding-bottom: 0;\n    border-bottom:none;\n}\n.card-faq p{\n    text-align: left;\n}\n\n/**Media**/\n @media (max-width: 767px) {\n    .card-faq {\n        margin-right:0;\n    } \n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
