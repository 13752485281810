import { IonRouterLink } from "@ionic/react";

const SecondBlokMocs: JSX.Element = (
  <div>
    {" "}
    <p>
      With various wristband styles and types, our affordable wristbands make
      perfect promotional items to show support for a team, advertise for a
      brand, promote a cause, commemorate an event, or publicize an affiliation!
    </p>
    <p>
      We offer a multitude of occasion wristbands, including memorial, military,
      fundraiser, event, and cancer!
    </p>
    <p>
      <span className="text-attention">
        More importantly, our affordable custom wristbands are popular items to
        help raise awareness!
      </span>{" "}
      Check out our{" "}
      <IonRouterLink
        routerLink="/blog"
        routerDirection="none"
        className="text-important"
      >
        blog
      </IonRouterLink>{" "}
      to learn more about all the causes we support.
    </p>
  </div>
);

export default SecondBlokMocs;
