import { IonRow, IonGrid } from "@ionic/react";
import TrustingCompaniesItem from "./components/TrustingCompaniesItem/TrustingCompaniesItem";
import { ITrustingCompany } from "../../types/ITrustingCompany";
import "./TrustingCompaniesList.css";

interface ITrustingCompaniesListParams {
  list: ITrustingCompany[];
}

const TrustingCompaniesList: React.FC<ITrustingCompaniesListParams> = ({
  list,
}) => {
  return (
    <IonGrid className="trusting-companies">
      <IonRow>
        {list.map((company: ITrustingCompany) => (
          <TrustingCompaniesItem key={company.id} item={company} />
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default TrustingCompaniesList;
