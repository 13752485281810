import { useCallback, useRef } from "react";

// Define the hook using generic types to allow it to be used with any function signature.
function useDebounce<T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
): T {
  // Use useRef to hold a mutable reference to the timeout ID.
  const timer = useRef<ReturnType<typeof setTimeout>>();

  // useCallback ensures that the debounced function itself is memoized.
  const debouncedCallback = useCallback(
    (...args: Parameters<T>) => {
      // Clear any existing timer before setting a new one.
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  ) as T;

  return debouncedCallback;
}

export default useDebounce;
