import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";
import { MenuItems, Pages } from "../ListOfPages";
import "./Menu.css";

const Menu: React.FC = () => {
  /*Function to render one menu item*/
  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem button routerLink={p.path} routerDirection="none">
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }
  return (
    <IonMenu side="end" contentId="main" className="side-menu">
      <IonHeader className="ion-no-border menu-header">
        <IonToolbar color="light">
          <IonTitle className="menu-title" color="primary">
            Menu
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>{renderlistItems(MenuItems)}</IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
