import axios from "axios";
import { SetStateAction, useState } from "react";

import { IChangeEvent } from "../types/IChangeEvent";
import { IField, InputChangeEvent } from "../types/IFormField";
import { IFormInputError } from "../types/IFormInputError";
import { IFormValidationRule } from "../types/IFormValidationRule";

export const useFormInput = (initialValue = "") => {
  const [value, setValue] = useState(initialValue);

  const handleChange = async (e: InputChangeEvent): Promise<void> => {
    let tempValue = "";

    if ("currentTarget" in e && e.currentTarget) {
      tempValue = (e.currentTarget as any).value;
    } else if ("detail" in e && e.detail) {
      tempValue = e.detail.value || "";
    }

    setValue(tempValue);
  };

  return {
    value,
    reset: (newValue: SetStateAction<string>) => setValue(newValue),
    onIonChange: handleChange,
    onKeyUp: handleChange,
  };
};

export const useFormSelect = (initialValue: string | string[] = "") => {
  const [value, setValue] = useState(initialValue);

  const handleChange = async (e: IChangeEvent) => {
    const tempValue = await e.currentTarget.value;
    setValue(tempValue);
  };

  return {
    value,
    reset: (newValue: SetStateAction<string | string[]>) => setValue(newValue),
    onIonChange: handleChange,
  };
};

export const googleLogin = () => {
  axios
    .request({
      method: "GET",
      url: "/api/social?provider=google",
    })
    .then((response) => {
      // handle successful response here
      console.log("SUCCESS_SOCIAL_LOGIN", { response });
      if (response.data && response.data.code == 200) {
        setTimeout(() => {
          window.location.href = response.data.data.redirect_url;
        }, 250);
      }
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 400 || error.response.status === 400)
      ) {
        // handle 400 error here
        console.log("Bad Request", { response: error.response });
      } else {
        // handle other errors here
        console.log("Something went wrong", { response: error.response });
      }
    });
};

export const validateForm = (
  fields: IField[],
  rules: IFormValidationRule[] = [],
) => {
  const errors: IFormInputError[] = [];

  fields.forEach((field) => {
    if (field.required) {
      let fieldValue;

      if ("input" in field) {
        fieldValue = field.input.state.value;
      } else if ("select" in field) {
        fieldValue = field.select.state.value;
      } else if ("textarea" in field) {
        fieldValue = field.textarea.state.value;
      }

      if (fieldValue === "") {
        const error = {
          id: field.id,
          message: `${field.label} is required.`,
        };

        errors.push(error);
      }
    }

    rules.forEach((rule) => {
      if (rule.fieldId === field.id) {
        const possibleError = rule.validate(field);

        if (possibleError) {
          errors.push(possibleError);
        }
      }
    });
  });

  return errors;
};
