// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonials-widget {
    border-top: 1px solid #CECECE;
}
.testimonials-widget h2 {
    margin-bottom: 0;
}
.swiper-container {
    display: flex;
    align-items: center;
}
.btn-slider-testimonial,
.btn-slider-testimonial:hover,
.btn-slider-testimonial:active {
    opacity: 0.5;
}
.testimonials-widget ion-title h3{
   text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/components/TestimonialsWidget/TestimonialsWidget.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;;;IAGI,YAAY;AAChB;AACA;GACG,iBAAiB;AACpB","sourcesContent":[".testimonials-widget {\n    border-top: 1px solid #CECECE;\n}\n.testimonials-widget h2 {\n    margin-bottom: 0;\n}\n.swiper-container {\n    display: flex;\n    align-items: center;\n}\n.btn-slider-testimonial,\n.btn-slider-testimonial:hover,\n.btn-slider-testimonial:active {\n    opacity: 0.5;\n}\n.testimonials-widget ion-title h3{\n   text-align: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
