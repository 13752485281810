import "./Titles.css";

interface ISmallTitleParams {
  title: JSX.Element;
}

const SmallTitle: React.FC<ISmallTitleParams> = (props) => {
  return <div className="small-title ion-no-padding">{props.title}</div>;
};

export default SmallTitle;
