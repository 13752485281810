// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text ion-label.text-list {
  color: #003365;
  font-size: 16px;
  font-weight:600;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  margin-left: 20px;
}
.text ion-item:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 6px;
  height: 4px;
  background-color: #003365;
  z-index: 10;
}
ion-item.text-list-item {
  --min-height:20px;
}


@media (max-width: 800px) {
  .text ion-label.text-list {
    margin: 5px 0 5px 10px;
  }
}

@media (max-width: 500px) {
  .text ion-label.text-list {
    font-size: 14px;
  }
}



`, "",{"version":3,"sources":["webpack://./src/components/List/components/ListItem/ListItem.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,eAAe;EACf,oCAAoC;EACpC,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,WAAW;EACX,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,iBAAiB;AACnB;;;AAGA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".text ion-label.text-list {\r\n  color: #003365;\r\n  font-size: 16px;\r\n  font-weight:600;\r\n  font-family: 'Open Sans', sans-serif;\r\n  position: relative;\r\n  margin-left: 20px;\r\n}\r\n.text ion-item:before {\r\n  content: \"\";\r\n  display: block;\r\n  position: absolute;\r\n  top: 50%;\r\n  width: 6px;\r\n  height: 4px;\r\n  background-color: #003365;\r\n  z-index: 10;\r\n}\r\nion-item.text-list-item {\r\n  --min-height:20px;\r\n}\r\n\r\n\r\n@media (max-width: 800px) {\r\n  .text ion-label.text-list {\r\n    margin: 5px 0 5px 10px;\r\n  }\r\n}\r\n\r\n@media (max-width: 500px) {\r\n  .text ion-label.text-list {\r\n    font-size: 14px;\r\n  }\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
