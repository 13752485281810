import "./ThicknessForm.css";
import { IProductOptions } from "../../../../types/IProductOptions";

interface IThinknessFormParams {
  selectedThickness: IProductOptions | undefined;
  setSelecteThickness: (thinkness: IProductOptions) => void;
  widths: IProductOptions[];
}

const ThicknessForm: React.FC<IThinknessFormParams> = (props) => {
  return (
    <section className="order-section">
      <div className="order-section-header">
        <div className="order-page-container">
          <h2 className="order-section-title">Width:</h2>
        </div>
      </div>

      <div className="order-page-container">
        <div className="order-block-content options-line">
          {props.widths.map((width: any, key: number) => (
            <div key={key} className="width-option-item">
              <input
                type="radio"
                id={`radio-${width.id}`}
                name="thickness"
                value={width.id}
                checked={props.selectedThickness?.id === width.id}
                onChange={() => props.setSelecteThickness(width)}
                className="radio-item"
              />
              <label htmlFor={`radio-${width.id}`} className="size-label">
                {`${width.adminName} inch`}
              </label>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default ThicknessForm;
