import {
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import "./TextColorCard.css";
import { IColorCombination } from "../../../types/IColorCombination";
import { ICombinationField } from "../../../types/ICombinationField";
import { IProductOptions } from "../../../types/IProductOptions";

interface IOptionCardParams {
  setShowModal: (visibility: boolean) => void;
  color: IProductOptions;
  updateCombination: (
    index: number,
    field: ICombinationField,
    value: number | IProductOptions,
  ) => void;
  colorCombinations: IColorCombination[];
  selectedCombination: number;
}

const TextColorCard: React.FC<IOptionCardParams> = ({
  color,
  setShowModal,
  updateCombination,
  colorCombinations,
  selectedCombination,
}) => {
  return (
    <IonCard
      className={`color-card ion-no-margin ${
        colorCombinations[selectedCombination]?.textColor?.id === color.id
          ? "option-card-selected"
          : ""
      }`}
      onClick={() => {
        updateCombination(selectedCombination, "textColor", color);
        setShowModal(false);
      }}
    >
      <div
        className="text-color-block"
        style={{ backgroundColor: color.attributeOptionPreview.hexCodes }}
      ></div>

      <div className="option-card-divider"></div>
      <IonCardHeader className="color-card-title-container ion-no-padding">
        <IonCardTitle className="option-card-title color-card-title">
          {color.adminName}
        </IonCardTitle>
        <IonCardSubtitle
          className={`color-card-subtitle ${
            colorCombinations[selectedCombination]?.textColor?.id === color.id
              ? "color-card-subtitle-show"
              : ""
          }`}
        >
          {color.swatchValue}
        </IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
};
export default TextColorCard;
