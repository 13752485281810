// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial-content {
    display:flex;
    text-align: start;
    font-style: italic;
    font-size:16px;
    font-weight:600;
}
.testimonial-card {
    box-shadow:none;
    border:none;
}
.testimonial-author {
    font-size:14px;
    margin-top:7px;
}

/**Media**/

@media (max-width: 767px) {
    .img-container-testimonials {
        display:flex;
        justify-content: center;
    }
    .testimonial-card blockquote{
        text-align: center;
        margin-inline-start: 15px;
        margin-inline-end: 15px;
    }
    .testimonial-content {
        padding:5px;
    }
    .testimonial-card {
        padding:5px;
        margin:0;
    }
}

@media (max-width: 450px) {

    .testimonial-card blockquote{
        margin-inline-start: 0;
        margin-inline-end: 0;
    }
    .testimonial-card {
        padding:0;
        margin:0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/TestimonialsWidget/components/TestimonialsItem/TestimonialsItem.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA,UAAU;;AAEV;IACI;QACI,YAAY;QACZ,uBAAuB;IAC3B;IACA;QACI,kBAAkB;QAClB,yBAAyB;QACzB,uBAAuB;IAC3B;IACA;QACI,WAAW;IACf;IACA;QACI,WAAW;QACX,QAAQ;IACZ;AACJ;;AAEA;;IAEI;QACI,sBAAsB;QACtB,oBAAoB;IACxB;IACA;QACI,SAAS;QACT,QAAQ;IACZ;AACJ","sourcesContent":[".testimonial-content {\n    display:flex;\n    text-align: start;\n    font-style: italic;\n    font-size:16px;\n    font-weight:600;\n}\n.testimonial-card {\n    box-shadow:none;\n    border:none;\n}\n.testimonial-author {\n    font-size:14px;\n    margin-top:7px;\n}\n\n/**Media**/\n\n@media (max-width: 767px) {\n    .img-container-testimonials {\n        display:flex;\n        justify-content: center;\n    }\n    .testimonial-card blockquote{\n        text-align: center;\n        margin-inline-start: 15px;\n        margin-inline-end: 15px;\n    }\n    .testimonial-content {\n        padding:5px;\n    }\n    .testimonial-card {\n        padding:5px;\n        margin:0;\n    }\n}\n\n@media (max-width: 450px) {\n\n    .testimonial-card blockquote{\n        margin-inline-start: 0;\n        margin-inline-end: 0;\n    }\n    .testimonial-card {\n        padding:0;\n        margin:0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
