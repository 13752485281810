// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-gallery {
  width: unset;
  margin-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  cursor: pointer;
}
.btn-gallery ion-button.btn-slider,
.btn-gallery ion-button.btn-slider:hover {
  width: unset;
  margin-top: 0;
  --padding-start: 0;
  --padding-end: 0;
  opacity: 0.6;
}
div.modal-gallery .swiper-wrapper {
  background-color: white;
  align-items: center;
}
.swiper-container {
  height: 97%;
}
button.button-native,
button.button-native::after {
  --padding-start: 0;
  --padding-end: 0;
}
button.button-native,
button.button-native::after ::part(native) {
  --padding: 0;
}
.modal-slide-number {
  display: flex;
  justify-content: end;
  opacity: 0.5;
}
/***Media***/
@media (max-width: 850px) {
  .swiper-container {
    height: 95%;
  }
}
@media (max-width: 650px) {
  .swiper-container {
    height: 93%;
  }
}
@media (max-width: 450px) {
  .swiper-container {
    height: 93%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Gallery/components/GallerySlider/GallerySlider.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;AACA;;EAEE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;;EAEE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;;EAEE,YAAY;AACd;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,YAAY;AACd;AACA,YAAY;AACZ;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".btn-gallery {\n  width: unset;\n  margin-top: 0;\n  --padding-start: 0;\n  --padding-end: 0;\n  cursor: pointer;\n}\n.btn-gallery ion-button.btn-slider,\n.btn-gallery ion-button.btn-slider:hover {\n  width: unset;\n  margin-top: 0;\n  --padding-start: 0;\n  --padding-end: 0;\n  opacity: 0.6;\n}\ndiv.modal-gallery .swiper-wrapper {\n  background-color: white;\n  align-items: center;\n}\n.swiper-container {\n  height: 97%;\n}\nbutton.button-native,\nbutton.button-native::after {\n  --padding-start: 0;\n  --padding-end: 0;\n}\nbutton.button-native,\nbutton.button-native::after ::part(native) {\n  --padding: 0;\n}\n.modal-slide-number {\n  display: flex;\n  justify-content: end;\n  opacity: 0.5;\n}\n/***Media***/\n@media (max-width: 850px) {\n  .swiper-container {\n    height: 95%;\n  }\n}\n@media (max-width: 650px) {\n  .swiper-container {\n    height: 93%;\n  }\n}\n@media (max-width: 450px) {\n  .swiper-container {\n    height: 93%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
