import {
  IonHeader,
  IonList,
  IonLabel,
  IonRouterLink,
  IonMenuButton,
  IonIcon,
} from "@ionic/react";
import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router";
import { MenuItems, Pages } from "../ListOfPages";
import { CurrentCustomerContext } from "../../contexts/currentCustomer";
import fullCart from "../../assets/icon/full-cart.svg";
import emptyCart from "../../assets/icon/empty-cart.svg";
import "./Header.css";
import { CartContext } from "../../contexts/cart";
import getCouponDetails from "../../services/checkout/getCouponDetails";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ICoupon } from "../../types/Cart/ICoupon";
import applyCoupon from "../../services/checkout/applyCoupon";
import { ICart } from "../../types/Cart/ICart";

interface MediaQueryState {
  matches: boolean;
}

const Header: React.FC = () => {
  const location = useLocation();
  //Getting customer state to display 'sing in' or 'account' options
  const [currentCustomerState] = useContext(CurrentCustomerContext);
  //Getting cart state to show full or empty cart
  const [cartState, dispatchCart] = useContext(CartContext);
  const [cart, setCart] = useState<ICart | any>();
  const [token] = useLocalStorage("token");
  //Checking if it is the order pagr
  const isOrderPage =
    location.pathname === "/order" || location.pathname === "/order/";
  const isCart =
    location.pathname === "/cart" || location.pathname === "/cart/";
  const isReceipt = location.pathname === "/order/receipt";
  const isCheckout = location.pathname === "/order/checkout";
  const isOrderProcessPage = isOrderPage || isCart || isReceipt || isCheckout;
  /*Function to render one menu item*/
  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonRouterLink
          key={p.title}
          routerLink={p.path}
          routerDirection="none"
          color="secondary"
          className={
            location.pathname.startsWith(p.path)
              ? "nav-item selected"
              : "nav-item"
          }
        >
          <IonLabel>{p.title}</IonLabel>
        </IonRouterLink>
      ));
  }

  /*Listening the screen size*/
  const [mQuery, setMQuery] = React.useState<MediaQueryState>({
    matches: window.innerWidth > 1000 ? true : false,
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1000px)");
    mediaQuery.addEventListener("change", setMQuery);
    return () => mediaQuery.removeEventListener("change", setMQuery);
  }, []);

  //checking Coupon from the link

  const [coupon, setCoupon] = useState<ICoupon | any>();
  const [couponError, setCouponError] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [, setCouponDetails] = useLocalStorage("coupon");
  const [isReadyToShowCouponOnTop, setIsReadyToShowCouponOnTop] =
    useState<boolean>(false);
  const [isReadyToShowInvalidCouponOnTop, setIsReadyToShowInvalidCouponOnTop] =
    useState<boolean>(false);

  //Getting Coupon from context
  useEffect(() => {
    setCoupon(cartState.coupon);
  }, [cartState]);

  //Checking what message is ready to show
  useEffect(() => {
    setIsReadyToShowCouponOnTop(
      cartState.coupon?.status == true && !isOrderPage && !isCart,
    );
    setIsReadyToShowInvalidCouponOnTop(
      ((coupon && cartState.coupon?.status == false) || couponError) &&
        !isOrderPage &&
        !isCart,
    );
  }, [
    coupon,
    cartState.cart,
    cartState.coupon?.status,
    cartState.cart?.discountAmount,
    isCart,
    isOrderPage,
    isCheckout,
    couponError,
  ]);

  useEffect(() => {
    // Parse the URL to get the coupon code
    const urlParams = new URLSearchParams(window.location.search);
    const coupon = urlParams.get("coupon");
    if (coupon) {
      setCouponCode(coupon);
    }
  }, []);

  //checking coupon if it exists
  useEffect(() => {
    if (couponCode) {
      checkCoupon();
    }
  }, [couponCode]);

  //function to check coupon if it is ok
  const checkCoupon = async () => {
    try {
      const response = await getCouponDetails(couponCode);
      if (response.data.errors) {
        setCouponDetails("");
        dispatchCart({
          type: "STORE_COUPON",
          payload: null,
        });
        setCouponError(true);
      }
      //handleErrorResponse(response);
      const couponStatus = response?.data?.data?.couponDetails?.status ?? false;
      if (couponStatus) {
        setCouponDetails(JSON.stringify(response.data.data.couponDetails));
        dispatchCart({
          type: "STORE_COUPON",
          payload: response.data.data.couponDetails,
        });
        setCouponError(false);
      }
      if (cartState.cart) {
        try {
          const response = await applyCoupon(
            couponCode,
            token,
            cartState.guestId,
            cart?.id || cartState.cart?.id || 0,
          );
          if (response.data.data.applyCoupon.cart) {
            setCart(response.data.data.applyCoupon.cart);
            dispatchCart({
              type: "SAVE_CART",
              payload: response.data.data.applyCoupon.cart,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      setCouponError(true);
      console.error(error);
    }
  };

  return (
    <>
      <IonHeader className="ion-no-border header">
        <IonRouterLink
          className="nav-item"
          routerLink="/home"
          routerDirection="none"
          color="secondary"
        >
          <img
            className={`header-logo ${
              isOrderProcessPage ? "header-order-logo" : ""
            }`}
            src="../../../assets/img/logo.svg"
            alt="rapidwristbands.com"
          />
        </IonRouterLink>

        <IonRouterLink
          className="nav-icon-item"
          routerLink="/cart"
          routerDirection="none"
        >
          <IonIcon
            className="cart-icon"
            icon={
              cartState &&
              cartState.cart &&
              cartState.cart.items &&
              cartState.cart.items.length > 0
                ? fullCart
                : emptyCart
            }
          />
        </IonRouterLink>

        {(mQuery && !mQuery.matches) || isOrderProcessPage ? (
          <IonMenuButton
            className={`active-button menu-button ${
              isOrderProcessPage ? "menu-button-order " : ""
            }`}
            color="secondary"
          >
            <img src="../../../assets/icon/menu.svg" alt="menu" />
          </IonMenuButton>
        ) : (
          <>
            <div className="header-items">
              <div className="reviews-container">
                <div className="nav-phone">
                  <a href="tel:+13993445423">
                    <img src="../../../assets/icon/phone.svg" alt="phone" />
                  </a>
                  <span>1(800)523-8078</span>
                </div>
                <div className="review-item">
                  <a
                    href="https://www.bbb.org/us/tx/houston/profile/wearable-promotional-products/rapid-wristbands-0915-90043132/#sealclick"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      src="https://seal-houston.bbb.org/seals/blue-seal-200-42-bbb-90043132.png"
                      style={{ border: 0 }}
                      alt="Rapid Wristbands BBB Business Review"
                    />
                  </a>
                </div>
                <div className="review-item">
                  <a
                    href="https://www.shopperapproved.com/reviews/rapidwristbands.com"
                    target="blank"
                    className="shopperlink new-sa-seals placement-856"
                  >
                    <img
                      src="https://www.shopperapproved.com/seal/16600/856-sa-seal.gif"
                      style={{ borderRadius: 4, height: 42 }}
                      alt="Customer Reviews"
                      onContextMenu={() => {
                        const d = new Date();
                        alert(
                          `Copying Prohibited by Law - This image and all included logos are copyrighted by Shopper Approved \\251 ${d.getFullYear()}.`,
                        );
                        return false;
                      }}
                    />
                  </a>
                </div>
              </div>

              {/*Main Navigation*/}
              <IonList className="nav">{renderlistItems(MenuItems)}</IonList>
              {/*Phone*/}
            </div>

            {/*Sign in/Cart*/}
            <IonList className="nav-right">
              <IonRouterLink
                className="nav-right-item"
                routerLink="/cart"
                routerDirection="none"
              >
                <div>
                  <img src="../../../assets/icon/cart.svg" alt="cart" />
                  <IonLabel>View Cart</IonLabel>
                </div>
              </IonRouterLink>

              <IonRouterLink
                className="nav-right-item"
                routerLink={
                  currentCustomerState.isLoggedIn ? "/account" : "/login"
                }
                routerDirection="none"
              >
                <div>
                  <img src="../../../assets/icon/person.svg" alt="login" />
                  <IonLabel>
                    {currentCustomerState.isLoggedIn ? "Account" : "Sign in"}
                  </IonLabel>
                </div>
              </IonRouterLink>
            </IonList>
          </>
        )}
      </IonHeader>
      {isReadyToShowCouponOnTop && coupon?.description ? (
        <div className="coupon-container">
          <div className="coupon-text">{coupon.description}</div>
        </div>
      ) : (
        <></>
      )}
      {isReadyToShowInvalidCouponOnTop ? (
        <div className="coupon-container coupon-container-error">
          The coupon is invalid
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
