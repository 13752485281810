/* eslint-disable indent */
import {
  IonContent,
  IonPage,
  IonLabel,
  IonCheckbox,
  IonTitle,
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonCard,
  IonSpinner,
  IonText,
  IonRouterLink,
} from "@ionic/react";
import { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router";
/*Components*/
import Header from "../../components/Header/Header";
import AddressForm from "../Account/AccountForms/AddressForm";
import GuestInfoForm from "../Account/AccountForms/GuestInfoForm";
/*Custom hooks*/
import useLocalStorage from "../../hooks/useLocalStorage";
/*Contects*/
import { CartContext } from "../../contexts/cart";
import { CurrentCustomerContext } from "../../contexts/currentCustomer";
/*Types/interfaces*/
import { IFormInputError } from "../../types/IFormInputError";
import { ICart } from "../../types/Cart/ICart";
/*Services (GraphQl)*/
import checkout from "../../services/checkout/checkout";
import saveCheckoutAddresses from "../../services/checkout/saveCheckoutAddresses";
import getShippingMethods from "../../services/checkout/getShippingMethods";
import saveShippingMethod from "../../services/checkout/saveShippingMethod";
import savePaymentMethod from "../../services/checkout/savePaymentMethod";
/*CSS*/
import "./CheckoutPage.css";
import { AxiosRequestConfig } from "axios";
import { IPaymentMethod } from "../../types/Checkout.ts/IPaymentMethod";
import { IShippingMethod } from "../../types/Checkout.ts/IShippingMethod";
import addPurchaseOrderFile from "../../services/checkout/addPurchaseOrderFile";
import addTaxExemption from "../../services/checkout/addTaxExemption";
import OrderItem from "../Receipt/components/OrderItem/OrderItem";
import AdditionalButton from "../../components/Store/AdditionalButton/AdditionalButton";
import applyCoupon from "../../services/checkout/applyCoupon";
import addTaxExemptFile from "../../services/checkout/addTaxExemptFile";
import { handleFileChange } from "../../functions/fileHandler";

const CheckoutPage: React.FC = () => {
  const [cartState] = useContext(CartContext);
  const [cart, setCart] = useState<ICart>();
  const [customer, setCustomer] = useState<any>();
  const [isGuest, setIsGuest] = useState<boolean>(false);
  const [guestProfileIsComplete, setGuestProfileIsComplete] =
    useState<boolean>(false);
  const [currentCustomerState] = useContext(CurrentCustomerContext);
  const [customerFromLocalStorage, , removeCustomerFromLS] =
    useLocalStorage("customer");
  const history = useHistory();
  const [token] = useLocalStorage("token");
  const [, dispatchCart] = useContext(CartContext);
  const [, , removeCart] = useLocalStorage("cart");
  const [, , removeCoupon] = useLocalStorage("coupon");
  //checkout variables
  const [backendErrors, setBackendErrors] = useState<IFormInputError[]>([]);
  const [shippingMethods, setShippingMethods] = useState<IShippingMethod[]>();
  const [selectedShipmentMethod, setSelectedShipmentMethod] =
    useState<string>();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>();

  //Loading variables
  const [loadingShippingMethods, setLoadingShippingMethods] = useState(false);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false);
  const [loading, setLoading] = useState(false);

  //Setting order details visibility
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const handleViewDetails = () => {
    setIsDetailsVisible(!isDetailsVisible);
  };

  //Variable to store the adddress from state
  const [addressFormActive, setAddressFormActive] = useState<
    boolean | undefined
  >(undefined);

  //Handling Tax Exemption
  const [isTaxExempt, setIsTaxExempt] = useState(false);
  const isManualChange = useRef(false);
  const [taxExemptFile, setTaxExemptFile] = useState<{
    url: string;
    file: File;
    fileName: string;
  } | null>(null);

  const [taxId, setTaxId] = useState<string>("");
  const [taxIdError, setTaxIdError] = useState<string>();

  // Function to validate the tax ID (must be 9 digits including the dash)
  const validateTaxId = (value: string) => {
    const isValid = /^\d{2}-\d{7}$/.test(value);
    setTaxIdError(isValid ? "" : "The Tax Id should be 9 digits");
    return isValid;
  };

  // Function to format the tax ID (add dash after first 2 digits)
  const formatTaxId = (value: string) => {
    const numbersOnly = value.replace(/\D/g, "");
    if (numbersOnly.length > 2) {
      return numbersOnly.slice(0, 2) + "-" + numbersOnly.slice(2, 9);
    }
    return numbersOnly;
  };

  // Handler for input change
  const handleTaxIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = formatTaxId(value);
    setTaxId(value);
    validateTaxId(value.replace("-", ""));
  };

  const triggerTaxFileInput = () => {
    const fileInput = document.getElementById("taxExemptploader");
    fileInput && fileInput.click();
  };

  const handleTaxExemptChange = () => {
    setIsTaxExempt((prevState) => {
      isManualChange.current = true;
      return !prevState;
    });
  };
  const handleTaxExempt = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event, setTaxExemptFile);
  };

  useEffect(() => {
    if (isManualChange.current && cartState.cart?.id) {
      saveTaxExemption(cartState.cart.id);
      isManualChange.current = false; // Reset the manual change flag
    }
  }, [isTaxExempt, cartState.cart?.id]);

  useEffect(() => {
    if (cartState.cart) {
      setIsTaxExempt(cartState.cart.isTaxExempt);
    }
  }, [cartState.cart]);

  //Save Tax Exemption  File
  const saveTaxExemptFile = async (orderId: number) => {
    if (taxExemptFile) {
      try {
        await addTaxExemptFile(token, orderId, taxId || "", taxExemptFile.file);
      } catch (error) {
        console.error(error);
      }
    }
    if (!taxExemptFile && taxId) {
      await addTaxExemptFile(token, orderId, taxId);
    }
  };

  //save tax exemption
  const saveTaxExemption = async (cartId: string | number) => {
    try {
      await addTaxExemption(
        +cartId,
        cartState.guestId,
        isTaxExempt,
        token,
      ).then((response) => {
        setCart(response.data.data.addTaxExemption.cart);
        dispatchCart({
          type: "SAVE_CART",
          payload: response.data.data.addTaxExemption.cart,
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  //Handling Purchase Order
  const [POFile, setPOFile] = useState<{
    url: string;
    file: File;
    fileName: string;
  } | null>(null);
  const [PONumberError, setPONumberError] = useState<string>();
  const [PONumber, setPONumber] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const handlePOChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event, setPOFile);
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("POUploader");
    fileInput && fileInput.click();
  };

  //Save Purchase Order File
  const savePOFile = async (orderId: number) => {
    if (POFile) {
      try {
        await addPurchaseOrderFile(token, orderId, PONumber || "", POFile.file);
      } catch (error) {
        console.error(error);
      }
    }
    if (!POFile && PONumber) {
      await addPurchaseOrderFile(token, orderId, PONumber);
    }
  };

  //Getting Cart from context
  useEffect(() => {
    if (cartState.cart) {
      setCart(cartState.cart);
    }
  }, [cartState]);
  //Getting Customer from context
  useEffect(() => {
    if (!currentCustomerState.currentCustomer) {
      const currentCart = cartState.cart;

      if (
        currentCart &&
        currentCart.id > 0 &&
        currentCart.customerEmail == null &&
        currentCart.customerFirstName == null
      ) {
        const cFLS = customerFromLocalStorage
          ? JSON.parse(customerFromLocalStorage)
          : null;

        const firstName = cFLS && cFLS.firstName ? cFLS.firstName : "Guest";
        const lastName = cFLS && cFLS.lastName ? cFLS.lastName : "User";
        const email = cFLS && cFLS.email ? cFLS.email : "";
        const addresses = cFLS && cFLS.addresses ? cFLS.addresses : [];

        const guestCustomer = {
          id: null,
          firstName,
          lastName,
          email,
          addresses,
        };

        setCustomer(guestCustomer);
        setIsGuest(true);
      }
    } else {
      if (
        (currentCustomerState.currentCustomer.firstName === "Guest" &&
          currentCustomerState.currentCustomer.lastName === "User") ||
        !currentCustomerState.isLoggedIn
      ) {
        setIsGuest(true);
      }

      setCustomer(currentCustomerState.currentCustomer);

      if (
        currentCustomerState.currentCustomer.firstName !== "Guest" &&
        currentCustomerState.currentCustomer.lastName !== "User" &&
        !currentCustomerState.isLoggedIn
      ) {
        setGuestProfileIsComplete(true);
      }
    }
  }, [currentCustomerState]);

  useEffect(() => {
    if (selectedShipmentMethod) {
      saveSelectedMethod();
    }
  }, [selectedShipmentMethod]);

  useEffect(() => {
    if (selectedPaymentMethod) {
      saveSelectedPaymentMethod();
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (isGuest && customerFromLocalStorage) {
      setCustomer(customerFromLocalStorage);
    }
  }, []);

  /****** Checkout Functions *****/

  //handling errors
  const handleErrorResponse = (response: AxiosRequestConfig) => {
    if (response.data.errors) {
      const errors: IFormInputError[] = response.data.errors.map(
        (err: { message: string }) => ({
          message: err.message,
          id: `error-${Math.floor(Math.random() * 100) + 1}`,
        }),
      );
      setBackendErrors(errors);
    } else {
      setBackendErrors([]);
    }
  };

  const updateGuestCheckoutAddress = async (guestCustomer: any) => {
    await saveCheckoutAddresses(
      +guestCustomer.addresses[0].id,
      "",
      cartState.guestId,
      guestCustomer.addresses[0],
      cart?.id || cartState.cart?.id || 0,
    ).catch((error) => {
      console.log("ERROR_UPDATING_GUEST_CHECKOUT_ADDRESS", { error });
    });
  };

  //Save checkout Addresss and Fetching shippment methods
  const saveCheckoutAddress = async () => {
    setLoadingShippingMethods(true);
    try {
      const response = await saveCheckoutAddresses(
        +customer.addresses[0].id,
        token,
        cartState.guestId,
        isGuest ? { ...customer.addresses[0], email: customer.email } : null,
        cart?.id || cartState.cart?.id || 0,
      );
      handleErrorResponse(response);

      if (!response.data.errors) {
        if (response.data.data.saveCheckoutAddresses.cart) {
          setCart(response.data.data.saveCheckoutAddresses.cart);
          dispatchCart({
            type: "SAVE_CART",
            payload: response.data.data.saveCheckoutAddresses.cart,
          });
        }
        const shippingResponse = await getShippingMethods(
          token,
          cartState.guestId,
          cart?.id || cartState.cart?.id || 0,
        );
        setShippingMethods(
          shippingResponse.data.data.shippingMethods.shippingMethods,
        );
      }
      setLoadingShippingMethods(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (customer && customer.addresses && customer.addresses.length > 0) {
      saveCheckoutAddress();
    }
  }, [customer]);

  //Save Shippment Method and Fethcing Paymenmt Methods from the backend
  const saveSelectedMethod = async () => {
    setLoadingPaymentMethods(true);
    try {
      const response = await saveShippingMethod(
        selectedShipmentMethod,
        token,
        cartState.guestId,
        cart?.id || cartState.cart?.id || 0,
      );
      handleErrorResponse(response);

      if (!response.data.errors) {
        setPaymentMethods(response.data.data.paymentMethods.paymentMethods);
        dispatchCart({
          type: "SAVE_CART",
          payload: response.data.data.paymentMethods.cart,
        });
        setCart(response.data.data.paymentMethods.cart);
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingPaymentMethods(false);
  };

  //Save Payment Method
  const saveSelectedPaymentMethod = async () => {
    try {
      const response = await savePaymentMethod(
        selectedPaymentMethod,
        token,
        cartState.guestId,
        cart?.id || cartState.cart?.id || 0,
      );
      handleErrorResponse(response);
    } catch (error) {
      console.error(error);
    }
  };

  //Handling Coupon
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponCodeMessage, setCouponCodeMessage] = useState<string>("");
  const [couponCodeFromLink, setCouponCodeFromLink] = useState<string>("");
  const [loadingCoupon, setLoadingCoupon] = useState<boolean>(false);
  const [couponCodeStatus, setCouponCodeStatus] = useState<boolean>(false);

  useEffect(() => {
    if (cartState.coupon) {
      setCouponCodeFromLink(cartState.coupon.couponCode);
    }
  }, [cartState.coupon]);

  useEffect(() => {
    if (couponCodeFromLink) {
      applyCouponToCart(couponCodeFromLink);
    }
  }, [couponCodeFromLink]);

  const handleApplyCoupon = () => {
    if (couponCode) {
      applyCouponToCart(couponCode);
    }
  };

  //apply coupon to cart
  const applyCouponToCart = async (couponCode: string) => {
    setLoadingCoupon(true);
    try {
      const response = await applyCoupon(
        couponCode,
        token,
        cartState.guestId,
        cart?.id || cartState.cart?.id || 0,
      );
      setCouponCodeMessage(response.data.data.applyCoupon.message);
      setCouponCodeStatus(response.data.data.applyCoupon.success);
      handleErrorResponse(response);
      if (response.data.data.applyCoupon.cart) {
        setCart(response.data.data.applyCoupon.cart);
        dispatchCart({
          type: "SAVE_CART",
          payload: response.data.data.applyCoupon.cart,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingCoupon(false);
  };

  // Placing Order
  const placeOrder = async () => {
    if (PONumber && PONumber.length < 3) {
      setPONumberError("PO Number should be at least 3 characters long.");
      return;
    }
    setLoading(true);
    try {
      const response = await checkout(
        token,
        cartState.guestId,
        cart?.id || cartState.cart?.id || 0,
      );

      handleErrorResponse(response);

      if (response.data.data.placeOrder.success === "1") {
        const tasks = [
          removeCart(),

          removeCoupon(),
          dispatchCart({ type: "CLEAR_CART" }),
          dispatchCart({
            type: "STORE_COUPON",
            payload: null,
          }),
          (PONumber || POFile) &&
            savePOFile(response.data.data.placeOrder.order.id),
          //saveTaxExemption(response.data.data.placeOrder.order.id),
          (taxId || taxExemptFile) &&
            saveTaxExemptFile(response.data.data.placeOrder.order.id),
        ];

        if (isGuest) {
          tasks.push(removeCustomerFromLS());
        }

        await Promise.all(tasks);

        alert("Your Order was placed");
        const orderData = response.data.data.placeOrder.order;
        history.push({
          pathname: "/order/receipt",
          state: { order: orderData },
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handlePaymentMethodChange = (event: any) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const goGetQuote = () => {
    history.push("/get-quote");
  };

  return (
    <IonPage>
      <Header />
      <IonContent color="light">
        <div className="page-header">
          <div className="order-page-container">
            <h1>Checkout</h1>
          </div>
        </div>
        {/* Render backend errors */}
        {backendErrors && backendErrors.length > 0 && (
          <div className="error-container">
            {backendErrors.map((error, index) => (
              <div key={index} className="error-message">
                {error.message}
              </div>
            ))}
          </div>
        )}
        <div className="checkout-container">
          <div className="cart-details-column">
            {cart && cart.items && cart.items.length > 0 && (
              <section className="receipt-section receipt-section-checkout">
                <div className="order-section-header review-cart-header">
                  <div className="order-page-container">
                    <h2 className="order-section-title">Summary:</h2>
                  </div>
                </div>
                <div className="cart-summary-block cart-summary-block-checkout">
                  <div className="cart-summary-details-block">
                    <div>
                      <p>Designs: {cart.itemsCount}</p>
                      <p>Pieces: {cart.itemsQty}</p>
                    </div>
                    <div>
                      <p>Subtotal: ${cart.subTotal.toFixed(2)}</p>
                      <p>
                        Shipping: $
                        {cart.selectedShippingRate &&
                        cart.selectedShippingRate.price
                          ? cart.selectedShippingRate.price
                          : 0}
                      </p>
                      <p className={cart.discountAmount ? "success-text" : ""}>
                        Discount: $
                        {cart.discountAmount ? cart.discountAmount : 0}
                      </p>
                    </div>
                  </div>

                  <p>Tax: ${cart.taxTotal}</p>

                  {!couponCodeFromLink && (
                    <div>
                      <div className="coupon-code-container">
                        <input
                          type="text"
                          id="couponCode"
                          name="couponCode"
                          onChange={(e) => setCouponCode(e.target.value)}
                          className="input-message-field"
                          placeholder="Enter Coupon Code"
                        />
                        {loadingCoupon ? (
                          <IonSpinner name="lines" />
                        ) : (
                          <IonButton
                            fill="outline"
                            color="tertiary"
                            onClick={handleApplyCoupon}
                          >
                            <span className="coupon-button-text">Apply</span>
                          </IonButton>
                        )}
                      </div>
                      <p
                        className={
                          !couponCodeStatus
                            ? "coupon-message danger-text"
                            : "coupon-message"
                        }
                      >
                        {couponCodeMessage}
                      </p>
                    </div>
                  )}
                  <p className="cart-total cart-total-checkout">
                    Grand Total: $
                    {cart.grandTotal ? cart.grandTotal.toFixed(2) : 0.0}
                  </p>
                  <p
                    className="account-form-action form-action-checkout"
                    onClick={handleViewDetails}
                  >
                    {isDetailsVisible ? "Hide Details" : "View Details"}
                  </p>
                </div>
                <div
                  className="order-item-details-block"
                  style={{ display: isDetailsVisible ? "block" : "none" }}
                >
                  {cart.items.map((item: any, key: number) => (
                    <OrderItem orderItem={item} key={key} />
                  ))}
                </div>
              </section>
            )}
          </div>
          <div className="checkout-options-column">
            {loading && (
              <div className="form-overlay">
                <IonSpinner name="lines" color="tertiary" />
              </div>
            )}
            {isGuest && (
              <section className="receipt-section receipt-section-checkout">
                <div className="order-section-header">
                  <div className="order-page-container">
                    <h2 className="order-section-title">Customer Details:</h2>
                  </div>
                </div>
                <div className="checkout-section">
                  <div>
                    <GuestInfoForm
                      currentCustomer={customer}
                      setGuestProfileIsComplete={setGuestProfileIsComplete}
                      isGuestProfileComplete={guestProfileIsComplete}
                      updateGuestCheckoutAddress={updateGuestCheckoutAddress}
                    />
                  </div>
                </div>
              </section>
            )}
            <section className="receipt-section receipt-section-checkout">
              <div className="order-section-header">
                <div className="order-page-container">
                  <h2 className="order-section-title">Address:</h2>
                </div>
              </div>

              <div className="checkout-address-container">
                <div style={{ width: addressFormActive ? "100%" : "50%" }}>
                  {" "}
                  {customer && (
                    <AddressForm
                      currentCustomer={customer}
                      setAddressFormActive={setAddressFormActive}
                      isGuest={isGuest}
                    />
                  )}
                </div>

                <div
                  className="checkout-address-buttons"
                  style={{ display: addressFormActive ? "none" : "flex" }}
                >
                  {loadingShippingMethods && <IonSpinner name="lines" />}
                </div>
              </div>
            </section>
            {/* Contact Information Section */}
            {shippingMethods && (
              <section className="receipt-section receipt-section-checkout">
                <div className="order-section-header">
                  <div className="order-page-container">
                    <h2 className="order-section-title">
                      Choose Your Shipping Method:
                    </h2>
                  </div>
                </div>
                <IonCard className="account-form-card form-card-checkout">
                  <div className="checkout-options">
                    {shippingMethods.map((method: IShippingMethod) => (
                      <div
                        key={method.methods.code}
                        className="checkout-option"
                      >
                        <label>
                          <input
                            type="radio"
                            name="shippingMethod"
                            value={method.methods.code}
                            className="radio-item"
                            onChange={() =>
                              setSelectedShipmentMethod(method.methods.code)
                            }
                          />
                          <p>{method.methods.label}</p>
                          <p>{method.methods.formattedPrice}</p>
                          <p>
                            Est.{" "}
                            {method.methods.estimatedDeliveryDate
                              ? method.methods.estimatedDeliveryDate
                              : ""}
                          </p>
                        </label>
                      </div>
                    ))}
                    {loadingPaymentMethods && <IonSpinner name="lines" />}
                  </div>
                </IonCard>
              </section>
            )}
            {paymentMethods && (
              <section className="receipt-section receipt-section-checkout">
                <div className="order-section-header">
                  <div className="order-page-container">
                    <h2 className="order-section-title">
                      Choose Your Payment Method:
                    </h2>
                  </div>
                </div>
                {/* Payment Options Section */}
                <IonCard className="account-form-card form-card-checkout payment-options-container">
                  <div className="checkout-options">
                    <select
                      id="paymentMethod"
                      name="paymentMethod"
                      value={selectedPaymentMethod}
                      onChange={handlePaymentMethodChange}
                      className="dropdown-menu-payment"
                    >
                      {paymentMethods.map((method) => (
                        <option key={method.method} value={method.method}>
                          {method.method_title}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedPaymentMethod === "purchaseorder" && (
                    <div>
                      <div className="po-field-container">
                        <p className="po-container-text">
                          Please upload your PO document if available
                        </p>
                        <input
                          type="file"
                          hidden
                          id="POUploader"
                          name="POUploader"
                          onChange={handlePOChange}
                        />
                        <AdditionalButton
                          text="Choose from Your Computer"
                          event={triggerFileInput}
                        />
                        {POFile?.fileName && (
                          <span style={{ marginLeft: "10px" }}>
                            {POFile.fileName}
                          </span>
                        )}
                      </div>
                      <div className="po-field-container">
                        <p className="po-container-text">
                          Purchase Order Number (optional)
                        </p>
                        <input
                          type="text"
                          id="poNumber"
                          name="PONumber"
                          onChange={(e) => setPONumber(e.target.value)}
                          className="input-message-field"
                        />
                        <p>{PONumberError}</p>
                      </div>

                      <IonButton
                        onClick={() => setShowModal(true)}
                        id="button-details-po"
                        fill="clear"
                      >
                        Click for more information regarding paying with a
                        purchase order
                      </IonButton>
                      <IonModal isOpen={showModal}>
                        <IonHeader>
                          <IonToolbar>
                            <IonTitle>Purchase Order Information</IonTitle>
                          </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                          {/* Place your text content here */}
                          <p>
                            Here is the detailed information about paying with a
                            purchase order...
                          </p>
                          <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                            id="more-info-close-button"
                          >
                            Close
                          </IonButton>
                        </IonContent>
                      </IonModal>
                    </div>
                  )}
                  <div className="tax-exempt-section">
                    <div className="tax_exempt">
                      <IonLabel>Tax Exempt</IonLabel>
                      <IonCheckbox
                        checked={isTaxExempt}
                        onIonChange={handleTaxExemptChange}
                      />
                    </div>
                    {isTaxExempt ? (
                      <div className="tax-exempt-field-container">
                        <div>
                          <p className="po-container-text">
                            Please upload your Tax Exempt document if available
                          </p>
                          <input
                            type="file"
                            hidden
                            id="taxExemptploader"
                            name="taxExemptploader"
                            onChange={handleTaxExempt}
                          />
                          <AdditionalButton
                            text="Choose from Your Computer"
                            event={triggerTaxFileInput}
                          />
                          {taxExemptFile?.fileName && (
                            <span style={{ marginLeft: "10px" }}>
                              {taxExemptFile.fileName}
                            </span>
                          )}
                        </div>
                        <div>
                          <p className="po-container-text">
                            Enter your Tax Id (optional)
                          </p>
                          <div>
                            <input
                              type="text"
                              id="taxId"
                              name="taxId"
                              value={formatTaxId(taxId)}
                              onChange={handleTaxIdChange}
                              className="input-message-field"
                            />
                            {taxIdError && (
                              <div className="error-message">{taxIdError}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <IonText className="quote-block">
                    <p className="get-quote-text">
                      <strong>Want a quote?</strong> Log in and click on the
                      "Request a Quote" button at the bottom of the checkout
                      page.
                    </p>
                    <IonRouterLink href="/login">
                      <p className="get-quote-text get-quote-text-link">
                        Login / Register
                      </p>
                    </IonRouterLink>
                  </IonText>
                </IonCard>
                <div className="checkout-buttons-container">
                  {(!isGuest || guestProfileIsComplete) && (
                    <button
                      onClick={placeOrder}
                      className={`${!isGuest ? "button-checkout half-width-button" : "button-checkout"} ${
                        selectedPaymentMethod ? "button-add-to-cart-active" : ""
                      }`}
                      id="place-order-button"
                    >
                      Submit Order
                    </button>
                  )}
                  {!isGuest && (
                    <button
                      className="button-checkout half-width-button get-quote-button"
                      onClick={goGetQuote}
                    >
                      Get Quote
                    </button>
                  )}
                </div>
              </section>
            )}
          </div>
          {/* Second column on desktop */}
          {/* Items Section */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CheckoutPage;
