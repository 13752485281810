import { IonContent, IonPage } from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";

/***Import Components***/
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SectionTitle from "../../components/Titles/SectionTitle";
import { useEffect } from "react";

const NotFoundComponent: React.FC = () => {
  // NB: The specific logic below is to force a redirect to /404 if the current path is not /404.
  // This is due to a bug in the Ionic framework where the 404 page is not rendered if redirected to directly from `App.tsx`
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/404") {
      history.push("/404");
    }
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section section-about">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Route Not Found /404</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default NotFoundComponent;
