import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SectionTitle from "../../components/Titles/SectionTitle";

const UpsellVerification: React.FC = () => {
  //const history = useHistory();
  const location = useLocation();
  const [verificationStatus, setVerificationStatus] = useState(
    "Verifying Upsell Offer",
  );
  const [upselVerified, setUpsellVerified] = useState(false);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<string>("");
  const [signature, setSignature] = useState<string | null>(null);
  const [expires, setExpires] = useState<string>("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setOrderId(query.get("order_id"));
    setQuantity(query.get("quantity") || "");
    setSignature(query.get("signature"));
    setExpires(query.get("expires") || "");
  }, []);

  const verifyUpsell = () => {
    // Send the order ID and quantity to the backend to verify the signed link
    axios
      .request({
        method: "POST",
        url: "/graphql",
        headers: { "Content-type": "application/json" },
        data: {
          query: `
          mutation VerifyUpsellLink($orderId: String!, $quantity: Int!, $signature: String!, $expires: Int!) {
            verifyUpsellLink(
              order_id: $orderId,
              quantity: $quantity,
              signature: $signature,
              expires: $expires
            ) {
              success
              message
            }
          }`,
          variables: {
            orderId: orderId,
            quantity: parseInt(quantity, 10),
            signature: signature,
            expires: parseInt(expires, 10),
          },
        },
      })
      .then((res) => {
        if (res.data && res.data.data.verifyUpsellLink) {
          setUpsellVerified(true);
          const response = res.data.data.verifyUpsellLink;
          setVerificationStatus(response.message);
          /*setTimeout(() => {
            history.push("/home");
          }, 3000);*/
        }
      })
      .catch((err) => {
        console.log("Error verifying upsell link", err);
        setVerificationStatus("Upsell verification failed.");
        /*setTimeout(() => {
          history.push("/home");
        }, 3000);*/
      });
  };

  useEffect(() => {
    if (!upselVerified && orderId && signature) {
      verifyUpsell();
    }
  }, [upselVerified, orderId, quantity, signature, expires]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Upsell Offer Verification</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid className="ion-padding">
              <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
                <IonCol
                  size="12"
                  size-md="6"
                  size-lg="6"
                  offset-lg="3"
                  offset-md="3"
                  className="text-align-left"
                >
                  <h4>{verificationStatus}</h4>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default UpsellVerification;
