import { IonContent, IonPage, IonIcon, IonRouterLink } from "@ionic/react";

import { useState, useContext, useEffect } from "react";
import Header from "../../components/Header/Header";
import { CartContext } from "../../contexts/cart";
import CartItem from "./components/CartItems/CartItem";
import { ICartItem } from "../../types/Cart/ICartItem";
import { ICart } from "../../types/Cart/ICart";
import solidLock from "../../assets/icon/lock-solid.svg";
import arrowCheckout from "../../assets/icon/arrow-checkout.svg";
import "./Cart.css";
import applyCoupon from "../../services/checkout/applyCoupon";
import useLocalStorage from "../../hooks/useLocalStorage";
import useViewportHeight from "../../hooks/useViewportHeight";

const Cart: React.FC = () => {
  useViewportHeight(); // This sets the --vh variable for dynamic adjusting
  const [cartState, dispatchCart] = useContext(CartContext);
  const [cart, setCart] = useState<ICart | any>();
  const [token] = useLocalStorage("token");

  //Getting Cart from context
  useEffect(() => {
    setCart(cartState.cart);
  }, [cartState]);

  //Reloading order page in case if the customer clicks "continue shopping" link
  const handleContinueShopping = () => {
    sessionStorage.setItem("fromCart", "false");
    window.location.href = "/order";
  };

  //Handling Coupon, if it is passed in the link
  const [, setCouponCodeMessage] = useState<string>("");
  const [couponCodeFromLink, setCouponCodeFromLink] = useState<string>("");
  const [, setLoadingCoupon] = useState<boolean>(false);

  useEffect(() => {
    if (cartState.coupon) {
      setCouponCodeFromLink(cartState.coupon.couponCode);
    }
  }, []);

  useEffect(() => {
    if (couponCodeFromLink) {
      applyCouponToCart(couponCodeFromLink);
    }
  }, [couponCodeFromLink]);

  const applyCouponToCart = async (couponCode: string) => {
    setLoadingCoupon(true);
    try {
      const response = await applyCoupon(
        couponCode,
        token,
        cartState.guestId,
        cart?.id || cartState.cart?.id || 0,
      );
      setCouponCodeMessage(response.data.data.applyCoupon.message);
      //handleErrorResponse(response);
      if (response.data.data.applyCoupon.cart) {
        setCart(response.data.data.applyCoupon.cart);
        dispatchCart({
          type: "SAVE_CART",
          payload: response.data.data.applyCoupon.cart,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingCoupon(false);
  };

  return (
    <IonPage className="no-scroll-page">
      <Header />
      <IonContent color="light" scroll-y="false">
        {cart && cart.items.length > 0 ? (
          <div className="fixed-height white-background">
            <div className="page-header">
              <div className="order-page-container">
                <h1>Cart</h1>
              </div>
            </div>
            <div className="dark-blue-line">
              <div className="order-page-container">
                <span className="white-link" onClick={handleContinueShopping}>
                  Continue Shopping
                </span>
              </div>
            </div>
            <div className="cart-items-container">
              {cart.items.map((item: ICartItem, key: number) => (
                <CartItem cartItem={item} key={key} cartId={cart.id} />
              ))}
            </div>
            <div className="cart-bottom">
              <div className="order-page-container">
                <div className="cart-summary-block">
                  <div className="cart-summary-details-block">
                    <div>
                      <p>Designs: {cart.itemsCount}</p>
                      <p>Pieces: {cart.itemsQty}</p>
                    </div>
                    <div>
                      <p>Subtotal: ${cart.subTotal}</p>
                      <p className={cart.discountAmount ? "success-text" : ""}>
                        Discount: $
                        {cart.discountAmount ? cart.discountAmount : 0}{" "}
                      </p>
                    </div>
                  </div>
                  <p className="cart-total">Subtotal: ${cart.grandTotal}</p>
                </div>
                <IonRouterLink href="order/checkout">
                  <button className="button-checkout button-add-to-cart-active">
                    <span>Checkout</span>
                    <IonIcon icon={arrowCheckout} className="arrow-checkout" />

                    <IonIcon icon={solidLock} className="cart-side-icon" />
                  </button>
                </IonRouterLink>
              </div>
            </div>
          </div>
        ) : (
          <h3>There are no items in your cart</h3>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Cart;
