import axios, { AxiosResponse } from "axios";

const createCustomerAddress = (
  token: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postcode: string,
): Promise<AxiosResponse> => {
  return axios.request({
    method: "POST",
    url: "/graphql",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    data: JSON.stringify({
      query: `  mutation createAddress ($address1: String!, $address2: String, $city: String!, $state: String!, $postcode: String!) {
            createAddress(input: {
                address1: $address1
                address2: $address2
                state: $state
                city: $city
                postcode: $postcode
            }) {
            addresses{
                id
                address1
                address2
                state
                city
                postcode
            }
          }
        }
      `,
      variables: {
        address1: `${address1}`,
        address2: `${address2}`,
        city: `${city}`,
        state: `${state}`,
        postcode: `${postcode}`,
      },
    }),
  });
};

export default createCustomerAddress;
