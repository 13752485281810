import axios, { AxiosResponse } from "axios";

const getCart = (token: string): Promise<AxiosResponse> => {
  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: ` query cartDetail {
        cartDetail {
          id
          customerEmail
          customerFirstName
          customerLastName
          shippingMethod
          taxTotal
          grandTotal
          baseGrandTotal
          selectedShippingRate {
            price
          }
          discountAmount
          itemsQty
          isTaxExempt
          itemsCount
          subTotal
          items {
            id
            quantity
            sku
            type
            name
            price
            basePrice
            total
            baseTotal
            taxPercent
            taxAmount
            baseTaxAmount
            cartId
            productId
            productFlat {
              id
            }
            designPersonalizations {
              attributeId
              textValue
              jsonValue
              integerValue
              attributeOption {
                id
                adminName
                swatchValue
                attributeOptionPreview{
                  image
                  hexCodes
                }
              }
              attribute {
                adminName
                type
                code
              }
            }
            additional 
          }
            
        }
    }
      `,
    }),
  });
};

export default getCart;
