export const MenuItems = [
  { title: "Home", path: "/home" },
  { title: "Wristbands", path: "/wristbands" },
  { title: "Prices", path: "/prices" },
  { title: "Occasions", path: "/occasions" },
  { title: "Spotlight Blog", path: "/blog" },
  { title: "Resources", path: "/resources" },
];

export interface Pages {
  title: string;
  path: string;
  routerDirection?: string;
}
