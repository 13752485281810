import React from "react";
import ProductPriceItem from "./ProductPriceItem/ProductPriceItem";
import "./ProductPrice.css";
import { IProductOptions } from "../../../../../types/IProductOptions";
import { IProductVariants } from "../../../../../types/IProductVariants";
import { IPriceItem } from "../../../../../types/IPriceItem";

interface IProductPriceParams {
  productSize: IProductVariants;
}

const ProductPrice: React.FC<IProductPriceParams> = (props) => {
  // Find the superAttribute with code 'band_width'
  const bandWidthAttribute = props.productSize.superAttributesValues.find(
    (attr: IProductOptions) => attr.code === "band_width",
  );
  // Now you can access the value of the found attribute, if it exists
  const bandWidthValue = bandWidthAttribute
    ? bandWidthAttribute.value
    : undefined;

  return (
    <>
      <h4 className="product-item-price">{bandWidthValue}</h4>
      <div className="price-row">
        <div className="price-col">
          <div className="price-inside-row bold">Qty</div>
          <div className="price-inside-row bold price-inside-row-gray">
            Each
          </div>
        </div>
        {/**Getting the single array with prices */}
        {props.productSize.customerGroupPrices.map(
          (item: IPriceItem, index: number) => (
            <ProductPriceItem
              key={index}
              priceItem={item}
              //priceArray={props.priceArray}
            />
          ),
        )}
      </div>
    </>
  );
};

export default ProductPrice;
