// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-form-action {
  cursor: pointer;
  color: #6dc3e5;
  font-size: 20px;
  margin: 0 25px;
  font-weight: 400;
  text-transform: none;
}
.show-on-small {
  display: none;
}

/****Media****/

@media (max-width: 767px) {
  .show-on-small {
    display: block;
  }
  .show-on-large {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Account/Account.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,aAAa;AACf;;AAEA,cAAc;;AAEd;EACE;IACE,cAAc;EAChB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".account-form-action {\n  cursor: pointer;\n  color: #6dc3e5;\n  font-size: 20px;\n  margin: 0 25px;\n  font-weight: 400;\n  text-transform: none;\n}\n.show-on-small {\n  display: none;\n}\n\n/****Media****/\n\n@media (max-width: 767px) {\n  .show-on-small {\n    display: block;\n  }\n  .show-on-large {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
