/**Context to save customer data and customer state */
import { createContext, useReducer, ReactNode } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { ICustomer } from "../types/ICustomer";

interface State {
  isLoading: boolean;
  isLoggedIn: boolean | null;
  currentCustomer: any;
}

type Action =
  | { type: "LOADING" }
  | { type: "SET_AUTHORIZED"; payload: ICustomer }
  | { type: "SET_UNAUTHORIZED" }
  | { type: "SET_USER_FOUND"; payload: ICustomer }
  | { type: "SET_GUEST_ADDRESS"; payload: ICustomer };

const initialState: State = {
  isLoading: false,
  isLoggedIn: null,
  currentCustomer: null,
};

//reducer to handle customer's state
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SET_AUTHORIZED":
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        currentCustomer: action.payload,
      };
    case "SET_UNAUTHORIZED":
      return {
        ...state,
        isLoggedIn: false,
        currentCustomer: null,
      };
    case "SET_USER_FOUND":
      return {
        ...state,
        isLoggedIn: true,
        currentCustomer: action.payload,
      };
    case "SET_GUEST_ADDRESS":
      return {
        ...state,
        isLoggedIn: false, // should be obvious
        currentCustomer: action.payload,
      };
    default:
      return state;
  }
};

interface CurrentCustomerProviderProps {
  children: ReactNode;
}

export const CurrentCustomerContext = createContext<
  [State, React.Dispatch<Action>]
>([initialState, () => null]);

export const CurrentCustomerProvider = ({
  children,
}: CurrentCustomerProviderProps) => {
  const [token] = useLocalStorage("token");
  const [customer] = useLocalStorage("customer");

  const tokenIsDefined = token && token.trim() !== "";
  // Check if there is a token and customer in local storage in case of rerendering
  if (token && customer) {
    // Dispatch an action to set the user found from local storage
    initialState.isLoading = false;
    initialState.isLoggedIn = true;
    initialState.currentCustomer = JSON.parse(customer);
    reducer(initialState, {
      type: "SET_USER_FOUND",
      payload: initialState.currentCustomer,
    });
  } else if (!tokenIsDefined && customer) {
    // Dispatch an action to set the user found from local storage
    initialState.isLoading = false;
    initialState.isLoggedIn = false;
    initialState.currentCustomer = JSON.parse(customer);
    reducer(initialState, {
      type: "SET_GUEST_ADDRESS",
      payload: initialState.currentCustomer,
    });
  }

  const value = useReducer(reducer, initialState);

  return (
    <CurrentCustomerContext.Provider value={value}>
      {children}
    </CurrentCustomerContext.Provider>
  );
};
