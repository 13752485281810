import React, { useEffect } from "react";
import ButtonSecond from "../Button/ButtonSecond";
import "./AddBanner.css";

const AdBanner: React.FC = () => {
  /*Listening the screen size*/
  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 767 ? true : false,
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 767px)");
    mediaQuery.addEventListener("change", setMQuery);
    return () => mediaQuery.removeEventListener("change", setMQuery);
  }, []);

  return (
    <div className="ad-banner">
      {mQuery && !mQuery.matches ? (
        <h1 className="banner-text">
          ORDER 100
          <br />
          <span className="banner-text-big">
            GET 200
            <br /> FREE!!!
          </span>
        </h1>
      ) : (
        <>
          <span className="banner-text">Limited Time Only!</span>
          <div className="banner-offer">
            <p className="banner-text-main">
              200 Free Wristbands with Every Wristband Order
            </p>
            <p className="banner-text-additional">
              *On all orders of 100 wristbands or more
            </p>
          </div>
        </>
      )}
      <ButtonSecond text="Order Now!!!" />
    </div>
  );
};

export default AdBanner;
