// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trusted-img {
    object-fit: contain;
    max-width: 100%;
    height:100px;
  }
.img-container {
  display:flex;
  justify-content: center;
  align-items: center;
  padding:20px 30px; 
}

@media (max-width:500px) {
  .trusted-img {
    height:70px; 
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TrustingCompaniesList/components/TrustingCompaniesItem/TrustingCompaniesItem.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,YAAY;EACd;AACF;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".trusted-img {\r\n    object-fit: contain;\r\n    max-width: 100%;\r\n    height:100px;\r\n  }\r\n.img-container {\r\n  display:flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding:20px 30px; \r\n}\r\n\r\n@media (max-width:500px) {\r\n  .trusted-img {\r\n    height:70px; \r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
