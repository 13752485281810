/*
 *Displaying one card with the product from TopSelling sectoion on the home page
 */
import {
  IonCol,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import Button from "../../../Button/Button";
import "./TopSellingProductItem.css";
import { IProductsTemp } from "../../../../types/IProductsTemp";

interface IProductItemsParams {
  item: IProductsTemp;
}

const TopSellingProductItem: React.FC<IProductItemsParams> = ({ item }) => {
  return (
    <IonCol size="12" size-md="6" size-xl="4" class="featured-card-container">
      <IonCard className="featured-card">
        <IonCardHeader>
          <IonCardTitle className="card-title">{item.title}</IonCardTitle>
          <IonCardSubtitle className="card-subtitle">
            {item.subtitle}
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent className="featured-content">
          <img className="featured-img-item" src={item.img} alt={item.title} />
        </IonCardContent>
        <Button text="Order Now" />
      </IonCard>
    </IonCol>
  );
};

export default TopSellingProductItem;
