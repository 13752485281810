import "./TextBlock.css";

interface ITextBlockParams {
  text: JSX.Element;
}

const TextBlock: React.FC<ITextBlockParams> = ({ text }) => {
  return <div className="text-block">{text}</div>;
};

export default TextBlock;
