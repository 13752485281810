import axios, { AxiosResponse } from "axios";

const addTaxExemptFile = (
  token: string,
  order_id: number,
  tax_id: string,
  file?: File,
): Promise<AxiosResponse> => {
  const formData = new FormData();

  // The actual GraphQL query without variables for the file
  const query = `
    mutation addTaxExemptFile($file: Upload, $order_id: Int!, $tax_id: String!) {
        addTaxExemptFile(input: {
        file: $file,
        order_id: $order_id,
        tax_id: $tax_id,
      }) {
        file_name
        order_id
      }
    }
  `;

  if (file) {
    // Append the file to formData
    formData.append("0", file);
  }

  // Append the operations with placeholders for the file
  formData.append(
    "operations",
    JSON.stringify({
      query,
      variables: {
        order_id: order_id,
        tax_id: tax_id,
      },
    }),
  );

  // Append the map to link file to the mutation variable
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));

  return axios({
    method: "post",
    url: "/graphql",
    data: formData,
    withCredentials: true,
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
      "X-Requested-With": "XMLHttpRequest",
    },
  });
};

export default addTaxExemptFile;
