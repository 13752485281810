/*
 * Function to get the picture for certain product style page
 */
import { IProducts } from "../types/IProduct";
import { IProductData } from "../types/IProductData";

const getProductStylePicture = (
  product: IProducts,
  productData: IProductData[],
): any => {
  for (const includedData of productData) {
    if (
      includedData.type == "media" &&
      includedData.id ==
        product.relationships.media.data[1].attributes["product.lists.refid"]
    ) {
      return includedData.attributes["media.url"];
    }
  }
};

export default getProductStylePicture;
