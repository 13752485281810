import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useEffect, useState, FormEvent } from "react";
import { useParams } from "react-router";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import CustomField from "../../components/FormInputs/CustomField";
import Header from "../../components/Header/Header";
import SectionTitle from "../../components/Titles/SectionTitle";
import { useForgotPasswordFields } from "../../functions/authInputFields";
import "./Auth.css";
import { IFormInputError } from "../../types/IFormInputError";
import { validateForm } from "../../functions/utils";
import axios from "axios";
import { Action } from "../../components/FormInputs/Action";
import { IFormValidationRule } from "../../types/IFormValidationRule";
import { IFormField } from "../../types/IFormField";

const ForgotPassword: React.FC = () => {
  const params = useParams();
  const fields = useForgotPasswordFields();
  const initialErrors: IFormInputError[] = [];
  const [errors, setErrors] = useState(initialErrors);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const getValidationRules = (): IFormValidationRule[] => {
    const rules: IFormValidationRule[] = [];

    const emailRule: IFormValidationRule = {
      fieldId: "email",
      validate(field: IFormField): IFormInputError | null | undefined {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const id = "email";
        const email: string | undefined = field.input.state.value;

        if (!(email && email.length && email.trim() !== "")) {
          return { id, message: "The email is required." };
        }

        if (!email.match(emailRegex)) {
          return { id, message: "Please enter a valid email address." };
        }

        return null;
      },
    };

    rules.push(emailRule);

    return rules;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      login(event as unknown as FormEvent<HTMLFormElement>);
    }
  };

  const login = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitPasswordRequestLink(event);
  };

  const submitPasswordRequestLink = (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const errors = validateForm(fields, getValidationRules());
    setErrors(errors);

    if (errors.length) {
      return;
    }
    setLoading(true);
    const formData: { [key: string]: string | boolean | undefined } = {};

    fields.forEach((field) => {
      formData[field.name] = field.input.state.value;
    });

    axios
      .request({
        method: "POST",
        url: "/graphql",
        headers: {
          "Content-type": "application/json",
        },
        data: JSON.stringify({
          query: `mutation ($email: String!) {
            forgotPassword(input : {
              email: $email
            }) 
            {
              status,
              success
            }
          }`,
          variables: {
            email: `${formData.email}`,
          },
        }),
      })
      .then((res) => {
        setLoading(false);
        if (res.data && res.data.errors) {
          if (res.data.errors[0] && res.data.errors[0].debugMessage) {
            throw Error(res.data.errors[0].debugMessage);
          } else {
            throw Error(JSON.stringify(res.data.errors[0]));
          }
        } else {
          console.log("Response", { response: res.data });
          if (res.data && res.data.data && res.data.data.forgotPassword) {
            const response = res.data.data.forgotPassword;

            if (Object.hasOwn(response, "status") && response.status !== true) {
              throw Error(JSON.stringify({ email: [response.success] }));
            }

            setSuccessMessage(response.success);
          }
        }
      })
      .catch((err) => {
        const inputErrors: IFormInputError[] = [];
        if (err.message && typeof err.message === "string") {
          const tryParsingJson = (jsonString: string) => {
            try {
              const obj = JSON.parse(jsonString);

              if (obj && typeof obj === "object") {
                return obj;
              }
            } catch (e) {}

            return false;
          };
          const testObj = tryParsingJson(err.message);

          const checkForWords = (str: string, words: string[]) => {
            for (let i = 0; i < words.length; ++i) {
              if (str.toLowerCase().includes(words[i])) {
                return {
                  found: true,
                  word: words[i],
                };
              }
            }

            return { found: false, word: null };
          };

          const handleGenericErrors = () => {
            for (const [, value] of Object.entries(fields)) {
              if (typeof value === "object" && value.id) {
                inputErrors.push({
                  id: value.id,
                  message: "Error processing request.",
                });
              }
            }
          };

          if (typeof testObj === "object" && Object.keys(testObj).length > 0) {
            if ("email" in testObj) {
              inputErrors.push({
                id: "email",
                message: testObj.email[0],
              });
            } else if ("extensions" in testObj && "locations" in testObj) {
              // if it contains things like extensions and locations, it shouldn't be shown to the user
              let fieldError = false;

              if (testObj.message && typeof testObj.message === "string") {
                const keys = fields.map((item) => item.id);
                const found = checkForWords(testObj.message, keys);

                if (found.found && found.word) {
                  fieldError = true;
                  inputErrors.push({
                    id: found.word,
                    message: testObj.message,
                  });
                }
              }

              if (!fieldError) {
                for (const [, value] of Object.entries(fields)) {
                  if (typeof value === "object" && value.id) {
                    const msg =
                      (testObj.extensions && testObj.extensions.reason) ||
                      "Error processing request.";
                    inputErrors.push({
                      id: value.id,
                      message: msg.toLowerCase(),
                    });
                  }
                }
              }
            } else {
              handleGenericErrors();
            }
          } else {
            handleGenericErrors();
          }
        }

        setErrors(inputErrors);
      });
  };

  useEffect(() => {
    return () => {
      fields.forEach((field) => field.input.state.reset(""));
      setErrors([]);
    };
  }, [params]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        {loading && (
          <div className="form-overlay">
            <IonSpinner name="lines" />
          </div>
        )}
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Forgot Password</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid className="ion-padding">
              <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
                {successMessage != "" && <h4>{successMessage}</h4>}
                <IonCol
                  size="12"
                  size-md="6"
                  size-lg="6"
                  offset-lg="3"
                  offset-md="3"
                  className="text-align-left"
                >
                  <form
                    id="forgotPasswordForm"
                    onSubmit={submitPasswordRequestLink}
                  >
                    {fields.map((field) => {
                      return (
                        <CustomField
                          key={field.id}
                          field={field}
                          errors={errors}
                          {...(field.id === "email"
                            ? { onKeyDown: handleKeyPress }
                            : {})}
                        />
                      );
                    })}
                    <Button
                      type="submit"
                      className="auth-cta"
                      id="forgotPasswordBtn"
                      text="Send Reset Link"
                    ></Button>
                    <Action
                      message="Remembered Password?"
                      text="Login"
                      link="/login"
                    />
                  </form>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
