/*
 *Getting Attribute Options
 */
import axios, { AxiosResponse } from "axios";

/*Getting Access Token*/
const getAttributeOptions = (attributeCode: string): Promise<AxiosResponse> => {
  /*Getting the product by slug*/
  return axios
    .request({
      method: "POST",
      url: `/graphql`,
      headers: {
        "Content-type": "application/json",
      },
      data: JSON.stringify({
        query: `query attributes ($attributeCode: String!) {
        attributes(input: { code: $attributeCode }) {
          data {
            id
            code
            adminName
            options {
              id
              adminName
              swatchValue
              attributeId
              attributeOptionPreview {
                image
                hexCodes
              }
            }
          }
        }
      }
    `,
        variables: {
          attributeCode: `${attributeCode}`,
        },
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export default getAttributeOptions;
