import axios, { AxiosResponse } from "axios";

const updateCustomerPassword = (
  token: string,
  firstName: string,
  lastName: string,
  email: string,
  oldpassword?: string,
  password?: string,
  passwordConfirmation?: string,
): Promise<AxiosResponse> => {
  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: `  mutation updateAccount ($firstName: String!, $lastName: String!, $email: String! $oldpassword: String, $password: String, $passwordConfirmation: String){
            updateAccount(input: {
                firstName: $firstName
                lastName: $lastName
                email: $email
                oldpassword: $oldpassword
                password: $password
                passwordConfirmation: $passwordConfirmation
            }) {
            customer{
                id
                firstName
                lastName         
                email
                addresses {
                    id
                    address1
                    address2
                    city
                    state
                    postcode
                  }
               }
            }
        }
      `,
      variables: {
        firstName: `${firstName}`,
        lastName: `${lastName}`,
        email: `${email}`,
        oldpassword: `${oldpassword}`,
        password: `${password}`,
        passwordConfirmation: `${passwordConfirmation}`,
      },
    }),
  });
};

export default updateCustomerPassword;
