import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SectionTitle from "../../components/Titles/SectionTitle";
import useLocalStorage from "../../hooks/useLocalStorage";
import { CurrentCustomerContext } from "../../contexts/currentCustomer";

const SocialLogin: React.FC = () => {
  const history = useHistory();
  const { socialprovider: provider } = useParams<{ socialprovider: string }>();
  const [verificationStatus, setVerificationStatus] =
    useState("Authenticating...");
  const [, setToken] = useLocalStorage("token");
  const [, setCustomer] = useLocalStorage("customer");
  const [, dispatch] = useContext(CurrentCustomerContext);

  const verifySocialLogin = (provider: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(searchParams.entries());
    axios
      .request({
        method: "GET",
        url: `/api/social/verify?provider=${provider}`,
        headers: { "Content-type": "application/json" },
        params: queryParams,
      })
      .then((res) => {
        if (res.data && typeof res.data === "object") {
          setVerificationStatus(res.data.message);

          setTimeout(() => {
            const socialCustomer = {
              ...res.data.data.customer,
              firstName: res.data.data.customer.first_name,
              lastName: res.data.data.customer.last_name,
            };

            setToken(res.data.data.accessToken);
            setCustomer(JSON.stringify(socialCustomer));
            //sending data in customer context
            dispatch({
              type: "SET_AUTHORIZED",
              payload: socialCustomer,
            });
            history.push("/account");
          }, 200);
        }
      })
      .catch((err) => {
        console.log("Error Verifying authenticating", {
          error:
            err.response && err.response.data
              ? err.response.data.code
              : err.response,
          status: err.response && err.response.status | err.status,
          message:
            err.response && err.response.data
              ? err.response.data.message
              : err.response,
        });
        if (err.response && err.response.data) {
          const data = err.response.data;
          if (data.message && typeof data.message === "string") {
            setVerificationStatus(data.message);
            setTimeout(() => {
              history.push("/home");
            }, 3000);
          }
        }
      });
  };

  useEffect(() => {
    verifySocialLogin(provider);
  }, [provider]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">OAuth Verification</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid className="ion-padding">
              <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
                <IonCol
                  size="12"
                  size-md="6"
                  size-lg="6"
                  offset-lg="3"
                  offset-md="3"
                  className="text-align-left"
                >
                  <h4>{verificationStatus}</h4>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default SocialLogin;
