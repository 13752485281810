// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-widget {
  box-shadow: none;
  border-radius: 0;
  box-shadow: 0px 0px 4px #eaeaea;
  max-width: 400px;
  margin: 0;
}
.widget-link-title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
}
.widget-list ion-item {
  min-height: fit-content;
}
.widget-list ion-item {
  --min-height: 30px;
}
.widget-list ion-label {
  margin: 5px 0;
}
.widget-list item-inner {
  height:40px;
}

/***MEDIA***/
@media (max-width: 991px) {
  .card-widget {
    min-width:35%;
  }
}
@media (max-width: 900px) {
  .widget-link-title {
    font-size: 14px;
    font-weight: 700;
  }
}
@media (max-width: 700px) {
  .card-widget {
    min-width:50%;
  }
}
@media (max-width: 650px) {
  .card-widget {
    min-width:100%;
  }
}

@media (max-width: 580px) {
  .card-widget {
    width: 400px;
  }
}
@media (max-width: 420px) {
  .card-widget {
    width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Prices/components/LinksWidgetPrices/LinksWidgetPrices.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,+BAA+B;EAC/B,gBAAgB;EAChB,SAAS;AACX;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,oCAAoC;AACtC;AACA;EACE,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;;AAEA,YAAY;AACZ;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".card-widget {\n  box-shadow: none;\n  border-radius: 0;\n  box-shadow: 0px 0px 4px #eaeaea;\n  max-width: 400px;\n  margin: 0;\n}\n.widget-link-title {\n  font-size: 16px;\n  font-weight: 700;\n  text-transform: uppercase;\n  font-family: \"Open Sans\", sans-serif;\n}\n.widget-list ion-item {\n  min-height: fit-content;\n}\n.widget-list ion-item {\n  --min-height: 30px;\n}\n.widget-list ion-label {\n  margin: 5px 0;\n}\n.widget-list item-inner {\n  height:40px;\n}\n\n/***MEDIA***/\n@media (max-width: 991px) {\n  .card-widget {\n    min-width:35%;\n  }\n}\n@media (max-width: 900px) {\n  .widget-link-title {\n    font-size: 14px;\n    font-weight: 700;\n  }\n}\n@media (max-width: 700px) {\n  .card-widget {\n    min-width:50%;\n  }\n}\n@media (max-width: 650px) {\n  .card-widget {\n    min-width:100%;\n  }\n}\n\n@media (max-width: 580px) {\n  .card-widget {\n    width: 400px;\n  }\n}\n@media (max-width: 420px) {\n  .card-widget {\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
