// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-circle {
  border-radius: 50%;
  width: 70px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-circle span {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

@media (max-width: 500px) {
  .step-circle {
    width: 60px;
    height: auto;
  }
  .step-circle span {
    font-size: 12px;
  }
}
@media (max-width: 300px) {
  .step-circle {
    width: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/OrderPage/components/stepCircle/StepCircle.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".step-circle {\n  border-radius: 50%;\n  width: 70px;\n  height: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.step-circle span {\n  font-size: 20px;\n  font-weight: bold;\n  color: #fff;\n}\n\n@media (max-width: 500px) {\n  .step-circle {\n    width: 60px;\n    height: auto;\n  }\n  .step-circle span {\n    font-size: 12px;\n  }\n}\n@media (max-width: 300px) {\n  .step-circle {\n    width: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
