import { IonInput, IonLabel } from "@ionic/react";
import { IFormInputError } from "../../types/IFormInputError";
import "./CustomField.css";
import { IFormField } from "../../types/IFormField";
import { KeyboardEventHandler } from "react";

interface CustomFieldInput {
  field: IFormField;
  errors: IFormInputError[];
  options?: { key: string; value: string }[];
  rows?: number;
  onKeyDown?: KeyboardEventHandler | undefined;
}

const CustomField = ({ field, errors, onKeyDown }: CustomFieldInput) => {
  const error = errors && errors.filter((e) => e.id === field.id)[0];
  const errorMessage =
    error && errors.filter((e) => e.id === field.id)[0].message;

  return (
    <div className="field">
      <IonLabel className="fieldLabel">{field.label}</IonLabel>
      <IonInput
        id={field.id}
        name={field.name}
        className={`customInput ${error ? "errorBorder" : ""}`}
        {...field.input.props}
        {...field.input.state}
        onKeyDown={onKeyDown}
      />
      <IonLabel>
        {error && <p className="errorText">{errorMessage}</p>}
      </IonLabel>
    </div>
  );
};

export type { CustomFieldInput };
export default CustomField;
