import { IonIcon } from "@ionic/react";
import "./ColorCombinationBlock.css";
import React, { useEffect, useState } from "react";
import ModalWindow from "../../../ColorForm/ModalWindow/ModalWindow";
import ModalWindowText from "../../../TextColorForm/ModalWindow/ModalWindowText";
import trashCan from "../../../../../../assets/icon/trash_can.svg";

interface IColorCombinationBlock {
  colorCombination: any;
  setStyleColorSelected: any;
  styleColorSelected: any;
  colorsCollection: any;
  textColorOptions: any;
  selectedCombination: any;
  updateCombination: any;
  addColorCombination: any;
  colorCombinations: any;
  deleteColorCombination: any;
  setSelectedCombination: any;
  blockIndex: number;
}

const ColorCombinationBlock: React.FC<IColorCombinationBlock> = (props) => {
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);
  const [showModalText, setShowModalText] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(undefined);

  useEffect(() => {
    if (props.colorCombination.textColor) {
      setBackgroundColor(
        props.colorCombination.textColor.attributeOptionPreview.hexCodes,
      );
    }
  }, [props.colorCombination.textColor]);

  if (
    props.selectedCombination === null ||
    !props.colorCombinations[props.selectedCombination]
  ) {
    return null;
  }

  return (
    <div
      className={
        `${
          props.selectedCombination === props.blockIndex
            ? "quantity-block-content-active"
            : ""
        } quantity-block-content` +
        `${props.blockIndex % 2 === 1 ? " every-second-quantity-block" : ""}`
      }
    >
      <div className="order-page-container">
        <div className="quantity-first-arrow-block">
          <div className="quantity-image-block">
            <img
              className="preview-icon"
              src="../../../../../../assets/img/preview.png"
            />
            <div className="color-block-item">
              <span className="quantity-size-label">Band Color:</span>
              <div
                onClick={() => setShowModal(true)}
                className="color-preview-container"
              >
                {props.colorCombination.color ? (
                  <img
                    className="color-preview"
                    src={`storage/${props.colorCombination.color.attributeOptionPreview.image}`}
                  />
                ) : (
                  <div className="color-preview-undefined">Make Selection</div>
                )}
                {props.colorCombination.color?.adminName ? (
                  <span className="quantity-color-name">
                    {props.colorCombination.color.adminName}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div>
              <span className="quantity-size-label">Text Color:</span>
              <div
                className="color-preview-container"
                onClick={() => setShowModalText(true)}
              >
                {backgroundColor ? (
                  <div
                    className="color-preview"
                    style={{
                      backgroundColor: backgroundColor,
                    }}
                  ></div>
                ) : (
                  <div className="color-preview-undefined">Make Selection</div>
                )}
                {props.colorCombination.textColor?.adminName ? (
                  <span className="quantity-color-name">
                    {props.colorCombination.textColor.adminName}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <IonIcon
            className="trash-can-button"
            slot="end"
            icon={trashCan}
            onClick={props.deleteColorCombination}
          />
        </div>

        <div className="quantity-size-arrow">
          <div className="quantity-size-input-container">
            <span className="quantity-size-label">Youth:</span>
            <input
              className="quantity-size-input"
              value={props.colorCombination.youth}
              placeholder="0"
              onChange={(e) =>
                props.updateCombination(
                  props.selectedCombination,
                  "youth",
                  e.target.value,
                )
              }
              min="0"
            />
          </div>
          <div className="quantity-size-input-container">
            <span className="quantity-size-label">Adult:</span>
            <input
              className="quantity-size-input"
              value={props.colorCombination.adult}
              placeholder="0"
              onChange={(e) =>
                props.updateCombination(
                  props.selectedCombination,
                  "adult",
                  e.target.value,
                )
              }
              min="0"
            />
          </div>
          <div className="quantity-size-input-container">
            <span className="quantity-size-label">Large:</span>
            <input
              className="quantity-size-input"
              value={props.colorCombination.large}
              placeholder="0"
              onChange={(e) =>
                props.updateCombination(
                  props.selectedCombination,
                  "large",
                  e.target.value,
                )
              }
              min="0"
            />
          </div>
        </div>
        {showModal && (
          <ModalWindow
            setStyleColorSelected={props.setStyleColorSelected}
            styleColorSelected={props.styleColorSelected}
            colorsCollection={props.colorsCollection}
            showModal={showModal}
            setShowModal={setShowModal}
            selectedCombination={props.selectedCombination}
            updateCombination={props.updateCombination}
            colorCombinations={props.colorCombinations}
          />
        )}
        {showModalText && (
          <ModalWindowText
            textColorOptions={props.textColorOptions}
            showModal={showModalText}
            setShowModal={setShowModalText}
            selectedCombination={props.selectedCombination}
            updateCombination={props.updateCombination}
            colorCombinations={props.colorCombinations}
          />
        )}
      </div>
    </div>
  );
};
export default ColorCombinationBlock;
