import { IonItem, IonLabel } from "@ionic/react";
import "./ListItem.css";

interface IListItemParams {
  item: string;
}

const ListItem: React.FC<IListItemParams> = ({ item }) => {
  return (
    <IonItem className="text-list-item">
      <IonLabel className="ion-text-wrap text-list">{item}</IonLabel>
    </IonItem>
  );
};

export default ListItem;
