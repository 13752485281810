import { useForm, Controller } from "react-hook-form";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
} from "@ionic/react";
import { useState, useContext } from "react";
import { CurrentCustomerContext } from "../../../contexts/currentCustomer";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { ICustomer } from "../../../types/ICustomer";
import "./AccountForm.css";

const emailRegex = /^\S+@\S+\.\S+$/;

// Define the expected inputs for the main info form
interface IGuestInfoFormInputs {
  firstName: string;
  lastName: string;
  email: string;
}

// Define the expected parameters for the main info form component
interface IMainFormParams {
  currentCustomer: ICustomer;
  setGuestProfileIsComplete?: React.Dispatch<React.SetStateAction<boolean>>;
  isGuestProfileComplete?: boolean;
  updateGuestCheckoutAddress?: (customerObj: any) => Promise<void>;
}

// Define the main info form component
const GuestInfoForm: React.FC<IMainFormParams> = ({
  currentCustomer,
  setGuestProfileIsComplete,
  isGuestProfileComplete = false,
  updateGuestCheckoutAddress,
}) => {
  // Get the token and customer from local storage
  const [, setCustomer] = useLocalStorage("customer");

  // Get the current customer from the context
  const [, dispatch] = useContext(CurrentCustomerContext);

  // Define the form state and validation rules using react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IGuestInfoFormInputs>({
    defaultValues: {
      firstName:
        currentCustomer.firstName === "Guest" ? "" : currentCustomer.firstName,
      lastName:
        currentCustomer.lastName === "User" ? "" : currentCustomer.lastName,
      email: currentCustomer.email,
    },
    mode: "all",
  });

  // Define state variables for the edit mode, saved state, and backend errors
  const [isEditable, setIsEditable] = useState(!isGuestProfileComplete); // true for guests
  const [isSaved, setIsSaved] = useState(false);

  // Define the form submission function
  const onSubmit = async (data: IGuestInfoFormInputs) => {
    const updatedCustomer = {
      ...currentCustomer,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    };

    // have to update the address of the customer as well with these details
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (updatedCustomer.addresses?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedCustomer.addresses[0].firstName = data.firstName;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedCustomer.addresses[0].lastName = data.lastName;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedCustomer.addresses[0].email = data.email;
    }

    // Call the update customer info service with the new data and token
    setCustomer(JSON.stringify(updatedCustomer));
    dispatch({
      type: "SET_GUEST_ADDRESS",
      payload: updatedCustomer,
    });
    setGuestProfileIsComplete && setGuestProfileIsComplete(true);
    if (
      updateGuestCheckoutAddress &&
      updatedCustomer.addresses &&
      updatedCustomer.addresses.length > 0
    ) {
      await updateGuestCheckoutAddress(updatedCustomer);
    }

    setIsEditable(false);
  };

  return (
    <IonCard className="account-form-card">
      <IonCardTitle className="account-form-card-title">
        {/* Guest Checkout Details */}
        {!isEditable && (
          <span
            className="account-form-action"
            onClick={() => {
              setIsEditable(true);
              setIsSaved(false);
            }}
          >
            Edit
          </span>
        )}
      </IonCardTitle>
      <IonCardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isSaved && <p>Guest details updated.</p>}
          {/* {backendErrors && (
            <span className="account-form-error">{backendErrors}</span>
          )} */}
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">First Name</IonLabel>
                <IonInput
                  type="text"
                  value={field.value}
                  onIonInput={field.onChange}
                  readonly={!isEditable}
                ></IonInput>
              </IonItem>
            )}
          />
          {errors.firstName && (
            <span className="account-form-error">This field is required</span>
          )}

          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">Last Name</IonLabel>
                <IonInput
                  type="text"
                  value={field.value}
                  onIonInput={field.onChange}
                  readonly={!isEditable}
                ></IonInput>
              </IonItem>
            )}
          />
          {errors.lastName && (
            <span className="account-form-error">This field is required</span>
          )}

          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: emailRegex,
                message: "Please enter a valid email",
              },
            }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  value={field.value}
                  onIonInput={field.onChange}
                  readonly={!isEditable}
                ></IonInput>
              </IonItem>
            )}
          />
          {errors.email && (
            <span className="account-form-error">{errors.email.message}</span>
          )}
          <div className="account-form-button-container">
            {/* Render the edit button if the form is not editable, otherwise render the submit button */}
            <IonButton type="submit" disabled={isValid === false}>
              <span className="button-text">
                {isEditable ? "Update Details" : "Use Details"}
              </span>
            </IonButton>
          </div>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default GuestInfoForm;
