// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.price-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow-y: scroll;
    border: 1px solid #EAEAEA;
    background: linear-gradient(#fff 50%, #EAEAEA 50%);
}
div.price-inside-row {
    padding: 7px 10px;
    text-align: center;
}
div.bold {
    font-weight: 700;
    padding-right: 26px;
}
h4.product-item-price {
    font-size: 20px;
}

/*Media*/
@media (max-width:991px) {
    div.bold {
        padding-right: 10px;
    }
}

@media (max-width: 905px) {
    div.price-row {
        box-shadow: -12px 0 28px -9px rgba(0, 0, 0, 0.34) inset;
    }
    div.price-inside-row {
        padding: 7px;
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Prices/components/PriceComponent/ProductPrice/ProductPrice.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,8BAA8B;IAC9B,kBAAkB;IAClB,yBAAyB;IACzB,kDAAkD;AACtD;AACA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;;AAEA,QAAQ;AACR;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,uDAAuD;IAC3D;IACA;QACI,YAAY;QACZ,kBAAkB;IACtB;AACJ","sourcesContent":["div.price-row {\n    display: flex;\n    flex-wrap: nowrap;\n    justify-content: space-between;\n    overflow-y: scroll;\n    border: 1px solid #EAEAEA;\n    background: linear-gradient(#fff 50%, #EAEAEA 50%);\n}\ndiv.price-inside-row {\n    padding: 7px 10px;\n    text-align: center;\n}\ndiv.bold {\n    font-weight: 700;\n    padding-right: 26px;\n}\nh4.product-item-price {\n    font-size: 20px;\n}\n\n/*Media*/\n@media (max-width:991px) {\n    div.bold {\n        padding-right: 10px;\n    }\n}\n\n@media (max-width: 905px) {\n    div.price-row {\n        box-shadow: -12px 0 28px -9px rgba(0, 0, 0, 0.34) inset;\n    }\n    div.price-inside-row {\n        padding: 7px;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
