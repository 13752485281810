/*
 *Slider to display when the user clicks on the picture in the gallery
 */

import { IonicSlides, IonButton } from "@ionic/react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  Autoplay,
  Keyboard,
  Navigation,
  Pagination,
  Scrollbar,
  Zoom,
} from "swiper";
import { IImages } from "../../../../types/IImages";
import { IonIcon } from "@ionic/react";
import arrowNext from "../../../../assets/icon/next.svg";
import arrowPrev from "../../../../assets/icon/prev.svg";
import "swiper/css";
import "@ionic/react/css/ionic-swiper.css";
import "./GallerySlider.css";

interface IGallerySliderParams {
  list: IImages[];
  current: number;
  updateData: any;
}
interface IButtonsParams {
  children: JSX.Element | any;
}

const GallerySlider: React.FC<IGallerySliderParams> = (props) => {
  //Slider Next Button
  const SwiperButtonNext: React.FC<IButtonsParams> = ({ children }) => {
    const swiper = useSwiper();
    return (
      <IonButton
        className="btn-slider"
        fill="clear"
        onClick={() => swiper && swiper.slideNext()}
      >
        {children}
      </IonButton>
    );
  };
  //Slider Prev Button
  const SwiperButtonPrev: React.FC<IButtonsParams> = ({ children }) => {
    const swiper = useSwiper();
    return (
      <IonButton
        className="btn-slider"
        fill="clear"
        onClick={() => swiper && swiper.slidePrev()}
      >
        {children}
      </IonButton>
    );
  };

  return (
    <>
      <div className="swiper-container swiper-gallery">
        <div id="prev" className="btn-gallery">
          {" "}
          <SwiperButtonPrev>
            <IonIcon icon={arrowPrev} />
          </SwiperButtonPrev>
        </div>
        <Swiper
          className="slider slider-gallery"
          modules={[
            Autoplay,
            Keyboard,
            Pagination,
            Scrollbar,
            Zoom,
            IonicSlides,
            Navigation,
          ]}
          autoplay={false}
          keyboard={true}
          scrollbar={true}
          zoom={true}
          slidesPerView={1}
          loop={true}
          navigation={{ nextEl: ".next", prevEl: "#prev" }}
          initialSlide={props.current - 1}
          pagination={{
            el: ".modal-slide-number",
            type: "fraction",
          }}
          onActiveIndexChange={(swiper?) => {
            props.updateData(swiper.activeIndex);
          }}
        >
          {props.list.map((item: IImages, index) => (
            <SwiperSlide key={index} className="next">
              <img src={item.src} alt={item.alt}></img>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="next btn-gallery">
          <SwiperButtonNext>
            <IonIcon icon={arrowNext} />
          </SwiperButtonNext>
        </div>
      </div>
    </>
  );
};

export default GallerySlider;
