import React, { useEffect, useState, useContext } from "react";
import {
  IonPage,
  IonContent,
  IonSelectOption,
  IonLabel,
  IonSelect,
  IonList,
  IonItem,
  IonButton,
  IonInput,
} from "@ionic/react";
import { useParams } from "react-router";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { SelectChangeEventDetail } from "@ionic/core";
import { AxiosResponse } from "axios";
import { ID } from "../../types/ID";
import RouteProductPage from "./RouteProductPage";
import getProductPage from "../../services/getProductPage";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SectionTitleLeft from "../../components/Titles/SectionTitleLeft";
import addToCart from "../../services/cart/addToCart";
import useLocalStorage from "../../hooks/useLocalStorage";
import { IProducts } from "../../types/IProducts";
import { IProductVariants } from "../../types/IProductVariants";
import { CartContext } from "../../contexts/cart";
import "./ProductPage.css";
import { IProductOptions } from "../../types/IProductOptions";

const ProductPage: React.FC = () => {
  const { link } = useParams<ID>();
  const [product, setProduct] = useState<IProducts | null>(null);
  const [widths, setWidths] = useState<IProductOptions[] | null>(null);
  const [styles, setStyles] = useState<IProductOptions[] | null>(null);
  const [variants, setVariants] = useState<IProductVariants[] | null>([]);
  const [currentVariant, setCurrentVariant] = useState<IProductVariants>();
  const [token] = useLocalStorage("token");
  const [, setCart] = useLocalStorage("cart");
  const [quantity, setQuantity] = useState<number>(1);
  const [, dispatch] = useContext(CartContext);

  useEffect(() => {
    getProductPage(link).then((response) => {
      console.log("PRODUCT", response.data.data.productBySlug);
      // redirect to 404 if product is not found
      if (!response.data.data.productBySlug) {
        window.location.href = "/404";
        return;
      }
      setProduct(response.data.data.productBySlug);
      setWidths(
        response.data.data.productBySlug.product.superAttributes[1].options,
      );
      setStyles(
        response.data.data.productBySlug.product.superAttributes[0].options,
      );
      setVariants(response.data.data.productBySlug.product.variants);
    });
  }, [link]);

  // Set the current product variant to the first variant in the variants list
  useEffect(() => {
    if (product) {
      setCurrentVariant(product.product.variants[0]);
    }
  }, [product]);

  // Function to handle style choice
  const handleChangeWidth = (e: CustomEvent<SelectChangeEventDetail>) => {
    if (!currentVariant) {
      return;
    }
    setCurrentVariant(
      findVariant(currentVariant.additionalData[2].value, e.detail.value),
    );
  };

  // Function to handle width choice
  const handleChangeStyle = (e: CustomEvent<SelectChangeEventDetail>) => {
    if (!currentVariant) {
      return;
    }
    setCurrentVariant(
      findVariant(e.detail.value, currentVariant.additionalData[3].value),
    );
  };
  //Function to display variant with current choices of size and color
  const findVariant = (
    style: string,
    width: string,
  ): IProductVariants | undefined => {
    if (!variants) {
      return;
    }
    return variants
      .filter((variant: IProductVariants) => {
        return variant.additionalData[2].value == style;
      })
      .find((variant: IProductVariants) => {
        return variant.additionalData[3].value == width;
      });
  };
  //Function to handle adding item to cart
  const addItem = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Validate quantity input
    if (quantity === 0 || quantity === null || quantity === undefined) {
      alert("Please, enter the quantity of wristbands");
      return;
    }
    if (!product || !currentVariant) {
      return;
    }

    if (token) {
      addToCart(product.id, quantity, +currentVariant.id, token)
        .then((response) => {
          handleAddItemResponse(response);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      addToCart(product.id, quantity, +currentVariant.id)
        .then((response) => {
          handleAddItemResponse(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  //Function to handle Response
  const handleAddItemResponse = (response: AxiosResponse) => {
    if (response.data.errors) {
      alert(response.data.errors[0].debugMessage);
      return;
    }
    setCart(JSON.stringify(response.data.data.addItemToCart.cart));
    dispatch({
      type: "ADD_ITEM",
      payload: response.data.data.addItemToCart.cart,
    });
    alert(response.data.data.addItemToCart.message);
    alert(JSON.stringify(response.data.data.addItemToCart.cart));
  };

  return (
    <IonPage>
      <Header />

      <IonContent color="light">
        {product && currentVariant ? (
          <div className="fixed-container fixed-container-white">
            <form onSubmit={addItem}>
              <SectionTitleLeft
                title={
                  <h2>
                    {" "}
                    <span className="title-bold title-dark">
                      {product.name}
                    </span>
                  </h2>
                }
                class="title-dark"
              />

              <IonGrid>
                <IonRow>
                  <IonCol>
                    <div>{product.description}</div>
                  </IonCol>
                  <IonCol>
                    <div>
                      <img
                        className="product-page-img"
                        src={product?.product.images[0]?.url || "default_image"}
                        alt={product?.name}
                      />
                    </div>
                  </IonCol>
                  <IonCol>
                    <div>
                      <p>Price :</p>
                      <p className="product-price">
                        $ {currentVariant.productFlats[0].price}
                      </p>
                    </div>
                  </IonCol>
                  <IonCol>
                    <IonList>
                      <IonItem>
                        {styles && (
                          <IonSelect
                            interface="alert"
                            value={currentVariant.additionalData[2].value}
                            onIonChange={(e) => handleChangeStyle(e)}
                            placeholder={currentVariant.additionalData[2].value}
                          >
                            {styles.map(
                              (option: IProductOptions, key: number) => (
                                <IonSelectOption
                                  key={key}
                                  value={option.adminName}
                                >
                                  {option.adminName}
                                </IonSelectOption>
                              ),
                            )}
                          </IonSelect>
                        )}
                      </IonItem>
                    </IonList>
                  </IonCol>
                  <IonCol>
                    <IonList>
                      <IonItem>
                        {widths && (
                          <IonSelect
                            interface="alert"
                            value={currentVariant.additionalData[3].value}
                            onIonChange={(e) => handleChangeWidth(e)}
                            placeholder={currentVariant.additionalData[3].value}
                          >
                            {widths.map(
                              (option: IProductOptions, key: number) => (
                                <IonSelectOption
                                  key={key}
                                  value={option.swatchValue}
                                >
                                  {option.swatchValue}
                                </IonSelectOption>
                              ),
                            )}
                          </IonSelect>
                        )}
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <div className="add-item-quantity">
                    <IonLabel>Enter the Quantity:</IonLabel>
                    <IonInput
                      type="number"
                      value={quantity}
                      onIonChange={(e) => {
                        setQuantity(parseInt(e.detail.value ?? ""));
                      }}
                    />
                  </div>
                </IonRow>
              </IonGrid>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonButton type="submit" color="secondary">
                      <span className="button-text">Add to Cart</span>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </div>
        ) : (
          <></>
        )}

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export { RouteProductPage };
export default ProductPage;
