/*
 *One item fron the Links Widget
 */

import { IonItem, IonLabel } from "@ionic/react";
import { useLocation } from "react-router";
import { ILinks } from "../../../types/ILinks";
import "./LinksWidgetItem.css";

interface ILinksWidgetItemsParams {
  item: ILinks;
}

const LinksWidgetItem: React.FC<ILinksWidgetItemsParams> = ({ item }) => {
  const location = useLocation();
  return (
    <IonItem routerLink={item.path} routerDirection="none">
      <IonLabel
        color="secondary"
        className={
          //if the item is active itr gets class "link-selected"
          location.pathname.endsWith(item.path)
            ? "ion-text-wrap item-link link-selected"
            : "ion-text-wrap item-link"
        }
      >
        {item.text}
      </IonLabel>
    </IonItem>
  );
};

export default LinksWidgetItem;
