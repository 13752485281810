/*Component is used to display notification, that secondary address wasn't found*/

import { IonIcon } from "@ionic/react";
import "../AccountForm.css";
import { alertCircleOutline } from "ionicons/icons";

interface ISecondaryAddressProblemParams {
  message: string;
}

const SecondaryAddressProblem: React.FC<ISecondaryAddressProblemParams> = (
  props,
) => {
  return (
    <div className="inline-information" id="secondary-address-problem">
      <IonIcon
        icon={alertCircleOutline}
        color="secondary"
        size="large"
      ></IonIcon>
      <p className="address-data">{props.message}</p>
    </div>
  );
};

export default SecondaryAddressProblem;
