/*
 * Template for crearing ocassions pages
 */
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TextBlock from "../../components/TextBlocks/TextBlock";
import ClipartBar from "../../components/ClipartBar/ClipartBar";
import Button from "../../components/Button/Button";
import SectionTitleLeft from "../../components/Titles/SectionTitleLeft";
import { ID } from "../../types/ID";
import RouteOccasions from "./RouteOccasions";
import { IOccasions } from "../../types/IOccasions";
import TestimonialsWidget from "../../components/TestimonialsWidget/TestimonialsWidget";
import getOccasionPages from "../../services/getOccasionPages";
import TestimonialsMocs from "../../fixtures/TestimonialsMocs";
import "./Occasions.css";
import FundCalculator from "../../components/FundCalculator/FundCalculator";

const Occasions: React.FC = () => {
  const { link } = useParams<ID>();
  /*Getting needed page**/

  /*Getting Occasion page using link adress*/
  const [occasionItem, setOccasionItem] = useState<IOccasions | null>(null);
  useEffect(() => {
    getOccasionPages().then((response) => {
      setOccasionItem(
        response.data.data.occasions.data?.find(
          (item: IOccasions) => item.translations[0]["urlKey"] === link,
        ),
      );
    });
  }, [link]);

  return (
    <IonPage>
      <Header />

      <IonContent color="light">
        <div className="fixed-container fixed-container-white occasions-page">
          {occasionItem ? (
            <div>
              <SectionTitleLeft
                title={
                  <h2>
                    {" "}
                    <span className="title-bold title-dark">
                      {occasionItem.translations[0]["pageTitle"]}
                    </span>
                  </h2>
                }
                class="title-dark"
              />
              {/**If the page has clipart bar on the top of the page, it will be displayed here */}
              {occasionItem.hasClipartBarOnTop && <ClipartBar />}
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <TextBlock
                      text={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: occasionItem.translations[0]["htmlContent"],
                          }}
                        ></div>
                      }
                      text-wrap
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <Button text="Order Now" />
                  </IonCol>
                </IonRow>
              </IonGrid>
              {/**If the page has clipart bar on the bottom of the page, it will be displayed here */}
              {occasionItem.hasClipartBarOnBottom && <ClipartBar />}
              {/**If the page has fundCalculator bar on the page, it will be displayed here */}
              {occasionItem.hasCalculator && <FundCalculator />}
              {/**If the page has testimonials widget, it will be displayed here */}
              {occasionItem.hasTestimonials && (
                <TestimonialsWidget testimonials={TestimonialsMocs} />
              )}
            </div>
          ) : (
            <div className="centered min-height">
              <IonSpinner name="lines" color="tertiary" />
            </div>
          )}
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};
export { RouteOccasions };
export default Occasions;
