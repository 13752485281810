import React, { useState, useEffect } from "react";
import "./StepCircle.css";

interface IStepCircleParams {
  activeImg: string;
  inactiveImg: string;
  stepNumber: number;
  step: number;
  setStep: any;
}

const StepCircle: React.FC<IStepCircleParams> = (props) => {
  const [circleSrc, setCircleSrc] = useState("");

  useEffect(() => {
    if (+props.stepNumber == props.step) {
      setCircleSrc(props.activeImg);
    } else {
      setCircleSrc(props.inactiveImg);
    }
  }, [props.stepNumber, props.step, props.activeImg, props.inactiveImg]);

  return (
    <div
      className="step-circle"
      onClick={() => {
        props.setStep(props.stepNumber);
      }}
    >
      <img src={circleSrc} alt="step" />
    </div>
  );
};
export default StepCircle;
