import { IonModal, IonCard } from "@ionic/react";
import TextColorCard from "../../../../../components/Store/TextColorCard/TextColorCard";

interface IQuantityFormParams {
  textColorOptions: any;
  showModal: any;
  setShowModal: any;
  selectedCombination: any;
  updateCombination: any;
  colorCombinations: any;
}

const ModalWindowText: React.FC<IQuantityFormParams> = (props) => {
  return (
    <IonModal
      style={{ "--backdrop-opacity": "0" }}
      isOpen={props.showModal}
      onDidDismiss={() => props.setShowModal(false)}
      className="modal-container"
    >
      {/* Modal content here */}

      <div className="order-section-header">
        <div className="order-page-container">
          <h2 className="order-section-title">Color:</h2>
        </div>
      </div>

      <div className="wide-options-container">
        <div className="order-block-content color-card-content">
          {props.textColorOptions.map((color: any, key: number) => {
            return (
              <TextColorCard
                key={key}
                color={color}
                setShowModal={props.setShowModal}
                selectedCombination={props.selectedCombination}
                updateCombination={props.updateCombination}
                colorCombinations={props.colorCombinations}
              />
            );
          })}

          <IonCard
            className="color-card ion-no-margin"
            onClick={() => props.setShowModal(false)}
          >
            <div className="text-option-colors-button">
              <img
                alt="Go Back"
                src="assets/icon/goback.svg"
                className="order-icon"
              />
              <span className="show-more-colors">Go Back</span>

              {/* Overlay text */}
            </div>
          </IonCard>
        </div>
      </div>
    </IonModal>
  );
};
export default ModalWindowText;
