const GalleryList: any = [
  {
    id: 1,
    alt: "gallery1",
    src: "../assets/img/gallery/1.jpeg",
  },
  {
    id: 2,
    alt: "gallery2",
    src: "../assets/img/gallery/2.jpeg",
  },
  {
    id: 3,
    alt: "gallery3",
    src: "../assets/img/gallery/3.jpeg",
  },
  {
    id: 4,
    alt: "gallery4",
    src: "../assets/img/gallery/4.jpeg",
  },
  {
    id: 5,
    alt: "gallery5",
    src: "../assets/img/gallery/5.jpeg",
  },
  {
    id: 6,
    alt: "gallery6",
    src: "../assets/img/gallery/6.jpeg",
  },
  {
    id: 7,
    alt: "gallery7",
    src: "../assets/img/gallery/7.jpeg",
  },
  {
    id: 8,
    alt: "gallery8",
    src: "../assets/img/gallery/8.jpeg",
  },
  {
    id: 9,
    alt: "gallery9",
    src: "../assets/img/gallery/9.jpeg",
  },
  {
    id: 10,
    alt: "gallery10",
    src: "../assets/img/gallery/10.jpeg",
  },
  {
    id: 11,
    alt: "gallery11",
    src: "../assets/img/gallery/11.jpeg",
  },
  {
    id: 12,
    alt: "gallery12",
    src: "../assets/img/gallery/12.jpeg",
  },
  {
    id: 13,
    alt: "gallery13",
    src: "../assets/img/gallery/13.jpeg",
  },
  {
    id: 14,
    alt: "gallery14",
    src: "../assets/img/gallery/14.jpeg",
  },
  {
    id: 15,
    alt: "gallery15",
    src: "../assets/img/gallery/15.jpeg",
  },
  {
    id: 16,
    alt: "gallery16",
    src: "../assets/img/gallery/16.jpeg",
  },
  {
    id: 17,
    alt: "gallery17",
    src: "../assets/img/gallery/17.jpeg",
  },
  {
    id: 18,
    alt: "gallery18",
    src: "../assets/img/gallery/18.jpeg",
  },
  {
    id: 19,
    alt: "gallery19",
    src: "../assets/img/gallery/19.jpeg",
  },
  {
    id: 20,
    alt: "gallery20",
    src: "../assets/img/gallery/20.jpeg",
  },
  {
    id: 21,
    alt: "gallery21",
    src: "../assets/img/gallery/21.jpeg",
  },
  {
    id: 22,
    alt: "gallery22",
    src: "../assets/img/gallery/22.jpeg",
  },
  {
    id: 23,
    alt: "gallery23",
    src: "../assets/img/gallery/23.jpeg",
  },
  {
    id: 24,
    alt: "gallery24",
    src: "../assets/img/gallery/24.jpeg",
  },
  {
    id: 25,
    alt: "gallery25",
    src: "../assets/img/gallery/25.jpeg",
  },
  {
    id: 26,
    alt: "gallery26",
    src: "../assets/img/gallery/26.jpeg",
  },
  {
    id: 27,
    alt: "gallery27",
    src: "../assets/img/gallery/27.jpeg",
  },
];

export default GalleryList;
