import { IonItem, IonLabel, IonIcon } from "@ionic/react";
import "./LinksWidgetPricesItem.css";
import shortArrow from "../../../../../assets/icon/arrow-blue.svg";
import { IProductOptions } from "../../../../../types/IProductOptions";

interface ILinksWidgetItemsParams {
  item: IProductOptions;
  setActiveStyle: (style: IProductOptions | undefined) => void;
  activeStyle: IProductOptions;
}

const LinksWidgetPricesItem: React.FC<ILinksWidgetItemsParams> = ({
  item,
  activeStyle,
  setActiveStyle,
}) => {
  return (
    <IonItem onClick={() => setActiveStyle(item)}>
      <IonLabel
        color="secondary"
        className={
          item.adminName == activeStyle.adminName
            ? "ion-text-wrap item-link link-selected"
            : "ion-text-wrap item-link"
        }
      >
        {item.adminName}
      </IonLabel>
      <IonIcon slot="end" icon={shortArrow} />
    </IonItem>
  );
};

export default LinksWidgetPricesItem;
