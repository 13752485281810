/*
 * The component is used to display options
 */

import { IonButton, IonIcon } from "@ionic/react";
import "./OptionButton.css";

interface IOptionButtonParams {
  selectedOptionItem: any;
  optionItem: any;
  setOptionItem: any;
}

const OptionButton: React.FC<IOptionButtonParams> = ({
  selectedOptionItem,
  setOptionItem,
  optionItem,
}) => {
  const isSelected = selectedOptionItem?.id === optionItem.id;
  return (
    <IonButton
      fill={isSelected ? "outline" : "clear"}
      className={`option-button ion-no-margin ${
        isSelected ? "option-button-selected" : ""
      }`}
      onClick={() => setOptionItem(optionItem)}
    >
      {optionItem.icon && <IonIcon slot="start" icon={optionItem.icon} />}
      {optionItem.name ? optionItem.name : optionItem.adminName}
    </IonButton>
  );
};

export default OptionButton;
