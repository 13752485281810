/*
 * Mocs to dispolay ClipartBar
 */
const ClipartList: any = [
  {
    id: 1,
    alt: "clipart1",
    src: "../assets/img/cliparts/1.png",
  },
  {
    id: 2,
    alt: "clipart2",
    src: "../assets/img/cliparts/2.png",
  },
  {
    id: 3,
    alt: "clipart3",
    src: "../assets/img/cliparts/3.png",
  },
  {
    id: 4,
    alt: "clipart4",
    src: "../assets/img/cliparts/4.png",
  },
  {
    id: 5,
    alt: "clipart5",
    src: "../assets/img/cliparts/5.png",
  },
  {
    id: 6,
    alt: "clipart6",
    src: "../assets/img/cliparts/6.png",
  },
];

export default ClipartList;
