// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-block p  {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 1.6em;
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px;
}
.text-block li {
  font-size:16px;
  line-height: 1.6em;
}
.text-block-gray ion-title {
  padding: 0;
}
.text-block {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.text-block-gray {
  background-color: #EAEAEA;
  padding: 15px 30px;
}
.text-attention {
  color: #003365;
  font-weight: 700;
}
.text-important {
  color: #E7832C;
  font-weight: 800;
}

/***Media***/

@media (max-width: 767px) {
  .text-block-gray {
    padding: 0;
  }
}

@media (max-width:500px) {
  .text-block p{
    font-size: 14px;
    margin-bottom: 18px;
  }
  .text-block li {
    font-size:14px;
  }
}

@media (max-width:320px) {
  .text-block p{
    font-size: 12px;
    margin-bottom: 10px;
  }
  .text-block li {
    font-size:12px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/TextBlocks/TextBlock.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA,YAAY;;AAEZ;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":[".text-block p  {\r\n  font-family: 'Open Sans', sans-serif;\r\n  font-size: 16px;\r\n  color: #000;\r\n  line-height: 1.6em;\r\n  text-align: left;\r\n  margin-top: 0;\r\n  margin-bottom: 20px;\r\n}\r\n.text-block li {\r\n  font-size:16px;\r\n  line-height: 1.6em;\r\n}\r\n.text-block-gray ion-title {\r\n  padding: 0;\r\n}\r\n.text-block {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: start;\r\n}\r\n.text-block-gray {\r\n  background-color: #EAEAEA;\r\n  padding: 15px 30px;\r\n}\r\n.text-attention {\r\n  color: #003365;\r\n  font-weight: 700;\r\n}\r\n.text-important {\r\n  color: #E7832C;\r\n  font-weight: 800;\r\n}\r\n\r\n/***Media***/\r\n\r\n@media (max-width: 767px) {\r\n  .text-block-gray {\r\n    padding: 0;\r\n  }\r\n}\r\n\r\n@media (max-width:500px) {\r\n  .text-block p{\r\n    font-size: 14px;\r\n    margin-bottom: 18px;\r\n  }\r\n  .text-block li {\r\n    font-size:14px;\r\n  }\r\n}\r\n\r\n@media (max-width:320px) {\r\n  .text-block p{\r\n    font-size: 12px;\r\n    margin-bottom: 10px;\r\n  }\r\n  .text-block li {\r\n    font-size:12px;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
