/*
 *Mocs to display data for Tyvek Page
 */
const TyvekMocs: JSX.Element = (
  <div>
    <h3>Custom Tyvek® Wristbands & Bracelets</h3>
    <p>
      Our custom Tyvek wristbands are paper wristbands for events made from
      DuPont™ Tyvek®. Each paper wristband comes perforated in sheets of 10
      and each band has a short-term use, peel-off adhesive liner for easy and
      quick application. Our Tyvek® event wristbands offer customization with a
      variety of colors and patterns and can be ordered in bulk. They are 10
      inches long by 3/4 of an inch wide (10" x 3/4").
    </p>
    <p>
      These are waterproof, cost-effective durable wristbands used at events
      primarily for crowd control and age verification. They are hard to destroy
      during the event, but after they are easy to cut off with scissors.
    </p>
    <h3>Tyvek® Wristband uses Include:</h3>
    <ul>
      <li>Pool and park admission and entry</li>
      <li>
        Bar or club age verification (over 21 wristbands & under 21 wristbands)
      </li>
      <li>Festival or concert attendance and admission</li>
      <li>Private parties</li>
      <li>Restricted access and security enforcement</li>
      <li>VIP or all access admission</li>
    </ul>
    <p>Customize your Tyvek bracelets with these clipart images:</p>
  </div>
);

export default TyvekMocs;
