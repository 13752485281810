/*
 * Displaying the every single question for FAQ page
 */
import { IFAQItem } from "../../../../../../types/FAQ/IFAQItem";
import { useState } from "react";
import { IonItem, IonLabel, IonText, IonIcon } from "@ionic/react";
import "./FAQItem.css";
import { add } from "ionicons/icons";
import { remove } from "ionicons/icons";

interface IFAQBlockParams {
  item: IFAQItem;
}

const FAQItem: React.FC<IFAQBlockParams> = ({ item }) => {
  const [details, setDetails] = useState(false);
  return (
    <>
      <IonItem
        className="item-question"
        button={true}
        lines="full"
        onClick={() => setDetails((prev) => !prev)}
      >
        <IonLabel class="ion-text-wrap">
          {item.translations[0].question}
        </IonLabel>
        {!details && <IonIcon slot="end" icon={add} color="tertiary"></IonIcon>}
        {details && (
          <IonIcon slot="end" icon={remove} color="tertiary"></IonIcon>
        )}
      </IonItem>

      {details && (
        <IonItem className="item-answer">
          <IonText>
            <div
              dangerouslySetInnerHTML={{ __html: item.translations[0].answer }}
            ></div>
          </IonText>
        </IonItem>
      )}
    </>
  );
};

export default FAQItem;
