/*
 *Getting data about Product Page
 */
import axios, { AxiosResponse } from "axios";

/*Getting Access Token*/
const getProductPage = (slug: string): Promise<AxiosResponse> => {
  /*Getting the product by slug*/
  return axios
    .request({
      method: "POST",
      url: `/graphql`,
      headers: {
        "Content-type": "application/json",
      },
      data: JSON.stringify({
        query: `    query product ($slug: String!){
        productBySlug(slug: $slug) {
            id
            description
            shortDescription
            urlKey
            name
            
            product {
              images {
                url
              }
              superAttributes {
                adminName
                options{
                  adminName
                  swatchValue
                }
              }
              variants {
                id
                additionalData {
                  id
                  admin_name
                  label
                  value
                }
                productFlats {
                  price
                }
              }
            }             
        }
      }
  `,
        variables: {
          slug: `${slug}`,
        },
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export default getProductPage;
