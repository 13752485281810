/*
 *Displaying the card with the product on the home page.
 */
import {
  IonCol,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonButton,
  IonCardSubtitle,
  IonIcon,
  IonRouterLink,
} from "@ionic/react";
import { IProducts } from "../../../../types/IProduct";
import { IProductData } from "../../../../types/IProductData";
import arrow from "../../../../assets/icon/arrow.svg";
import getProductPicture from "../../../../functions/getProductPicture";
import getProductSubtitle from "../../../../functions/getProductSubtitle";
import RouteProductStyle from "../../../../pages/ProductStylePage/RouteProductStyle";
import "./ProdictItem.css";

interface IProductItemsParams {
  item: IProducts;
  productData: IProductData[];
}

const ProductItem: React.FC<IProductItemsParams> = (props) => {
  const picture: string = getProductPicture(props.item, props.productData);
  const subtitle: string = getProductSubtitle(props.item, props.productData);
  const url: any = process.env.REACT_APP_DEMO_URL;

  return (
    <IonCol size="12" size-md="6" size-xl="4">
      {/*the card is inside the lisnk to the detailsef description of the product*/}
      <IonRouterLink
        routerLink={RouteProductStyle(props.item.attributes["product.url"])}
        routerDirection="none"
      >
        <IonCard className="card">
          <IonCardHeader>
            <IonCardTitle className="card-title">
              {props.item.attributes["product.label"]}
            </IonCardTitle>
            <IonCardSubtitle className="card-subtitle">
              <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <img
              className="img-item"
              src={`${url}/aimeos/${picture}`}
              alt={props.item.attributes["product.label"]}
            />
          </IonCardContent>
          <div className="buttons-options">
            <IonButton color="secondary" className="btn" fill="clear">
              Order Now
            </IonButton>
            <IonButton className="button-tr" fill="clear">
              <IonIcon icon={arrow} size="large" />
            </IonButton>
          </div>
        </IonCard>
      </IonRouterLink>
    </IonCol>
  );
};

export default ProductItem;
