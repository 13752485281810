/*
 *Displaying TyvekWristband Page
 */
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from "@ionic/react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import TextBlock from "../../components/TextBlocks/TextBlock";
import TyvekMocs from "../../fixtures/TyvekMocs";
import ClipartBar from "../../components/ClipartBar/ClipartBar";
import Button from "../../components/Button/Button";
import ButtonBlue from "../../components/Button/ButtonBlue";
import SectionTitleLeft from "../../components/Titles/SectionTitleLeft";
import "./TyvekWristbands.css";

const TyvekWristbands: React.FC = () => {
  return (
    <IonPage>
      <Header />
      <IonContent color="light">
        <div className="fixed-container fixed-container-white tyvek-page">
          <SectionTitleLeft
            title={
              <h2>
                <span className="title-bold">About Tyvek® Wristbands</span>
              </h2>
            }
            class="title-dark"
          />
          <IonGrid>
            <IonRow>
              <IonCol>
                <TextBlock text={TyvekMocs} text-wrap />
              </IonCol>
            </IonRow>
          </IonGrid>
          <ClipartBar />
          <IonGrid>
            <IonRow className="ion-padding-top">
              <IonCol size="12" size-md="6" className="ion-no-padding">
                <Button text="Design Custom Tyvek Wristbands" />
              </IonCol>
              <IonCol size="12" size-md="6" className="ion-no-padding">
                <ButtonBlue text="Design In-Stock Tyvek Wristbands" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default TyvekWristbands;
