import axios, { AxiosResponse } from "axios";

const removeCartItem = (
  id: number,
  token: string,
  guestId: string,
  cartId: number | string = 0,
): Promise<AxiosResponse> => {
  const cart_id =
    cartId === 0 || typeof cartId === "number" ? 0 : parseInt(cartId, 10);
  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers: {
      Authorization: token ? token : "",
      "Content-type": "application/json",
      "Guest-Identifier": guestId ? guestId : "",
      "x-cart-id": cart_id,
    },
    data: JSON.stringify({
      query: `  mutation removeCartItem ($id: ID!) {
        removeCartItem(
            id: $id
        ) {
            status
            message
            cart {
                id
                customerEmail
                customerFirstName
                customerLastName
                shippingMethod
                taxTotal
                grandTotal
                baseGrandTotal
                selectedShippingRate {
                  price
                }
                discountAmount
                itemsQty
                itemsCount
                isTaxExempt
                subTotal
                items {
                  id
                  quantity
                  sku
                  type
                  name
                  price
                  basePrice
                  total
                  baseTotal
                  taxPercent
                  taxAmount
                  baseTaxAmount
                  cartId
                  designPersonalizations {
                    attributeId
                    textValue
                    jsonValue
                    integerValue
                    attributeOption {
                      adminName
                      swatchValue
                      attributeOptionPreview{
                        image
                      }
                    }
                    attribute {
                      adminName
                      type
                      code
                    }
                  }
                  additional 
              }  
            }
        }
    }
      `,
      variables: {
        id: id,
      },
    }),
  });
};

export default removeCartItem;
