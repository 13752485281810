import "./Titles.css";

interface ISectionTitleParams {
  title: JSX.Element;
  class: string;
}

const SectionTitle: React.FC<ISectionTitleParams> = (props) => {
  return <div className={`${props.class} ion-text-center`}>{props.title}</div>;
};

export default SectionTitle;
