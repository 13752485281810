import { IonButton } from "@ionic/react";
import "./Button.css";

interface IButtonParams {
  text: string;
}

const ButtonSecond: React.FC<IButtonParams> = (props) => {
  return (
    <IonButton color="primary" className="button btn-shadow ion-text-wrap">
      <span className="button-second-text">{props.text}</span>
    </IonButton>
  );
};

export default ButtonSecond;
