import React, { useState, useEffect, useContext } from "react";
import { IonPage, IonContent, IonSpinner } from "@ionic/react";
import { useLocation, useHistory } from "react-router-dom";
import { AxiosResponse } from "axios";
//import components

import Header from "../../components/Header/Header";
import MessageForm from "./sections/MessageForm/MessageForm";
import ClipArtForm from "./sections/ClipArtForm/ClipArtForm";
import ColorForm from "./sections/ColorForm/ColorForm";
import StyleForm from "./sections/StyleForm/StyleForm";
import ThicknessForm from "./sections/ThicknessForm/ThicknessForm";
import QuantityForm from "./sections/QuantityForm/QuantityForm";
import StepCircle from "./components/stepCircle/stepCircle";
import TextColorForm from "./sections/TextColorForm/TextColorForm";
//import functions, services and custom hooks
import getWristbandAttributes from "../../services/getWristbandAttributes";
import useLocalStorage from "../../hooks/useLocalStorage";
import addToCartWithAttributes from "../../services/cart/addToCartWithAttributes";
import saveDesignFiles from "../../functions/saveDesignFiles";
import getAttributeOptions from "../../services/attributes/getAttributeOptions";
import getAttributeIdByCode from "../../services/attributes/getAttributeIdByCode";
import getAttributeCollections from "../../services/attributes/getAttributeCollections";
import useViewportHeight from "../../hooks/useViewportHeight";
import updateCartItems from "../../services/cart/updateCartItems";
import { handleUpdateItemResponse } from "../../functions/cart/handleUpdateResponse";
//import types
import { IProducts } from "../../types/IProducts";
import { IProductOptions } from "../../types/IProductOptions";
import { IProductVariants } from "../../types/IProductVariants";
import { IFile } from "../../types/IFile";
import { IAttributeOption } from "../../types/IAttributeOption";
import { IAttributes } from "../../types/IAttributes";
import { IColorCombination } from "../../types/IColorCombination";
import { ICartItem } from "../../types/Cart/ICartItem";
import { CartContext } from "../../contexts/cart";

import "./OrderPage.css";

interface ILocationState {
  dataItem: ICartItem;
  colorCombinations: IColorCombination[];
  fromCart: boolean;
}

const OrderPage: React.FC = () => {
  const [token] = useLocalStorage("token");
  const [, setCart] = useLocalStorage("cart");
  const [cartState, dispatch] = useContext(CartContext);
  const [guestId] = useLocalStorage("guestId");

  //setup product
  const [product, setProduct] = useState<IProducts | null>(null);
  const [widths, setWidths] = useState<IProductOptions[] | null>(null);
  const [styles, setStyles] = useState<IProductOptions[] | null>(null);

  //set variant
  const [variants, setVariants] = useState<IProductVariants[] | null>([]);
  const [currentVariant, setCurrentVariant] = useState<IProductVariants>();

  //attribute Ids variables
  const [bandStyleId, setBandStyleId] = useState<number>();
  const [bandWidthId, setBandWidthId] = useState<number>();
  const [bandTextSizeId, setBandTextSizeId] = useState<number>();
  const [bandColorId, setBandColorId] = useState<number>();
  const [bandFrontMessageId, setBandFrontMessageId] = useState<number>();
  const [bandBackMessageId, setBandBackMessageId] = useState<number>();
  const [bandWrapAroundMessageId, setBandWrapAroundMessageId] =
    useState<number>();
  const [messageTypes, setMessageTypes] = useState<IProductOptions[]>();
  const [messageTypeId, setMessageTypeId] = useState<number>();
  const [insideMessageId, setInsideMessageId] = useState<number>();
  const [bandTextFontId, setBandTextFontId] = useState<number>();
  const [bandTextColorId, setBandTextColorId] = useState<number>();
  const [frontStartClipartData, setFrontStartClipartData] =
    useState<IProductOptions>();
  const [frontEndClipartData, setFrontEndClipartData] =
    useState<IProductOptions>();
  const [backStartClipartData, setBackStartClipartData] =
    useState<IProductOptions>();
  const [backEndClipartData, setBackEndClipartData] =
    useState<IProductOptions>();
  const [wrapStartClipartData, setWrapStartClipartData] =
    useState<IProductOptions>();
  const [wrapEndClipartData, setWrapEndClipartData] =
    useState<IProductOptions>();
  const [frontStartClipartId, setFrontStartClipartId] = useState<number>();
  const [frontEndClipartId, setFrontEndClipartId] = useState<number>();
  const [backStartClipartId, setBackStartClipartId] = useState<number>();
  const [backEndClipartId, setBackEndClipartId] = useState<number>();
  const [wrapStartClipartId, setWrapStartClipartId] = useState<number>();
  const [wrapEndClipartId, setWrapEndClipartId] = useState<number>();

  const [bandColorCombinationId, setBandColorCombinationId] =
    useState<number>();

  //Setup wristband attributes
  const [selectedStyle, setSelectedStyle] = useState<IProductOptions>();
  const [selectedThickness, setSelectedThickness] = useState<IProductOptions>();
  const [colorsCollection, setColorsCollection] = useState<IProductOptions[]>();
  const [selectedColor] = useState<IProductOptions>();
  const [styleColorSelected, setStyleColorSelected] = useState<
    IAttributes | undefined
  >();
  const [messageStyle, setMessageStyle] = useState<
    IProductOptions | undefined
  >();
  const [messageFront, setMessageFront] = useState("");
  const [messageBack, setMessageBack] = useState("");
  const [wraparoundMessage, setWraparoundMessage] = useState("");
  const [insideMessage, setInsideMessage] = useState("");
  const [fontFamilyOptions, setFontFamilyOptions] = useState<IProductOptions[]>(
    [],
  );
  const [fontFamily, setFontFamily] = useState<IProductOptions>();
  const [textColorOptions, setTextColorOptions] = useState<IProductOptions[]>(
    [],
  );
  const [fontSizeOptions, setFontSizeOptions] = useState<IProductOptions[]>([]);
  const [fontSize, setFontSize] = useState<IProductOptions>();
  const [fontSizeValues, setFontSizeValues] = useState<number[]>([]);
  const [selectedFontSizeValue, setSelectedFontSizeValue] =
    useState<number>(24);
  const [selectedTextColor] = useState<IAttributeOption>();
  const [frontStartImage, setFrontStartImage] = useState<IFile>();
  const [frontEndImage, setFrontEndImage] = useState<IFile | undefined>();
  const [wrapStartImage, setWrapStartImage] = useState<IFile | undefined>();
  const [backStartImage, setBackStartImage] = useState<IFile | undefined>();
  const [backEndImage, setBackEndImage] = useState<IFile | undefined>();
  const [wrapEndImage, setWrapEndImage] = useState<IFile | undefined>();
  const [eachCost, setEachCost] = useState<number>();
  const [totalCost, setTotalCost] = useState<number>(0);

  //setup steps
  const [step, setStep] = useState<number>(1);
  //set up quantity
  const [quantity, setQuantity] = useState<number>(0);

  useViewportHeight(); // This sets the --vh variable for dynamic adjusting

  /***Handling color combinations**/

  type CombinationField = "color" | "textColor" | "youth" | "adult" | "large";
  //initial color combo
  const [colorCombinations, setColorCombinations] = useState<
    IColorCombination[]
  >([
    {
      color: undefined,
      textColor: undefined,
      youth: "",
      adult: "",
      large: "",
    },
  ]);

  const [selectedCombination, setSelectedCombination] = useState(0);

  //adding color combo
  const addColorCombination = () => {
    setColorCombinations([
      ...colorCombinations,
      {
        color: undefined,
        textColor: undefined,
        youth: "",
        adult: "",
        large: "",
      },
    ]);
    setSelectedCombination(colorCombinations.length);
  };

  //updating color combo
  const updateCombination = (
    index: number,
    field: CombinationField,
    value: any,
  ) => {
    const newCombinations = [...colorCombinations];
    if (field === "youth" || field === "adult" || field === "large") {
      const parsedValue = parseInt(value, 10);
      newCombinations[index][field] = isNaN(parsedValue) ? "" : parsedValue;
    } else {
      newCombinations[index][field] = value;
    }
    setColorCombinations(newCombinations);
  };

  const selectCombination = (index: number) => {
    setSelectedCombination(index);
  };

  useEffect(() => {
    // Initialize a variable to hold the total sum
    let totalSum = 0;

    // Iterate over each combination and add the values
    colorCombinations.forEach((combination) => {
      totalSum +=
        (Number(combination.youth) || 0) +
        (Number(combination.large) || 0) +
        (Number(combination.adult) || 0);
    });

    // Update the quantity state with the total sum if colorcombination is changed
    setQuantity(totalSum);
  }, [colorCombinations]); // Dependency array to run this effect when colorCombinations changes

  useEffect(() => {
    if (colorCombinations.length < 1) {
      setColorCombinations([
        {
          color: undefined,
          textColor: undefined,
          youth: "",
          adult: "",
          large: "",
        },
      ]);
    }
  }, [colorCombinations]);

  /**** STEP 1 REQUESTS *****/

  //Getting Super Attribures and Variants
  useEffect(() => {
    getWristbandAttributes("simple-product-name").then((response) => {
      // redirect to 404 if product is not found
      if (!response.data.data.productBySlug) {
        window.location.href = "/404";
        return;
      }

      // Set product
      setProduct(response.data.data.productBySlug);
      setVariants(response.data.data.productBySlug.product.variants);

      // Super attributes mapping for options
      const superAttributeMapping: {
        [key: string]: React.Dispatch<
          React.SetStateAction<IProductOptions[] | null>
        >;
      } = {
        band_style: setStyles,
        band_width: setWidths,
      };

      // Mapping for IDs
      const superAttributeIdMapping: {
        [key: string]: React.Dispatch<React.SetStateAction<number | undefined>>;
      } = {
        band_style: setBandStyleId,
        band_width: setBandWidthId,
      };

      // Iterate through super attributes
      const superAttributes =
        response.data.data.productBySlug.product.superAttributes;

      superAttributes.forEach((superAttribute: IAttributes) => {
        // If superAttribute adminName exists in mapping, use it to set state for options
        if (superAttributeMapping.hasOwnProperty(superAttribute.code)) {
          superAttributeMapping[superAttribute.code](superAttribute.options);
        }

        // Use it to set state for IDs if exists in ID mapping
        if (superAttributeIdMapping.hasOwnProperty(superAttribute.code)) {
          superAttributeIdMapping[superAttribute.code](superAttribute.id);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (currentVariant) {
      const productFlat = currentVariant.productFlats as { price: number }[];
      if (productFlat.length > 0) {
        setEachCost(productFlat[0].price);
      }
    }
  }, [currentVariant]);

  useEffect(() => {
    if (widths) {
      setSelectedThickness(widths[0]);
    }
  }, [widths]);

  useEffect(() => {
    if (styles) {
      setSelectedStyle(styles[0]);
    }
  }, [styles]);

  useEffect(() => {
    if (messageTypes) {
      setMessageStyle(messageTypes[0]);
    }
  }, [messageTypes]);

  useEffect(() => {
    if (textColorOptions) {
      updateCombination(0, "textColor", textColorOptions[0]);
    }
  }, [textColorOptions]);

  useEffect(() => {
    if (fontSizeOptions) {
      setFontSize(fontSizeOptions[6]);
      setFontSizeValues(
        fontSizeOptions
          .map((option) => parseInt(option.adminName))
          .sort((a, b) => a - b),
      );
    }
  }, [fontSizeOptions]);

  useEffect(() => {
    if (fontFamilyOptions) {
      setFontFamily(fontFamilyOptions[0]);
    }
  }, [fontFamilyOptions]);

  useEffect(() => {
    if (fontSizeOptions && selectedFontSizeValue) {
      const foundOption = fontSizeOptions.find((option: IProductOptions) => {
        return parseInt(option.swatchValue) === selectedFontSizeValue;
      });
      setFontSize(foundOption);
    }
  }, [selectedFontSizeValue, fontSizeOptions]);

  //Getting Colors options
  useEffect(() => {
    getAttributeIdByCode("band_color_swatch").then((response) => {
      setBandColorId(+response.data.data.attributes.data[0].id);
    });
  }, []);

  useEffect(() => {
    getAttributeCollections().then((response) => {
      setColorsCollection(
        response.data.data.allAttributeOptionCollections.data,
      );
    });
  }, []);

  useEffect(() => {
    if (
      colorsCollection &&
      colorsCollection.length > 0 &&
      colorsCollection[0].options &&
      colorsCollection[0].options.length > 0
    ) {
      updateCombination(0, "color", colorsCollection[0].options[0]);
    }
  }, [colorsCollection]);

  //if customer changes width or style, the product variant is changed as well.
  useEffect(() => {
    if (selectedThickness && selectedStyle) {
      setCurrentVariant(
        findVariant(selectedStyle.adminName, selectedThickness.adminName),
      );
    }
  }, [selectedThickness, selectedStyle]);

  //Function to display variant with current choices of size and color
  const findVariant = (
    style: string,
    width: string,
  ): IProductVariants | undefined => {
    if (!variants) {
      return;
    }
    return variants
      .filter((variant: IProductVariants) => {
        return (
          variant.superAttributesValues.find(
            (attr: IAttributeOption) => attr.code === "band_style",
          )?.value === style
        );
      })
      .find((variant: IProductVariants) => {
        return (
          variant.superAttributesValues.find(
            (attr: IAttributeOption) => attr.code === "band_width",
          )?.value === width
        );
      });
  };

  /**** STEP 2 REQUESTS */

  // Fetch and set the ID for and options

  useEffect(() => {
    if (variants && colorsCollection && fontFamilyOptions.length == 0) {
      getAttributeOptions("font_swatch").then((response) => {
        setFontFamilyOptions(response.data.data.attributes.data[0].options);
        setBandTextFontId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && textColorOptions.length == 0) {
      getAttributeOptions("band_text_swatch").then((response) => {
        setTextColorOptions(response.data.data.attributes.data[0].options);
        setBandTextColorId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && fontSizeOptions.length == 0) {
      getAttributeOptions("band_text_size").then((response) => {
        setFontSizeOptions(response.data.data.attributes.data[0].options);
        setBandTextSizeId(+response.data.data.attributes.data[0].id);
      });
    }

    if (variants && colorsCollection && !wrapEndClipartData) {
      getAttributeOptions("band_message_type").then((response) => {
        setMessageTypes(response.data.data.attributes.data[0].options);
        setMessageTypeId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && !frontStartClipartData) {
      getAttributeOptions("front_start").then((response) => {
        setFrontStartClipartData(response.data.data.attributes.data[0]);
        setFrontStartClipartId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && !frontEndClipartData) {
      getAttributeOptions("front_end").then((response) => {
        setFrontEndClipartData(response.data.data.attributes.data[0]);
        setFrontEndClipartId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && !backStartClipartData) {
      getAttributeOptions("back_start").then((response) => {
        setBackStartClipartData(response.data.data.attributes.data[0]);
        setBackStartClipartId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && !backEndClipartData) {
      getAttributeOptions("back_end").then((response) => {
        setBackEndClipartData(response.data.data.attributes.data[0]);
        setBackEndClipartId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && !wrapStartClipartData) {
      getAttributeOptions("wrap_start").then((response) => {
        setWrapStartClipartData(response.data.data.attributes.data[0]);
        setWrapStartClipartId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && !wrapEndClipartData) {
      getAttributeOptions("wrap_end").then((response) => {
        setWrapEndClipartData(response.data.data.attributes.data[0]);
        setWrapEndClipartId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && !insideMessageId) {
      getAttributeIdByCode("band_inside_message").then((response) => {
        setInsideMessageId(+response.data.data.attributes.data[0].id);
      });
    }
    if (variants && colorsCollection && !bandColorCombinationId) {
      getAttributeIdByCode("band_color_combinations").then((response) => {
        setBandColorCombinationId(+response.data.data.attributes.data[0].id);
      });
    }
  }, [variants, colorsCollection]);

  // Fetch and set the ID for band_wrap_around_message
  useEffect(() => {
    if (
      variants &&
      colorsCollection &&
      !bandWrapAroundMessageId &&
      messageStyle?.swatchValue === "wrap_around"
    ) {
      getAttributeIdByCode("band_wrap_around_message").then((response) => {
        setBandWrapAroundMessageId(+response.data.data.attributes.data[0].id);
      });
    }
    if (
      variants &&
      colorsCollection &&
      !bandFrontMessageId &&
      messageStyle?.swatchValue === "front_and_back"
    ) {
      getAttributeIdByCode("band_front_message").then((response) => {
        setBandFrontMessageId(+response.data.data.attributes.data[0].id);
      });
    }
    if (
      variants &&
      colorsCollection &&
      !bandBackMessageId &&
      messageStyle?.swatchValue === "front_and_back"
    ) {
      getAttributeIdByCode("band_back_message").then((response) => {
        setBandBackMessageId(+response.data.data.attributes.data[0].id);
      });
    }
  }, [variants, colorsCollection, messageStyle]);

  const [readyForMessageForm, setReadyForMessageForm] =
    useState<boolean>(false);
  const [readyForClipartForm, setReadyForClipartForm] =
    useState<boolean>(false);

  //check if Message Form is reaady to load
  useEffect(() => {
    if (
      fontFamilyOptions &&
      fontSizeOptions &&
      messageTypes &&
      messageStyle &&
      fontSizeValues
    ) {
      setReadyForMessageForm(true);
    }
  }, [
    fontFamilyOptions,
    fontSizeOptions,
    messageTypes,
    messageStyle,
    fontSizeValues,
  ]);

  //check if Clipart Form is ready to load
  useEffect(() => {
    if (
      frontStartClipartData &&
      frontEndClipartData &&
      backStartClipartData &&
      backEndClipartData &&
      wrapStartClipartData &&
      wrapEndClipartData
    ) {
      setReadyForClipartForm(true);
    }
  }, [
    messageStyle,
    frontStartClipartData,
    frontEndClipartData,
    backStartClipartData,
    backEndClipartData,
    wrapStartClipartData,
    wrapEndClipartData,
  ]);

  /**** STEP 3 REQUESTS */
  const [loading, setLoading] = useState<boolean>(false);
  const validateColorCombinations = (
    colorCombinations: IColorCombination[],
  ) => {
    return colorCombinations.some((combination: IColorCombination) => {
      const hasColor = combination.color && combination.color.adminName;
      const hasTextColor =
        combination.textColor && combination.textColor.adminName;
      const hasSize =
        +combination.youth > 0 ||
        +combination.adult > 0 ||
        +combination.large > 0;

      return hasColor && hasTextColor && hasSize;
    });
  };

  // In OrderPage component
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    setIsFormValid(validateColorCombinations(colorCombinations));
  }, [colorCombinations]);

  //set each cost, quantity
  useEffect(() => {
    if (eachCost) {
      const total = eachCost * quantity;
      // Round the total to 2 decimal places and convert it back to a number
      const roundedTotal = Number(total.toFixed(2));
      setTotalCost(roundedTotal);
    }
  }, [eachCost, quantity]);

  //prepare colorcCombinations Array
  const [colorCombinationstoSend, setColorCombinationsToSend] =
    useState<string>();
  useEffect(() => {
    setColorCombinationsToSend(JSON.stringify(colorCombinations));
  }, [colorCombinations]);

  /**Handle Prepare For Cart */
  const prepareArrayForCart = async () => {
    const attributesForCart: {
      attributeId: number | undefined;
      swatchValue?: string;
      textValue?: string;
      integerValue?: number;
    }[] = [];

    const addToCart = (
      condition: any,
      attributeId: number | undefined,
      prop: "textValue" | "integerValue" | "jsonValue",
      value: any,
    ) => {
      if (condition && attributeId !== undefined) {
        attributesForCart.push({
          attributeId,
          [prop]: prop === "integerValue" ? +value.id : value,
        });
      }
    };

    addToCart(selectedStyle, bandStyleId, "integerValue", selectedStyle);
    addToCart(
      selectedThickness,
      bandWidthId,
      "integerValue",
      selectedThickness,
    );
    addToCart(selectedColor, bandColorId, "integerValue", selectedColor);
    addToCart(messageStyle, messageTypeId, "integerValue", messageStyle);
    addToCart(messageFront, bandFrontMessageId, "textValue", messageFront);
    addToCart(messageBack, bandBackMessageId, "textValue", messageBack);
    addToCart(insideMessage, insideMessageId, "textValue", insideMessage);
    addToCart(
      colorCombinationstoSend,
      bandColorCombinationId,
      "jsonValue",
      colorCombinationstoSend,
    );
    addToCart(
      wraparoundMessage,
      bandWrapAroundMessageId,
      "textValue",
      wraparoundMessage,
    );
    addToCart(fontFamily, bandTextFontId, "integerValue", fontFamily);
    addToCart(fontSize, bandTextSizeId, "integerValue", fontSize);
    addToCart(
      selectedTextColor,
      bandTextColorId,
      "textValue",
      selectedTextColor,
    );

    const imageAttributes = [
      { image: frontStartImage, id: frontStartClipartId },
      { image: frontEndImage, id: frontEndClipartId },
      { image: backStartImage, id: backStartClipartId },
      { image: backEndImage, id: backEndClipartId },
      { image: wrapStartImage, id: wrapStartClipartId },
      { image: wrapEndImage, id: wrapEndClipartId },
    ];

    for (const { image, id } of imageAttributes) {
      if (image && image.search) {
        // If image is an object with search:true, save it as jsonValue
        const textValueJson = {
          id: image.id,
          url: image.url,
          term: image.term,
        };
        const textValue = JSON.stringify(textValueJson);
        attributesForCart.push({ attributeId: id, textValue });
      } else if (image && image.file) {
        // If image is a file, save it using saveDesignFiles
        try {
          const url = await saveDesignFiles(image.file, token);
          const textValue = JSON.stringify({ url: url });
          attributesForCart.push({ attributeId: id, textValue });
        } catch (error) {
          console.error("Error saving design file", error);
        }
      }
    }
    return attributesForCart;
  };

  //Add to Cart

  const handleAddToCart = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Validate quantity input
    if (quantity === 0 || quantity === null || quantity === undefined) {
      alert("Please, enter the quantity of wristbands");
      return;
    }
    if (!product || !currentVariant) {
      alert("Please, choose the product");
      return;
    }
    setLoading(true);
    prepareArrayForCart()
      .then((attributes) => {
        if (token) {
          addToCartWithAttributes(
            product.id,
            quantity,
            +currentVariant.id,
            attributes,
            token,
            guestId,
            cartState.cart?.id || 0,
          )
            .then((response) => {
              handleAddItemResponse(response);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          addToCartWithAttributes(
            product.id,
            quantity,
            +currentVariant.id,
            attributes,
            "",
            guestId,
            cartState.cart?.id || 0,
          )
            .then((response) => {
              handleAddItemResponse(response);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  //Function to handle Response after ading item to cart.
  const history = useHistory();
  const handleAddItemResponse = (response: AxiosResponse) => {
    setLoading(false);
    if (response.data.errors) {
      //alert(response.data.errors[0].debugMessage);
      return;
    }
    setCart(JSON.stringify(response.data.data.addItemToCart.cart));
    dispatch({
      type: "ADD_ITEM",
      payload: response.data.data.addItemToCart.cart,
    });
    //alert(response.data.data.addItemToCart.message);
    //alert(JSON.stringify(response.data.data.addItemToCart.cart));
    const items = response.data.data.addItemToCart.cart.items;
    let message = "";

    items.forEach((item: ICartItem) => {
      item.designPersonalizations.forEach(
        (personalization: IProductOptions) => {
          const { adminName } = personalization.attribute;
          const value =
            personalization.textValue ||
            personalization.attributeOption?.swatchValue ||
            personalization.integerValue;
          message += `${adminName} - ${value}\n`;
        },
      );
    });
    //alert(message);
    history.push("/cart");
  };

  /**Cart Editing */

  //loading the variable to show the item which is going to be edited

  const [dataLoaded, setDataLoaded] = useState(false);
  const location = useLocation<ILocationState>();
  const [dataItem, setDataItem] = useState<ICartItem>();
  const [dataColorCombinations, setDataColorCombinations] =
    useState<IColorCombination[]>();
  const [dataDesignPersonalizations, setDataDesignPersonalizations] =
    useState<IProductOptions[]>();

  const [goalToUpdateItem, setGoalToUpdateItem] = useState<boolean>(false);

  useEffect(() => {
    setGoalToUpdateItem(
      location.state?.fromCart && sessionStorage.getItem("fromCart") === "true",
    );
  }, [location.state?.fromCart, sessionStorage.getItem("fromCart")]);

  useEffect(() => {
    if (goalToUpdateItem) {
      setDataItem(location.state?.dataItem);
      setDataColorCombinations(location.state?.colorCombinations);
      setDataDesignPersonalizations(
        location.state?.dataItem.designPersonalizations,
      );
    }
  }, [goalToUpdateItem]);

  const [variantFromCart, setVariantFromCart] = useState<IProductVariants>();

  useEffect(() => {
    if (
      goalToUpdateItem &&
      dataItem &&
      dataColorCombinations &&
      dataColorCombinations.length > 0 &&
      dataDesignPersonalizations &&
      dataDesignPersonalizations.length > 0 &&
      styles &&
      styles.length > 0 &&
      widths &&
      widths.length > 0
    ) {
      setColorCombinations(dataColorCombinations);
      const jsonCartData = JSON.parse(dataItem.additional);

      // For styles
      const styleId = jsonCartData.attributes.band_style.option_id;
      const styleVariant = styles.find((s) => s.id == styleId);
      setSelectedStyle(styleVariant);

      // For widths
      const widthId = jsonCartData.attributes.band_width.option_id;
      const widthVariant = widths.find((w) => w.id == widthId);
      setSelectedThickness(widthVariant);

      if (styleVariant && widthVariant) {
        const variant = findVariant(
          styleVariant.adminName,
          widthVariant.adminName,
        );
        setVariantFromCart(variant);
      }

      dataDesignPersonalizations.forEach((item: IProductOptions) => {
        switch (item.attribute.code) {
          case "font_swatch":
            setFontFamily(item.attributeOption);
            break;
          case "band_text_size":
            setFontSize(item.attributeOption);
            break;
          case "band_message_type":
            setMessageStyle(item.attributeOption);
            break;
          case "band_front_message":
            setMessageFront(item.textValue);
            break;
          case "band_back_message":
            setMessageBack(item.textValue);
            break;
          case "band_wrap_around_message":
            setWraparoundMessage(item.textValue);
            break;
          case "front_start":
            setFrontStartImage({
              url: `storage/${item.textValue}`,
              file: null,
            });
            break;
          case "front_end":
            setFrontEndImage({ url: `storage/${item.textValue}`, file: null });
            break;
          case "back_start":
            setBackStartImage({ url: `storage/${item.textValue}`, file: null });
            break;
          case "back_end":
            setBackEndImage({ url: `storage/${item.textValue}`, file: null });
            break;
          case "wrap_start":
            setWrapStartImage({ url: `storage/${item.textValue}`, file: null });
            break;
          case "wrap_end":
            setWrapEndImage({ url: `storage/${item.textValue}`, file: null });
            break;
          case "band_inside_message":
            setInsideMessage(item.textValue);
            break;
        }
      });

      setStep(3);
      setDataLoaded(true);
    }
  }, [
    goalToUpdateItem,
    styles,
    widths,
    dataColorCombinations,
    dataDesignPersonalizations,
  ]);

  //Information which can be edited
  const [updatedArray, setUpdatedArray] = useState([]);
  const [quantityArray, setQuantityArray] = useState<any>();
  const [designPersonalizationsArray, setDesignPersonalizationsArray] =
    useState<any>();
  const [variantToUpdate, setVariantToUpdate] = useState<any>();
  const [isDataReadyForUpdate, setIsDataReadyForUpdate] =
    useState<boolean>(false);

  //function to update item to cart
  const updateItemToCart = (
    quantity: number,
    designPersonalizations: IProductOptions,
    variantToUpdate?: IProductVariants,
    guestId?: string,
    cartId?: number,
  ) => {
    const updateToken = token || ""; // Fallback to empty string if token is undefined
    updateCartItems(
      quantity,
      designPersonalizations,
      updateToken,
      guestId || "",
      cartId,
      variantToUpdate,
    )
      .then((response) => {
        setLoading(false);
        // Await the handleUpdateItemResponse before proceeding
        return handleUpdateItemResponse(response, setCart, dispatch);
      })
      .then(() => {
        // After handleUpdateItemResponse has successfully completed
        setIsDataReadyForUpdate(false);
        sessionStorage.removeItem("fromCart");
        history.push("/cart");
      })
      .catch((error) => {
        console.error("Error updating cart items:", error);
      });
  };

  //Function to handle item update
  const handleUpdateItem = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!dataItem) {
      return;
    }
    //compare existing attributes' values with new
    setLoading(true);
    prepareArrayForCart().then((attributes: any) => {
      const someArray = attributes.reduce((acc: any, update: any) => {
        const original = dataItem.designPersonalizations.find(
          (d: any) => d.attributeId == update.attributeId,
        );
        if (original) {
          let isDifferent = false;
          if (
            "integerValue" in update &&
            original.integerValue !== update.integerValue
          ) {
            isDifferent = true;
          } else if (
            "textValue" in update &&
            original.textValue !== update.textValue
          ) {
            isDifferent = true;
          } else if (
            "jsonValue" in update &&
            original.jsonValue !== update.jsonValue
          ) {
            isDifferent = true;
          }

          if (isDifferent) {
            const newUpdate: any = {
              attributeId: parseInt(update.attributeId),
            };

            if ("textValue" in update) {
              newUpdate.textValue = update.textValue;
            }
            if ("integerValue" in update) {
              newUpdate.integerValue = update.integerValue;
            }
            if ("jsonValue" in update) {
              newUpdate.jsonValue = update.jsonValue;
            }

            acc.push(newUpdate);
          }
        } else {
          // Add the new attribute to the array if not found in the original personalizations
          acc.push({
            attributeId: parseInt(update.attributeId, 10),
            textValue: "textValue" in update ? update.textValue : null,
            integerValue: "integerValue" in update ? update.integerValue : null,
            jsonValue: "jsonValue" in update ? update.jsonValue : null,
          });
        }
        return acc;
      }, []);

      setUpdatedArray(someArray);
      setQuantityArray([{ cartItemId: dataItem.id, quantity: quantity }]);
      if (
        dataItem &&
        currentVariant &&
        variantFromCart &&
        currentVariant.id !== variantFromCart.id
      ) {
        setVariantToUpdate({
          cartItemId: dataItem.id,
          selectedConfigurableOption: +currentVariant.id,
        });
      }
      if (updatedArray || quantityArray || variantToUpdate) {
        setIsDataReadyForUpdate(true);
      }
    });
  };

  useEffect(() => {
    if (dataItem && updatedArray && updatedArray.length > 0) {
      setDesignPersonalizationsArray([
        {
          cartItemId: dataItem.id,
          designPersonalizations: updatedArray,
        },
      ]);
    }
  }, [updatedArray]);

  //Watch if it is time for update
  useEffect(() => {
    if (
      isDataReadyForUpdate &&
      quantityArray &&
      designPersonalizationsArray &&
      quantityArray.length > 0 &&
      designPersonalizationsArray.length > 0
    ) {
      //updateItemToCart(quantityArray, designPersonalizationsArray);
      if (variantToUpdate) {
        updateItemToCart(
          quantityArray,
          designPersonalizationsArray,
          variantToUpdate,
          guestId,
          cartState.cart?.id,
        );
      } else {
        updateItemToCart(
          quantityArray,
          designPersonalizationsArray,
          undefined,
          guestId,
          cartState.cart?.id,
        );
      }
    }
  }, [
    isDataReadyForUpdate,
    setDesignPersonalizationsArray,
    quantityArray,
    variantToUpdate,
  ]);

  const [readyForStepThree, setReadyForStepThree] = useState(false);

  useEffect(() => {
    setReadyForStepThree(
      colorCombinations.length > 0 &&
        (!goalToUpdateItem || (goalToUpdateItem && dataLoaded)),
    );
  }, [colorCombinations.length, goalToUpdateItem, dataLoaded]);

  return (
    <IonPage className="no-scroll-page">
      <Header />
      <IonContent color="light" scroll-y="false">
        {loading && (
          <div className="form-overlay">
            <IonSpinner name="lines" color="tertiary" />
          </div>
        )}
        {product ? (
          <div className="fixed-height light-background">
            <div className="page-header">
              <div className="order-page-container">
                <h1>Personalize Your Wristband</h1>
              </div>
            </div>
            <div className="order-page-desktop-container">
              <section className="preview">
                {/*Preview Wristband*/}
                <div className="order-page-container">
                  <div className="order-preview-container">
                    <img
                      src="../../../assets/img/order/wristband.png"
                      alt="wristband"
                      className="order-preview-img"
                    />

                    <img
                      src="../../../assets/img/order/wristband.png"
                      alt="wristband"
                      className="order-preview-img"
                    />
                  </div>
                </div>
              </section>
              {/*Options blocks*/}
              <form
                onSubmit={goalToUpdateItem ? handleUpdateItem : handleAddToCart}
              >
                <div className="order-block">
                  {step === 1 && (
                    <div className="order-options-container">
                      {styles && (
                        <StyleForm
                          selectedStyle={selectedStyle}
                          setSelectedStyle={setSelectedStyle}
                          styles={styles}
                        />
                      )}
                      {widths && (
                        <ThicknessForm
                          selectedThickness={selectedThickness}
                          setSelecteThickness={setSelectedThickness}
                          widths={widths}
                        />
                      )}
                      {colorsCollection && (
                        <ColorForm
                          styleColorSelected={styleColorSelected}
                          setStyleColorSelected={setStyleColorSelected}
                          colorsCollection={colorsCollection}
                          colorCombinations={colorCombinations}
                          selectedCombination={selectedCombination}
                          updateCombination={updateCombination}
                          addColorCombination={addColorCombination}
                          selectCombination={selectCombination}
                        />
                      )}
                    </div>
                  )}

                  {step === 2 && (
                    <div className="order-options-container">
                      {readyForMessageForm && (
                        <MessageForm
                          messageStyle={messageStyle}
                          messageTypes={messageTypes}
                          setMessageStyle={setMessageStyle}
                          messageFront={messageFront}
                          setMessageFront={setMessageFront}
                          messageBack={messageBack}
                          setMessageBack={setMessageBack}
                          wraparoundMessage={wraparoundMessage}
                          setWraparoundMessage={setWraparoundMessage}
                          fontFamily={fontFamily}
                          setFontFamily={setFontFamily}
                          fontSize={fontSize}
                          setFontSize={setFontSize}
                          fontFamilyOptions={fontFamilyOptions}
                          fontSizeValues={fontSizeValues}
                          fontSizeOptions={fontSizeOptions}
                          selectedFontSizeValue={selectedFontSizeValue}
                          setSelectedFontSizeValue={setSelectedFontSizeValue}
                          insideMessage={insideMessage}
                          setInsideMessage={setInsideMessage}
                        />
                      )}

                      {textColorOptions && (
                        <TextColorForm
                          textColorOptions={textColorOptions}
                          colorCombinations={colorCombinations}
                          selectedCombination={selectedCombination}
                          updateCombination={updateCombination}
                        />
                      )}
                      {readyForClipartForm && (
                        <ClipArtForm
                          frontStartImage={frontStartImage}
                          setFrontStartImage={setFrontStartImage}
                          frontEndImage={frontEndImage}
                          setFrontEndImage={setFrontEndImage}
                          backStartImage={backStartImage}
                          setBackStartImage={setBackStartImage}
                          backEndImage={backEndImage}
                          setBackEndImage={setBackEndImage}
                          wrapStartImage={wrapStartImage}
                          setWrapStartImage={setWrapStartImage}
                          wrapEndImage={wrapEndImage}
                          setWrapEndImage={setWrapEndImage}
                          messageStyle={messageStyle}
                          frontStartClipartData={frontStartClipartData}
                          frontEndClipartData={frontEndClipartData}
                          backStartClipartData={backStartClipartData}
                          backEndClipartData={backEndClipartData}
                          wrapStartClipartData={wrapStartClipartData}
                          wrapEndClipartData={wrapEndClipartData}
                        />
                      )}
                    </div>
                  )}
                  {step === 3 && (
                    <>
                      {readyForStepThree && (
                        <QuantityForm
                          setStyleColorSelected={setStyleColorSelected}
                          styleColorSelected={styleColorSelected}
                          colorsCollection={colorsCollection}
                          textColorOptions={textColorOptions}
                          colorCombinations={colorCombinations}
                          selectedCombination={selectedCombination}
                          updateCombination={updateCombination}
                          addColorCombination={addColorCombination}
                          selectCombination={selectCombination}
                          addColorCombinations={addColorCombination}
                          setColorCombinations={setColorCombinations}
                          setSelectedCombination={setSelectedCombination}
                          quantity={quantity}
                          eachCost={eachCost}
                          totalCost={totalCost}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className="justified-content-between circles-group">
                  <StepCircle
                    activeImg="assets/icon/steps/Step-1-active.png"
                    inactiveImg="assets/icon/steps/Step-1-inactive.png"
                    stepNumber={1}
                    setStep={setStep}
                    step={step}
                  />
                  <StepCircle
                    activeImg="assets/icon/steps/Step-2-active.png"
                    inactiveImg="assets/icon/steps/Step-2-inactive.png"
                    stepNumber={2}
                    setStep={setStep}
                    step={step}
                  />
                  <StepCircle
                    activeImg="assets/icon/steps/Step-3-active.png"
                    inactiveImg="assets/icon/steps/Step-3-inactive.png"
                    stepNumber={3}
                    setStep={setStep}
                    step={step}
                  />
                  <button
                    type="submit"
                    className={`button-add-to-cart ${
                      isFormValid ? "button-add-to-cart-active" : ""
                    }`}
                    disabled={!isFormValid}
                  >
                    {goalToUpdateItem ? "Update Item" : "Add to Cart"}
                  </button>
                </div>
              </form>
            </div>
            {/*circle-buttons*/}
          </div>
        ) : (
          <div className="fixed-height light-background centered">
            <IonSpinner name="lines" color="tertiary"></IonSpinner>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default OrderPage;
