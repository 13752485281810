// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .menu-title {
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-size:15;
    font-weight:600;
}
.side-menu ion-label {
    color: #3A3A38;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.css"],"names":[],"mappings":"CAAC;IACG,yBAAyB;IACzB,oCAAoC;IACpC,YAAY;IACZ,eAAe;AACnB;AACA;IACI,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[" .menu-title {\r\n    text-transform: uppercase;\r\n    font-family: 'Open Sans', sans-serif;\r\n    font-size:15;\r\n    font-weight:600;\r\n}\r\n.side-menu ion-label {\r\n    color: #3A3A38;\r\n    text-transform: uppercase;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
