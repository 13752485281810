const AboutForthBlockMocs: JSX.Element = (
  <div>
    <h3>Customer Care</h3>
    <p>
      You care about your wristbands, and we care about you! Our customer
      service representatives will help you in any way to get the wristbands you
      need. Don’t hesitate to call or live chat with us.
    </p>

    <p>
      Rapid Wristbands is here to provide professional design, fast shipping,
      and complete satisfaction. Whether it be silicone or Tyvek, we are here to
      produce you wristbands in the way you ordered them, every time.
    </p>
  </div>
);

export default AboutForthBlockMocs;
