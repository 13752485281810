/*
 *Getting one testimonial
 */
import { IonCol, IonGrid, IonRow, IonCard, IonCardContent } from "@ionic/react";
import { ITestimonials } from "../../../../types/ITestimonials";
import "./TestimonialsItem.css";

interface ITestimonialsItemParams {
  item: ITestimonials;
}

const TestimonialsItem: React.FC<ITestimonialsItemParams> = (props) => {
  return (
    <IonCard className="testimonial-card">
      <IonCardContent className="testimonial-content">
        <IonGrid className="ion-no-padding">
          <IonRow class="ion-align-items-center">
            <IonCol
              size="12"
              size-md="4"
              size-xl="2"
              className="img-container-testimonials"
            >
              <img src={props.item.img} />
            </IonCol>
            <IonCol size="12" size-md="8" size-xl="10">
              <blockquote>
                {props.item.text}
                <div className="testimonial-author">{`-${props.item.name}`}</div>
              </blockquote>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default TestimonialsItem;
