/*
 *This component is rendering Button with blue background
 */

import { IonButton, IonIcon } from "@ionic/react";
import "./Button.css";
import arrow from "../../assets/icon/arrow.svg";

interface IButtonParams {
  text: string;
}

const ButtonBlue: React.FC<IButtonParams> = (props) => {
  return (
    <IonButton color="tertiary" className="button filled-btn ion-text-wrap">
      <IonIcon className="filled-btn-arrow" slot="end" icon={arrow} />
      <span className="button-text">{props.text}</span>
    </IonButton>
  );
};

export default ButtonBlue;
