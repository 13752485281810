import React from "react";
import { IonicSlides, IonButton } from "@ionic/react";
import ProductItem from "./components/ProductItem/ProductItem";
import { IProducts } from "../../types/IProduct";
import { IProductData } from "../../types/IProductData";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from "swiper";

import "swiper/css";
import "@ionic/react/css/ionic-swiper.css";
import "./ProductListSlider.css";

interface ITopSellingProductsListParams {
  list: IProducts[];
  additionalData: IProductData[];
}
interface IButtonsParams {
  children: JSX.Element | any;
}

const ProductListSlider: React.FC<ITopSellingProductsListParams> = (props) => {
  const SwiperButtonNext: React.FC<IButtonsParams> = ({ children }) => {
    const swiper = useSwiper();
    return (
      <IonButton
        className="btn-slider"
        fill="clear"
        onClick={() => swiper.slideNext()}
      >
        {children}
      </IonButton>
    );
  };
  const SwiperButtonPrev: React.FC<IButtonsParams> = ({ children }) => {
    const swiper = useSwiper();
    return (
      <IonButton
        className="btn-slider"
        fill="clear"
        onClick={() => swiper.slidePrev()}
      >
        {children}
      </IonButton>
    );
  };
  return (
    <Swiper
      className="slider"
      modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]}
      autoplay={false}
      keyboard={true}
      pagination={true}
      scrollbar={true}
      zoom={true}
      slidesPerView={1}
      loop={true}
    >
      <SwiperButtonPrev>Prev</SwiperButtonPrev>
      <IonButton className="btn-slider btn-divider" fill="clear">
        /
      </IonButton>
      <SwiperButtonNext>Next</SwiperButtonNext>
      {props.list?.map((product: IProducts, index) => (
        <SwiperSlide key={index}>
          <ProductItem
            key={index}
            item={product}
            productData={props.additionalData}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductListSlider;
