/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/*
 * The file shows the one single picture, and the modal window with slider
 */
import { IonCard, IonPage, IonCol, IonModal } from "@ionic/react";
import { useState, useRef, useEffect } from "react";
import { createAnimation, IonButton, IonContent } from "@ionic/react";
import GallerySlider from "../GallerySlider/GallerySlider";
import GalleryList from "../../../../fixtures/GalleryMocs";
import "./GalleryPicture.css";

const GalleryPicture = (props: any) => {
  /**Modal window**/
  const modal = useRef<HTMLIonModalElement>(null);

  //Function to close the modal wondow
  function dismiss() {
    modal.current?.dismiss();
  }

  //Setting modal window size, depending on window size
  const [slideWidth, setSlideWidth] = useState<string | number>("");
  const [slideHeight, setSlideHeight] = useState<string | number>("");
  useEffect(() => {
    if (innerWidth < 800) {
      setSlideWidth("100vw");
      setSlideHeight("100vw");
    } else {
      setSlideWidth("100vh");
      setSlideHeight("100vh");
    }
  }, [innerWidth]);

  //getting actual window size
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  //function to create animation
  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;
    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0.99", transform: "scale(1)" },
        { offset: 1, opacity: "0.99", transform: "scale(1)" },
      ])
      .fromTo("transform", `translate(${x}px, ${y}px)`, "translate(0,0)")
      .fromTo("width", `${width}px`, slideWidth)
      .fromTo("height", `${height}px`, slideHeight);

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(300)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };
  //function to leave animation
  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction("reverse");
  };

  /**Getting picture's size and coordinates to provide correct anomation effect */

  const el = useRef<HTMLIonCardElement>(null);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (!el.current) return;
    setTimeout(() => {
      getParam(el.current);
    }, 100);
    //changing picture's data depending on scroll
    window.addEventListener("mousewheel", () => {
      setTimeout(() => {
        getParam(el.current);
      }, 100);
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      getParam(el.current);
    }, 100);
  }, [el.current]);

  //function to calculate picture's data
  const getParam: any = (el: any) => {
    setWidth(el.getBoundingClientRect().width);
    setX(
      -(windowSize.innerWidth / 2 - el.getBoundingClientRect().x) + width / 2,
    );
    setHeight(el.getBoundingClientRect().height);
    setY(
      -(windowSize.innerHeight / 2 - el.getBoundingClientRect().y) +
        height / 2 +
        window.scrollY,
    );
  };
  //Getting pucture id depending on the slide friom the slider
  const [currentSlide, setCurrentSlide] = useState(props.current);
  const updateData = (value: any) => {
    if (value > GalleryList.length) {
      setCurrentSlide(value - GalleryList.length);
    } else if (value == 0) {
      setCurrentSlide(GalleryList.length);
    } else {
      setCurrentSlide(value);
    }
  };
  //getting data for current picture from the slide
  useEffect(() => {
    const slideToReturn = document.getElementById(currentSlide);
    if (slideToReturn) {
      setTimeout(() => {
        getParam(slideToReturn);
      }, 100);
    }
  });

  return (
    <IonCol size="6" size-sm="4" size-md="3" className="mb">
      <IonCard
        className="gallery-img ion-button"
        // class="ion-button"
        id={props.current}
        ref={el}
        onClick={() => getParam(el.current)}
      >
        <img
          className="gallery-picture"
          src={props.item.src}
          alt={props.item.alt}
        />
      </IonCard>

      <IonModal
        id="example-modal"
        ref={modal}
        trigger={props.current}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
      >
        <IonPage className="modal-gallery">
          <div className="modal-button-container">
            <IonButton
              color="light"
              onClick={() => dismiss()}
              fill="clear"
              className="gallery-button"
            >
              x
            </IonButton>
          </div>
          <IonContent className="ion-padding">
            <GallerySlider
              list={GalleryList}
              current={props.current}
              updateData={updateData}
            />
          </IonContent>
          <div className="modal-slide-number-container">
            {currentSlide}/{GalleryList.length}
          </div>
        </IonPage>
      </IonModal>
    </IonCol>
  );
};

export default GalleryPicture;
