// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-question {
    color: #E7832C;
}
.item-question button {
    border-bottom:1px solid red;
}
.item-answer a, .item-answer ion-router-link {
    color: #E7832C;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/FAQ/components/FAQBlock/components/FAQItem/FAQItem.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".item-question {\n    color: #E7832C;\n}\n.item-question button {\n    border-bottom:1px solid red;\n}\n.item-answer a, .item-answer ion-router-link {\n    color: #E7832C;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
