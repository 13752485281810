// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option-button {
  min-width: 22%;
  height: 39px;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  text-transform: none;
  background: #fff;
  box-shadow: 0px 0px 2px 2px rgba(187, 188, 191, 0.45);
}
.option-button-selected {
  --border-color: var(--ion-color-selected-option);
  --border-width: 2px;
}
.option-button-selected:active,
.option-button-selected:focus {
  --background: #fff;
  --background-focused: #fff;
  --background-activated: #fff;
}

@media (max-width: 400px) and (max-height: 700px) {
  .option-button {
    min-width: 24%;
  }
}
@media screen and (orientation: portrait) and (max-height: 889px) {
  .option-button {
    height: 35px;
  }
}
@media screen and (orientation: portrait) and (max-height: 850px) {
  .option-button {
    height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Store/OptionButton/OptionButton.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,gBAAgB;EAChB,qDAAqD;AACvD;AACA;EACE,gDAAgD;EAChD,mBAAmB;AACrB;AACA;;EAEE,kBAAkB;EAClB,0BAA0B;EAC1B,4BAA4B;AAC9B;;AAEA;EACE;IACE,cAAc;EAChB;AACF;AACA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".option-button {\n  min-width: 22%;\n  height: 39px;\n  font-size: 12px;\n  font-weight: 400;\n  color: #000;\n  text-transform: none;\n  background: #fff;\n  box-shadow: 0px 0px 2px 2px rgba(187, 188, 191, 0.45);\n}\n.option-button-selected {\n  --border-color: var(--ion-color-selected-option);\n  --border-width: 2px;\n}\n.option-button-selected:active,\n.option-button-selected:focus {\n  --background: #fff;\n  --background-focused: #fff;\n  --background-activated: #fff;\n}\n\n@media (max-width: 400px) and (max-height: 700px) {\n  .option-button {\n    min-width: 24%;\n  }\n}\n@media screen and (orientation: portrait) and (max-height: 889px) {\n  .option-button {\n    height: 35px;\n  }\n}\n@media screen and (orientation: portrait) and (max-height: 850px) {\n  .option-button {\n    height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
