import { useEffect } from "react";

const useClickOutside = (
  inputRef: React.RefObject<HTMLElement>,
  listRef: React.RefObject<HTMLElement>,
  handler: () => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        !inputRef.current ||
        !listRef.current ||
        inputRef.current.contains(event.target as Node) ||
        listRef.current.contains(event.target as Node)
      ) {
        return;
      }
      handler();
    };

    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  }, [inputRef, listRef, handler]);
};

export default useClickOutside;
