// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-block-button {
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  margin: 9px auto 20px;
  padding: 12px 32px;
  display: block;
  width: fit-content;
  color: #000;
  text-align: center;
}
.add-button-icon {
  margin-right: 7px;
  width: 14px;
  height: 14px;
}
.add-block-button-text {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 1em;
  text-transform: none;
}

@media screen and (max-height: 755px) {
  .add-block-button {
    margin: 9px auto 12px;
    padding: 10px 32px;
  }
}
@media screen and (max-height: 730px) {
  .add-block-button {
    margin: 8px auto 8px;
    padding: 9px 32px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/OrderPage/sections/QuantityForm/components/AddBlockButton/AddBlockButton.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,+CAA+C;EAC/C,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE;IACE,qBAAqB;IACrB,kBAAkB;EACpB;AACF;AACA;EACE;IACE,oBAAoB;IACpB,iBAAiB;EACnB;AACF","sourcesContent":[".add-block-button {\n  background: #fff;\n  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);\n  margin: 9px auto 20px;\n  padding: 12px 32px;\n  display: block;\n  width: fit-content;\n  color: #000;\n  text-align: center;\n}\n.add-button-icon {\n  margin-right: 7px;\n  width: 14px;\n  height: 14px;\n}\n.add-block-button-text {\n  font-family: \"Open Sans\";\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 1em;\n  text-transform: none;\n}\n\n@media screen and (max-height: 755px) {\n  .add-block-button {\n    margin: 9px auto 12px;\n    padding: 10px 32px;\n  }\n}\n@media screen and (max-height: 730px) {\n  .add-block-button {\n    margin: 8px auto 8px;\n    padding: 9px 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
