/*
 *Displaying Receipt Page
 */

import { IonContent, IonPage } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import OrderItem from "./components/OrderItem/OrderItem";
import { IOrderDetails } from "../../types/IOrderDetails";
import "./Receipt.css";
import Footer from "../../components/Footer/Footer";

interface ILocationState {
  order?: IOrderDetails;
}

const Receipt: React.FC = () => {
  const location = useLocation<ILocationState>();

  //Order Details variable
  const [orderDetails, setOrderDetails] = useState<IOrderDetails>();

  //setting order details from location
  useEffect(() => {
    if (location && location.state && location.state.order) {
      setOrderDetails(location.state.order);
    }
  }, [location]);

  return (
    <IonPage>
      <Header />
      <IonContent color="light">
        {orderDetails && (
          <div>
            <div className="page-header">
              <div className="order-page-container">
                <h1>Order Receipt</h1>
              </div>
            </div>

            <div className="receipt-page-container">
              <div className="receipt-column">
                <section className="receipt-section order-page-container">
                  <p>
                    Thank you for your order. Please retain this receipt for
                    your records.
                  </p>
                  <div className="receipt-section-block">
                    <h4 className="receipt-block-title">Order Reference ID</h4>
                    <p>{orderDetails.referenceId}</p>
                  </div>
                  <div className="receipt-section-block">
                    <h4 className="receipt-block-title">Date Purchased</h4>
                    <p>{orderDetails.createdAt.toLocaleString()}</p>
                  </div>
                  <div className="receipt-section-block">
                    <h4 className="receipt-block-title">Shipping</h4>
                    <p>{orderDetails.shippingTitle}</p>
                  </div>
                </section>
                {/* Contact Information Section */}
                <section className="receipt-section">
                  <div className="order-section-header">
                    <div className="order-page-container">
                      <h2 className="order-section-title">
                        Contact Information:
                      </h2>
                    </div>
                  </div>
                  <div className="order-page-container">
                    <div className="receipt-section-block">
                      <h4 className="receipt-block-title">Customer Email</h4>
                      <p>{orderDetails.customerEmail}</p>
                    </div>

                    <div className="receipt-section-block">
                      <h4 className="receipt-block-title">Shipping Address</h4>
                      <p>{`${orderDetails.shippingAddress.firstName} ${orderDetails.shippingAddress.lastName}`}</p>
                      <p>{orderDetails.shippingAddress.companyName}</p>
                      <p>{orderDetails.shippingAddress.address1}</p>
                      <p>{orderDetails.shippingAddress.address2}</p>
                      <p>{`${orderDetails.shippingAddress.city} ${orderDetails.shippingAddress.state} ${orderDetails.shippingAddress.postcode}`}</p>
                    </div>
                    {orderDetails.shippingAddress.phone && (
                      <div className="receipt-section-block">
                        <h4 className="receipt-block-title">Phone</h4>
                        <p>{orderDetails.shippingAddress.phone}</p>
                      </div>
                    )}

                    <div className="receipt-section-block">
                      <h4 className="receipt-block-title">Billing Address</h4>
                      <p>{`${orderDetails.billingAddress.firstName} ${orderDetails.billingAddress.lastName}`}</p>
                      <p>{orderDetails.billingAddress.companyName}</p>
                      <p>{orderDetails.billingAddress.address1}</p>
                      <p>{orderDetails.billingAddress.address2}</p>
                      <p>{`${orderDetails.billingAddress.city} ${orderDetails.billingAddress.state} ${orderDetails.billingAddress.postcode}`}</p>
                    </div>
                    {orderDetails.billingAddress.phone && (
                      <div className="receipt-section-block">
                        <h4 className="receipt-block-title">Phone</h4>
                        <p>{orderDetails.billingAddress.phone}</p>
                      </div>
                    )}
                  </div>
                </section>
              </div>
              {/* Second column on desktop */}
              {/* Items Section */}
              <div className="receipt-column">
                {orderDetails.items && orderDetails.items.length > 0 && (
                  <section className="receipt-section">
                    <div className="order-section-header">
                      <div className="order-page-container">
                        <h2 className="order-section-title">Items:</h2>
                      </div>
                    </div>

                    <div>
                      {orderDetails.items.map((item: any, key: number) => (
                        <OrderItem orderItem={item} key={key} />
                      ))}
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>
        )}

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Receipt;
