// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-img {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #eaeaea;
  margin: 0;
  transition: box-shadow 0.5s ease;
}
.gallery-img:hover {
  box-shadow: 0 0 10px 5px #eaeaea;
  transition: box-shadow 0.5s ease;
}
.gallery-button {
  display: none;
  width: 20px;
  font-size: 16px;
}
ion-modal::part(content) {
  background-color: transparent;
  box-shadow: none;
}
.modal-button-container {
  display: flex;
  justify-content: end;
  padding: 10px;
}
.modal-slide-number-container {
  display: flex;
  justify-content: end;
  padding: 3px;
  font-size: 12px;
  color: #fff;
}

/***Media***/

@media (max-width: 1000px) {
  .gallery-button {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Gallery/components/GalleryPicture/GalleryPicture.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,gCAAgC;EAChC,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,WAAW;EACX,eAAe;AACjB;AACA;EACE,6BAA6B;EAC7B,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,aAAa;AACf;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA,YAAY;;AAEZ;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".gallery-img {\n  border-radius: 0;\n  box-shadow: none;\n  border: 1px solid #eaeaea;\n  margin: 0;\n  transition: box-shadow 0.5s ease;\n}\n.gallery-img:hover {\n  box-shadow: 0 0 10px 5px #eaeaea;\n  transition: box-shadow 0.5s ease;\n}\n.gallery-button {\n  display: none;\n  width: 20px;\n  font-size: 16px;\n}\nion-modal::part(content) {\n  background-color: transparent;\n  box-shadow: none;\n}\n.modal-button-container {\n  display: flex;\n  justify-content: end;\n  padding: 10px;\n}\n.modal-slide-number-container {\n  display: flex;\n  justify-content: end;\n  padding: 3px;\n  font-size: 12px;\n  color: #fff;\n}\n\n/***Media***/\n\n@media (max-width: 1000px) {\n  .gallery-button {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
