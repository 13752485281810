/*
 *Mocs to display as testimonials
 */
import { ITestimonials } from "../types/ITestimonials";

const TestimonialsMocs: ITestimonials[] = [
  {
    id: 1,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in massa bibendum, pulvinar augue a, tempor orci. Vivamus vel risus placerat, luctus urna id, rutrum tortor. Nulla nisi ante, ullamcorper at interdum at, consectetur nec urna. Integer eget gravida justo, sit amet dignissim ligula. Aenean lectus tellus, placerat quis sapien vel, pellentesque volutpat neque. Nulla placerat elit vel bibendum venenatis. Curabitur eget fermentum mauris. ",
    img: "../assets/img/wristbands/printed.png",
    name: "Customer name one",
  },
  {
    id: 2,
    text: "Praesent eleifend libero sit amet ipsum malesuada scelerisque. Etiam semper nec leo at vehicula. Sed sit amet risus ultricies, tincidunt odio at, accumsan sem. Aenean eu rutrum orci. Proin condimentum erat in justo ultrices congue. Etiam nulla orci, pharetra sit amet libero sit amet, laoreet tristique augue.",
    img: "../assets/img/wristbands/debossed.png",
    name: "Customer name two",
  },
  {
    id: 3,
    text: "Nulla rhoncus mi eu lacus malesuada, sed ornare massa posuere. Vivamus quam velit, dictum faucibus urna eget, rutrum suscipit ante. Aenean faucibus justo feugiat, viverra justo at, scelerisque tellus. Nullam diam ante, suscipit et faucibus nec, vestibulum vel tellus. Nunc et tincidunt ante. Proin placerat bibendum ipsum, fringilla placerat turpis vestibulum ac. ",
    img: "../assets/img/wristbands/embossed.png",
    name: "Customer name three",
  },
];

export default TestimonialsMocs;
