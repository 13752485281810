import { IonContent, IonPage, IonGrid, IonRow, IonCol } from "@ionic/react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SectionTitle from "../../components/Titles/SectionTitle";
import TextBlock from "../../components/TextBlocks/TextBlock";
import PrivacyMocs from "../../fixtures/privacyPageTexts/PrivacyMocs";
import AdBanner from "../../components/AddBanner/AddBanner";
import "./Privacy.css";

const Privacy: React.FC = () => {
  return (
    <IonPage>
      <Header />
      <IonContent color="light">
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Privacy </span>Policy
                </h2>
              }
              class="title-dark"
            />
            <AdBanner />
          </div>
          <div className="fixed-container fixed-container-white">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <TextBlock text={PrivacyMocs} text-wrap />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Privacy;
