import { useEffect, useState } from "react";
import { IonCard } from "@ionic/react";
import TextColorCard from "../../../../components/Store/TextColorCard/TextColorCard";
import ModalWindowText from "./ModalWindow/ModalWindowText";
import "./TextColorForm.css";
import { IColorCombination } from "../../../../types/IColorCombination";
import { ICombinationField } from "../../../../types/ICombinationField";
import { IProductOptions } from "../../../../types/IProductOptions";

interface IColorFormParams {
  textColorOptions: IProductOptions[];
  selectedCombination: number;
  updateCombination: (
    index: number,
    field: ICombinationField,
    value: number | IProductOptions,
  ) => void;
  colorCombinations: IColorCombination[];
}

const TextColorForm: React.FC<IColorFormParams> = ({
  textColorOptions,
  selectedCombination,
  updateCombination,
  colorCombinations,
}) => {
  const showMoreColors = () => {
    setShowModal(true);
  };

  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  const [textColorOptionsShort, setTextColorOptionsShort] =
    useState<IProductOptions[]>();
  useEffect(() => {
    setTextColorOptionsShort(textColorOptions.slice(0, 4));
  }, [textColorOptions]);

  useEffect(() => {
    if (colorCombinations[selectedCombination].textColor) {
      setTextColorOptionsShort((prevColors: any) => {
        const isColorFeatured = prevColors.some(
          (color: any) =>
            color.id === colorCombinations[selectedCombination].textColor?.id,
        );

        if (!isColorFeatured) {
          // Add selectedColor to the beginning of the array and remove the last color
          return [
            colorCombinations[selectedCombination].textColor,
            ...prevColors.slice(0, 3),
          ];
        } else {
          // If the color is already in the list, do nothing
          return prevColors;
        }
      });
    }
  }, [colorCombinations[selectedCombination].textColor]);

  return (
    <div className="order-options-container">
      <section className="order-section">
        <div className="order-section-header">
          <div className="order-page-container">
            <h2 className="order-section-title">Color:</h2>
          </div>
        </div>

        <div className="wide-options-container">
          <div className="order-block-content color-card-content">
            {textColorOptionsShort &&
              textColorOptionsShort.map(
                (color: IProductOptions, key: number) => {
                  return (
                    <TextColorCard
                      key={key}
                      color={color}
                      setShowModal={setShowModal}
                      selectedCombination={selectedCombination}
                      updateCombination={updateCombination}
                      colorCombinations={colorCombinations}
                    />
                  );
                },
              )}
            <IonCard
              className="color-card ion-no-margin"
              onClick={showMoreColors}
            >
              <div className="text-option-colors-button">
                <span className="show-more-colors">More Colors</span>
                <div className="dots-container">
                  <div className="black-dot"></div>
                  <div className="black-dot"></div>
                  <div className="black-dot"></div>
                </div>
              </div>
            </IonCard>
          </div>
        </div>
        {showModal && (
          <ModalWindowText
            textColorOptions={textColorOptions}
            showModal={showModal}
            setShowModal={setShowModal}
            selectedCombination={selectedCombination}
            updateCombination={updateCombination}
            colorCombinations={colorCombinations}
          />
        )}
      </section>
    </div>
  );
};
export default TextColorForm;
