import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SectionTitle from "../../components/Titles/SectionTitle";

const EmailVerification: React.FC = () => {
  const history = useHistory();
  const [verificationStatus, setVerificationStatus] =
    useState("Verifying Email");
  const { token } = useParams<{ token: string }>();

  const verifyToken = () => {
    axios
      .request({
        method: "GET",
        url: `/api/v1/customer-verify-email/${token}`,
        headers: { "Content-type": "application/json" },
      })
      .then((res) => {
        if (res.data && typeof res.data === "object") {
          setVerificationStatus(res.data.message);
          setTimeout(() => {
            history.push("/login");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error Verifying token", {
          error:
            err.response && err.response.data
              ? err.response.data.code
              : err.response,
          status: err.response && err.response.status | err.status,
          message:
            err.response && err.response.data
              ? err.response.data.message
              : err.response,
        });
        if (err.response && err.response.data) {
          const data = err.response.data;
          if (data.message && typeof data.message === "string") {
            setVerificationStatus(data.message);
            setTimeout(() => {
              history.push("/home");
            }, 3000);
          }
        }
      });
  };

  useEffect(() => {
    verifyToken();
  }, [token]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Email Verification</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid className="ion-padding">
              <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
                <IonCol
                  size="12"
                  size-md="6"
                  size-lg="6"
                  offset-lg="3"
                  offset-md="3"
                  className="text-align-left"
                >
                  <h4>{verificationStatus}</h4>
                  {/* <form id="loginForm" onSubmit={submitLoginData}>
                  </form> */}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default EmailVerification;
