import { IFormField } from "../types/IFormField";
import { useFormInput } from "./utils";

export const useRegisterFields: () => IFormField[] = () => {
  return [
    {
      id: "first_name",
      label: "First Name",
      name: "first_name",
      required: true,
      input: {
        props: {
          type: "text",
          placeholder: "Jane",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "last_name",
      label: "Last Name",
      name: "last_name",
      required: true,
      input: {
        props: {
          type: "text",
          placeholder: "Doe",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "email",
      label: "Email",
      name: "email",
      required: true,
      input: {
        props: {
          type: "email",
          placeholder: "joe@gmail.com",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "password",
      label: "Password",
      name: "password",
      required: true,
      input: {
        props: {
          type: "password",
          placeholder: "*********",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "password_confirmation",
      label: "Confirm Password",
      name: "password_confirmation",
      required: true,
      input: {
        props: {
          type: "password",
          placeholder: "*********",
        },
        state: useFormInput(""),
      },
    },
  ];
};

export const useLoginFields: () => IFormField[] = () => {
  return [
    {
      id: "email",
      label: "Email",
      name: "email",
      required: true,
      input: {
        props: {
          type: "email",
          placeholder: "joe@gmail.com",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "password",
      label: "Password",
      name: "password",
      required: true,
      input: {
        props: {
          type: "password",
          placeholder: "*******",
        },
        state: useFormInput(""),
      },
    },
  ];
};

export const useForgotPasswordFields: () => IFormField[] = () => {
  return [
    {
      id: "email",
      label: "Email",
      name: "email",
      required: true,
      input: {
        props: {
          type: "email",
          placeholder: "joe@gmail.com",
        },
        state: useFormInput(""),
      },
    },
  ];
};

export const useResetFields: () => IFormField[] = () => {
  return [
    {
      id: "email",
      label: "Email",
      name: "email",
      required: true,
      input: {
        props: {
          type: "email",
          placeholder: "joe@gmail.com",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "password",
      label: "Password",
      name: "password",
      required: true,
      input: {
        props: {
          type: "password",
          placeholder: "*******",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "password_confirmation",
      label: "Confirm Password",
      name: "password_confirmation",
      required: true,
      input: {
        props: {
          type: "password",
          placeholder: "*******",
        },
        state: useFormInput(""),
      },
    },
  ];
};
