export const OffersList: string[] = [
  "Engraved wristbands",
  "Thin wristbands (¼”)",
  "Thick wristbands(2”)",
  "Ink injected",
  "Thousands of imprint colors",
  "Large font selections",
  "Full color options",
  "And more than 2,000,000 images and cons to choose from!",
];
