/*
 * Displaying the list of the questions for every section
 */
import { IFAQItem } from "../../../../types/FAQ/IFAQItem";
import FAQItem from "./components/FAQItem/FAQItem";
import { IonList, IonCard } from "@ionic/react";
import "./FAQBlock.css";

interface IFAQBlockParams {
  title: string;
  questions: IFAQItem[];
}

const FAQBlock: React.FC<IFAQBlockParams> = (props) => {
  return (
    <>
      <h4>{props.title}</h4>
      <IonCard className="card-faq">
        <IonList>
          {props.questions.map((question: IFAQItem, index: number) => (
            <FAQItem key={index} item={question} />
          ))}
        </IonList>
      </IonCard>
    </>
  );
};

export default FAQBlock;
