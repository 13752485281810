import { createContext, useReducer, ReactNode } from "react";
import { ICart } from "../types/Cart/ICart";
import { ICoupon } from "../types/Cart/ICoupon";
import useLocalStorage from "../hooks/useLocalStorage";
import { v4 as uuidv4 } from "uuid";

// Define the shape of the state for the cart context
interface State {
  cart: ICart | null; // An array of products in the cart
  guestId: string | null;
  coupon: ICoupon | null;
}

// Define the actions that can be dispatched to update the state
type Action =
  | { type: "UPDATE_ITEM"; payload: ICart | null }
  | { type: "ADD_ITEM"; payload: ICart | null } // Add a product to the cart
  | { type: "SAVE_CART"; payload: ICart | null } // Save cart
  | { type: "CLEAR_CART" } // Remove all products from the cart
  | { type: "SET_CART_FOUND"; payload: ICart | null } // Finding cart in local storage if rerender
  | { type: "STORE_GUEST_ID"; payload: string | null } // Store guest id
  | { type: "STORE_COUPON"; payload: ICoupon | null }; // Store coupon details

const initialState: State = {
  cart: null,
  guestId: null,
  coupon: null,
};

// Define the reducer function that handles the state updates based on dispatched actions
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "ADD_ITEM":
      // Add a new item to the cart
      return {
        ...state,
        cart: action.payload,
      };
    case "SAVE_CART":
      return {
        ...state,
        cart: action.payload,
      };
    case "CLEAR_CART":
      // Remove all items from the cart
      return {
        ...state,
        cart: null,
      };
    case "SET_CART_FOUND":
      // Find cart in local storage is app is reloaded
      return {
        ...state,
        cart: action.payload,
      };
    case "UPDATE_ITEM":
      // Just replace the old cart with the new one from the payload
      return {
        ...state,
        cart: action.payload,
      };
    case "STORE_GUEST_ID":
      return {
        ...state,
        guestId: action.payload,
      };
    case "STORE_COUPON":
      return {
        ...state,
        coupon: action.payload,
      };
    default:
      return state;
  }
};

interface CartProviderProps {
  children: ReactNode;
}

// Create the cart context and provider
export const CartContext = createContext<[State, React.Dispatch<Action>]>([
  initialState,
  () => null,
]);

export const CartProvider = ({ children }: CartProviderProps) => {
  const [cart] = useLocalStorage("cart");
  const [guestId, setGuestId] = useLocalStorage("guestId");
  const [coupon] = useLocalStorage("coupon");

  // Check if there is a guestId in local storage in case of rerendering
  if (guestId && guestId != "") {
    initialState.guestId = guestId;
    reducer(initialState, {
      type: "STORE_GUEST_ID",
      payload: initialState.guestId,
    });
  } else {
    // generate a uuid for the guestId
    const uuidGuestId = uuidv4();

    initialState.guestId = uuidGuestId;
    reducer(initialState, {
      type: "STORE_GUEST_ID",
      payload: initialState.guestId,
    });

    setGuestId(uuidGuestId);
  }

  // Check if there is a cart in local storage in case of rerendering
  if (cart && cart != "") {
    initialState.cart = JSON.parse(cart);
    reducer(initialState, {
      type: "SET_CART_FOUND",
      payload: initialState.cart,
    });
  }
  if (cart == "") {
    console.log("CART_IS_EMPTY OR NOT_FOUND");
  }
  // Check if there is a coupon in local storage in case of rerendering
  if (coupon && coupon != "") {
    initialState.coupon = JSON.parse(coupon);
    reducer(initialState, {
      type: "STORE_COUPON",
      payload: initialState.coupon,
    });
  }
  console.log("CART_THAT_WAS_FOUND", { cart, guestId });
  const value = useReducer(reducer, initialState);
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
