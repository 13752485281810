const FirstBlokMocs: JSX.Element = (
  <div>
    <p>
      Here at Rapid Wristbands, we sell
      <span className="text-attention"> high quality, 100 % silicone </span>
      personalized wristbands for all events and occasions. Our silicone
      bracelets are affordable and ready for you to customize. With our custom
      options, you can inscribe any message, art, logo, or brand onto the color
      wristband of your choice.
    </p>
    <p>
      {" "}
      Although they are referred to as “rubber” wristbands, made popular by the
      WWJD and LIVESTRONG bracelets, our wristbands are rubber-free and we even
      offer latex-free wristbands for those that are allergic.
    </p>

    <p>
      There are no minimum or maximum orders, so whether you need one or a
      million cool, custom silicone bracelets, we are more than happy to
      provide!
    </p>
  </div>
);

export default FirstBlokMocs;
