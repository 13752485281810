/*
 *The page is displaying the table with prices for the cirtain product
 */
import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/react";
/**Import Interfaces */

/***Import Components***/
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AdBanner from "../../components/AddBanner/AddBanner";
import SectionTitle from "../../components/Titles/SectionTitle";
import ButtonBlue from "../../components/Button/ButtonBlue";
import LinksWidgetPrices from "./components/LinksWidgetPrices/LinksWidgetPrices";
import ProductImage from "./components/ProductImage/ProductImage";
import PriceComponent from "./components/PriceComponent/PriceComponent";
/**Import functions***/
import getProductPrices from "../../services/getProductPrices";
import getAttributeOptions from "../../services/attributes/getAttributeOptions";
/**Import interfaces **/
import { IProductVariants } from "../../types/IProductVariants";

/***Import CSS***/
import "./Prices.css";
import { IProductOptions } from "../../types/IProductOptions";
import { IAttributeOption } from "../../types/IAttributeOption";

const Prices: React.FC = () => {
  /*Getting Products**/
  const [variants, setVariants] = useState<IProductVariants[]>([]);
  const [productImage, setProductImage] = useState<string>();
  const [productStyles, setProductStyles] = useState<IProductOptions[]>([]);
  const [productStylesGroup, setProductStylesGroup] = useState<
    IProductVariants[]
  >([]);
  const [activeStyle, setActiveStyle] = useState<IProductOptions>();
  const [loading, setLoading] = useState(true);

  // Fetch product variants on component mount
  useEffect(() => {
    getProductPrices("simple-product-name").then((response) => {
      // Update state with product variants received from the response
      setLoading(false);
      setVariants(response.data.data.productBySlug.product.variants);
    });
  }, []);

  // Fetch attribute options on component mount
  useEffect(() => {
    getAttributeOptions("band_style").then((response) => {
      // Update state with product styles received from the response
      setProductStyles(response.data.data.attributes.data[0].options);
    });
  }, []);

  // Set the initial active style when product styles are fetched
  useEffect(() => {
    // Check if product styles are available and there are variants
    if (productStyles.length > 0 && variants.length > 0) {
      // Set the first style as the active style
      setActiveStyle(productStyles[0]);
    }
  }, [productStyles, variants]);

  // Update the product image and style group when an active style is selected
  useEffect(() => {
    // Check that the active style is set and the variants array is not empty
    if (activeStyle && variants.length > 0) {
      // Set the product image based on the active style's swatch value
      setProductImage(activeStyle.swatchValue);
      // Update the product styles group based on the active style
      setProductStylesGroup(getStyleGroup(activeStyle.adminName));
    }
  }, [activeStyle, variants]);

  // A helper function to filter variants by a style value
  const getStyleGroup = (value: string) => {
    // Return a filtered array of variants that match the given style value
    return variants.filter((variant: IProductVariants) => {
      // Check if any of the variant's attributes match the 'band_style' code and value
      return variant.superAttributesValues.some(
        (attribute: IAttributeOption) =>
          attribute.code === "band_style" && attribute.value === value,
      );
    });
  };

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Wristband Prices: </span>Custom
                  Wristband Pricing
                </h2>
              }
              class="title-dark"
            />
            <AdBanner />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            {!loading ? (
              <IonGrid>
                <IonRow>
                  <IonCol size="12" size-lg="4" size-xl="3">
                    <div className="prices-left-container">
                      {productStyles && activeStyle && (
                        <LinksWidgetPrices
                          productStyles={productStyles}
                          setActiveStyle={setActiveStyle}
                          activeStyle={activeStyle}
                        />
                      )}
                      <div className="price-img-container">
                        {/*Tablet version for product title*/}
                        <div className="wristband-prices wristband-prices-tablet">
                          {activeStyle && (
                            <h3 className="prices-subtitle">
                              {activeStyle.adminName}
                            </h3>
                          )}
                        </div>
                        {productImage && activeStyle && (
                          <ProductImage
                            image={productImage}
                            title={activeStyle.adminName}
                          />
                        )}
                        {/*Tablet version for button position*/}
                        {activeStyle && (
                          <div className="wristband-prices wristband-prices-tablet">
                            <ButtonBlue
                              text={`Order ${activeStyle.adminName}`}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </IonCol>
                  {/*Mobile version for product image,title and button*/}
                  <IonCol size="12" className="mobile">
                    <div className="price-img-container-mobile">
                      <div className="wristband-prices wristband-prices-tablet">
                        {activeStyle && (
                          <h3 className="prices-subtitle">
                            {activeStyle.adminName}
                          </h3>
                        )}
                      </div>
                      {productImage && activeStyle && (
                        <ProductImage
                          image={productImage}
                          title={activeStyle.adminName}
                        />
                      )}
                      <div className="wristband-prices wristband-prices-tablet">
                        {activeStyle && (
                          <ButtonBlue text={`Order ${activeStyle.adminName}`} />
                        )}
                      </div>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    size-lg="8"
                    size-xl="9"
                    className="price-right-container"
                  >
                    {/**Getting Price Component**/}
                    {activeStyle && productStylesGroup.length > 0 && (
                      <PriceComponent
                        productGroup={productStylesGroup}
                        activeStyle={activeStyle}
                      />
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            ) : (
              <div className="centered min-height">
                <IonSpinner name="lines" color="tertiary" />
              </div>
            )}
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Prices;
