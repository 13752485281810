/*
 *Displatying About Page
 */
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from "@ionic/react";

/***Import Components***/
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AdBanner from "../../components/AddBanner/AddBanner";
import SectionTitle from "../../components/Titles/SectionTitle";

/***Import CSS***/
import "./About.css";
import TextBlock from "../../components/TextBlocks/TextBlock";
import AboutForthBlockMocs from "../../fixtures/aboutPageTexts/AboutForthBlockMocs";
import AboutFirstBlockMocs from "../../fixtures/aboutPageTexts/AboutFirstBlockMocs";
import AboutSecondBlockMocs from "../../fixtures/aboutPageTexts/AboutSecondBlockMocks";
import AboutThirdBlockMocs from "../../fixtures/aboutPageTexts/AboutThirdBlockMocs";
import Button from "../../components/Button/Button";

const About: React.FC = () => {
  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section section-about">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">About Rapid Wristbands</span>
                </h2>
              }
              class="title-dark"
            />
            <AdBanner />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            {/**Displaying content of the page */}
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="6">
                  <TextBlock text={AboutFirstBlockMocs} />
                  <TextBlock text={AboutSecondBlockMocs} />
                </IonCol>
                <IonCol size="12" size-md="6">
                  <TextBlock text={AboutThirdBlockMocs} />
                  <TextBlock text={AboutForthBlockMocs} />
                  <Button text="Order Now" />
                  <img
                    src="../../assets/img/about/4.png"
                    className="about-full-img"
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default About;
