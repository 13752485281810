const PrivacyMocs: JSX.Element = (
  <div>
    <p>
      This privacy policy has been compiled to better serve those who are
      concerned with how their 'Personally identifiable information' (PII) is
      being used online. PII, as used in US privacy law and information
      security, is information that can be used on its own or with other
      information to identify, contact, or locate a single person, or to
      identify an individual in context. Please read our privacy policy
      carefully to get a clear understanding of how we collect, use, protect or
      otherwise handle your Personally Identifiable Information in accordance
      with our website.
    </p>

    <h3>
      What personal information do we collect from the people that visit our
      bh3, website or app?
    </h3>
    <p>
      When ordering or registering on our site, as appropriate, you may be asked
      to enter your name, email address, mailing address, phone number, credit
      card information or other details to help you with your experience.
    </p>

    <h3>When do we collect information?</h3>
    <p>
      We collect information from you when you place an order or enter
      information on our site.
    </p>

    <h3>How do we use your information? </h3>
    <p>
      {" "}
      We may use the information we collect from you when you register, make a
      purchase, sign up for our newsletter, respond to a survey or marketing
      communication, surf the website, or use certain other site features in the
      following ways:
    </p>
    <p>To quickly process your transactions.</p>

    <h3>How do we protect visitor information?</h3>
    <p>
      We do not use vulnerability scanning and/or scanning to PCI standards.
    </p>
    <p>We do not use Malware Scanning.</p>
    <p>
      Your personal information is contained behind secured networks and is only
      accessible by a limited number of persons who have special access rights
      to such systems, and are required to keep the information confidential. In
      addition, all sensitive/credit information you supply is encrypted via
      Secure Socket Layer (SSL) technology.{" "}
    </p>

    <p>
      We implement a variety of security measures when a user places an order
      enters, submits, or accesses their information to maintain the safety of
      your personal information.
    </p>

    <p>
      All transactions are processed through a gateway provider and are not
      stored or processed on our servers.
    </p>

    <h3>Do we use 'cookies'?</h3>
    <p>We do not use cookies for tracking purposes </p>
    <p>
      You can choose to have your computer warn you each time a cookie is being
      sent, or you can choose to turn off all cookies. You do this through your
      browser (like Internet Explorer) settings. Each browser is a little
      different, so look at your browser's Help menu to learn the correct way to
      modify your cookies.
    </p>

    <p>
      If you disable cookies off, some features will be disabled that make your
      site experience more efficient and some of our services will not function
      properly.
    </p>

    <p>However, you can still place orders .</p>

    <h3>Third Party Disclosure</h3>
    <p>
      We do not sell, trade, or otherwise transfer to outside parties your
      personally identifiable information.
    </p>

    <h3>Third party links</h3>
    <p>
      We do not include or offer third party products or services on our
      website.
    </p>

    <h4>Google</h4>
    <p>
      Google's advertising requirements can be summed up by Google's Advertising
      Principles. They are put in place to provide a positive experience for
      users. You can read more{" "}
      <a
        href="https://support.google.com/adwordspolicy/answer/1316548?hl=en"
        rel="nofollow noopener noreferrer"
        target="_blank"
      >
        here
      </a>{" "}
    </p>
    <p>We use Google AdSense Advertising on our website.</p>
    <p>
      Google, as a third party vendor, uses cookies to serve ads on our site.
      Google's use of the DART cookie enables it to serve ads to our users based
      on their visit to our site and other sites on the Internet. Users may opt
      out of the use of the DART cookie by visiting the Google ad and content
      network privacy policy.
    </p>
    <p>We have implemented the following:</p>
    <p>Google Display Network Impression Reporting</p>

    <p>
      We along with third-party vendors, such as Google use first-party cookies
      (such as the Google Analytics cookies) and third-party cookies (such as
      the DoubleClick cookie) or other third-party identifiers together to
      compile data regarding user interactions with ad impressions, and other ad
      service functions as they relate to our website.{" "}
    </p>
    <p>
      <strong>Opting out:</strong> Users can set preferences for how Google
      advertises to you using the Google Ad Settings page. Alternatively, you
      can opt out by visiting the Network Advertising initiative opt out page or
      permanently using the Google Analytics Opt Out Browser add on.
    </p>

    <h4>California Online Privacy Protection Act</h4>
    <p>
      CalOPPA is the first state law in the nation to require commercial
      websites and online services to post a privacy policy. The law's reach
      stretches well beyond California to require a person or company in the
      United States (and conceivably the world) that operates websites
      collecting personally identifiable information from California consumers
      to post a conspicuous privacy policy on its website stating exactly the
      information being collected and those individuals with whom it is being
      shared, and to comply with this policy. - See more at:
      http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
    </p>
    <p>According to CalOPPA we agree to the following:</p>
    <p>Users can visit our site anonymously</p>
    <p>
      Once this privacy policy is created, we will add a link to it on our home
      page, or as a minimum on the first significant page after entering our
      website.
    </p>
    <p>
      Our Privacy Policy link includes the word 'Privacy', and can be easily be
      found on the page specified above.
    </p>
    <p>Users will be notified of any privacy policy changes:</p>
    <p>On our Privacy Policy Page</p>
    <p>Users are able to change their personal information:</p>
    <p>By calling us</p>
    <p>By chatting with us or sending us a ticket</p>
    <p>How does our site handle do not track signals?</p>
    <p>
      We honor do not track signals and do not track, plant cookies, or use
      advertising when a Do Not Track (DNT) browser mechanism is in place.{" "}
    </p>
    <p>Does our site allow third party behavioral tracking?</p>
    <p>
      It's also important to note that we do not allow third party behavioral
      tracking
    </p>

    <h4>COPPA (Children Online Privacy Protection Act)</h4>
    <p>
      When it comes to the collection of personal information from children
      under 13, the Children's Online Privacy Protection Act (COPPA) puts
      parents in control. The Federal Trade Commission, the nation's consumer
      protection agency, enforces the COPPA Rule, which spells out what
      operators of websites and online services must do to protect children's
      privacy and safety online.
    </p>
    <p>We do not specifically market to children under 13.</p>

    <h4>Fair Information Practices</h4>
    <p>
      The Fair Information Practices Principles form the backbone of privacy law
      in the United States and the concepts they include have played a
      significant role in the development of data protection laws around the
      globe. Understanding the Fair Information Practice Principles and how they
      should be implemented is critical to comply with the various privacy laws
      that protect personal information.
    </p>
    <p>
      In order to be in line with Fair Information Practices we will take the
      following responsive action, should a data breach occur:
    </p>
    <p>We will notify the users via email</p>
    <p>Within 7 business days</p>
    <p>
      We also agree to the individual redress principle, which requires that
      individuals have a right to pursue legally enforceable rights against data
      collectors and processors who fail to adhere to the law. This principle
      requires not only that individuals have enforceable rights against data
      users, but also that individuals have recourse to courts or a government
      agency to investigate and/or prosecute non-compliance by data processors.
    </p>

    <h4>CAN SPAM Act</h4>
    <p>
      The CAN-SPAM Act is a law that sets the rules for commercial email,
      establishes requirements for commercial messages, gives recipients the
      right to have emails stopped from being sent to them, and spells out tough
      penalties for violations.
    </p>
    <p>We collect your email address in order to:</p>
    <p>To be in accordance with CANSPAM we agree to the following:</p>
    <p>
      If at any time you would like to unsubscribe from receiving future emails,
      you can email us at{" "}
      <a href="mailto:support@rapidwristbands.com">
        support@rapidwristbands.com
      </a>{" "}
      we will promptly remove you from ALL correspondence.
    </p>

    <h4>Contacting Us</h4>
    <p>
      If there are any questions regarding this privacy policy you may contact
      us using the information below.
    </p>
    <p>https://www.rapidwristbands.com/</p>
    <p>
      1056 Hercules Ave
      <br />
      Houston, TX 77058
    </p>
    <p>USA</p>
    <p>1 (800) 523-8078</p>
    <p>Last Edited on 2018-6-27</p>
  </div>
);

export default PrivacyMocs;
