/*
 *Getting data about  from API
 */
import axios, { AxiosResponse } from "axios";

/*Getting Access Token*/
const getOccasionPages = (): Promise<AxiosResponse> => {
  /*Getting the list of occasions pages*/
  return axios.request({
    method: "POST",
    url: "/graphql",
    headers: {
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: `  query occasions {
       occasions {
         data {
           id
           hasCalculator
           hasTestimonials
           hasClipartBarOnBottom
           hasClipartBarOnTop
           translations {
             id
             urlKey
             pageTitle
             htmlContent 
           }
         }
       }
     }`,
    }),
  });
};

export default getOccasionPages;
