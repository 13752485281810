/*
 *Getting data about products from API
 */
import axios, { AxiosResponse } from "axios";

const getProducts = (): Promise<AxiosResponse> => {
  return axios.request({
    method: "GET",
    url: "/api/v1/products",
    params: { include: ["text", "media"] },
  });
};

export default getProducts;
