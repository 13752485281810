import { environment as devEnvironment } from "./config.development";
import { environment as stagingEnvironment } from "./config.staging";
import { environment as productionEnvironment } from "./config.prod";

const env = process.env.APP_ENV || process.env.NODE_ENV || "staging";

let environment = {
  production: false,
  environment: "development",
  apiUrl: process.env.REACT_APP_DEMO_URL,
  smartyKey: "165007858348961838",
};

if (env === "staging") {
  environment = { ...environment, ...stagingEnvironment };
} else if (env === "development") {
  environment = { ...environment, ...devEnvironment };
} else if (env === "production") {
  environment = { ...environment, ...productionEnvironment };
}

export default environment;
