/*
 *Getting Attribute Options
 */
import axios, { AxiosResponse } from "axios";

/*Getting Access Token*/
const getAttributeCollections = (): Promise<AxiosResponse> => {
  /*Getting the product by slug*/
  return axios
    .request({
      method: "POST",
      url: `/graphql`,
      headers: {
        "Content-type": "application/json",
      },
      data: JSON.stringify({
        query: `query {
        allAttributeOptionCollections {
          data {
            id
            name
            attribute {
              id
              adminName
            }
            options {
              id
              adminName
              swatchValue
              attributeOptionPreview {
                image
                hexCodes
              }
            }
          }
        }
      }
      
     `,
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export default getAttributeCollections;
