const ProductList: any = [
  {
    id: 1,
    title: "Printed Wristbands",
    subtitle: "Silk Screen Printed Wristbands",
    img: "../assets/img/wristbands/printed.png",
  },

  {
    id: 2,
    title: "Debossed Wristbands",
    subtitle: "Engraved Lettering",
    img: "../assets/img/wristbands/debossed.png",
  },

  {
    id: 3,
    title: "Ink Injected Wristbands",
    subtitle: "Engraved & Color Filled Silicone Bracelets",
    img: "../assets/img/wristbands/ink-injected.png",
  },

  {
    id: 4,
    title: "Embossed",
    subtitle: "Raised Lettering Silicone Bracelets",
    img: "../assets/img/wristbands/embossed.png",
  },

  {
    id: 5,
    title: "Color Coat",
    subtitle: "Double Layer Silicone Bracelets",
    img: "../assets/img/wristbands/color-coat.png",
  },

  {
    id: 6,
    title: '1" Ink Injected',
    subtitle: "Wide Engraved & Color Filled Bracelets",
    img: "../assets/img/wristbands/one-inch-ink-injected.png",
  },
  {
    id: 7,
    title: "Blank",
    subtitle: "Message-Free Silicone Bands",
    img: "../assets/img/wristbands/blank.png",
  },
  {
    id: 8,
    title: "Custom Tyvek®",
    subtitle: "Custom Silk Screen Printed Wristbands",
    img: "../assets/img/wristbands/custom-tyvek.png",
  },
  {
    id: 9,
    title: "In Stock",
    subtitle: "Tyvekr PAaper Wristbands",
    img: "../assets/img/wristbands/instock-tyvek.png",
  },
];

export default ProductList;
