/*
 *Displatying Free Sample Page
 */
import {
  IonContent,
  IonPage,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonSpinner,
} from "@ionic/react";
import { globe } from "ionicons/icons";

/***Import Components***/
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SectionTitle from "../../components/Titles/SectionTitle";

/***Import CSS***/
import "./FreeSample.css";
import "../../components/FormInputs/CustomField.css";
import Button from "../../components/Button/Button";
import { useForm, Controller } from "react-hook-form";
import { US_STATES } from "../../functions/freeSampleInputs";
import { IFormInputError } from "../../types/IFormInputError";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useEffect, useRef, useState } from "react";
import placeSampleRequest from "../../services/checkout/placeSampleRequest";
import getProductPage from "../../services/getProductPage";
import { IProducts } from "../../types/IProducts";
import React from "react";

interface ISampleRequestFormInputs {
  name: string;
  phone: string;
  address1: string;
  address2: string;
  state: string;
  company: string;
  email: string;
  city: string;
  postal_code: string;
  comments: string;
}

const FreeSample: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ISampleRequestFormInputs>({ mode: "all" });

  const formRef = useRef<HTMLFormElement>(null);
  const [backendErrors, setBackendErrors] = useState<IFormInputError[]>([]);
  const [token] = useLocalStorage("token");
  const [product, setProduct] = useState<IProducts | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleButtonClick = () => {
    // trigger the formRef submission
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event("submit", { bubbles: true }));
      setLoading(true);
    }
  };

  const submitSampleRequestData = (data: ISampleRequestFormInputs) => {
    placeSampleRequest(
      token,
      data.name,
      data.phone,
      data.address1,
      data.address2,
      data.email,
      data.state,
      data.city,
      data.postal_code,
      data.comments,
      data.company || "",
      [{ product_id: product?.id, quantity: 1 }],
    )
      .then((res) => {
        setLoading(false);
        if (res.data.errors) {
          const errors: IFormInputError[] = [];
          const sampleErrorMap: {
            [key: string]: {
              id: string;
              name: string;
              other?: string | undefined;
            };
          } = {
            "input.address.phone": { id: "phone", name: "Phone Number" },
            "input.address.address1": {
              id: "address1",
              name: "Address Line 1",
            },
            "input.address.address2": {
              id: "address2",
              name: "Address Line 2",
            },
            "input.address.state": { id: "state", name: "State" },
            "input.address.city": { id: "city", name: "City" },
            "input.address.postcode": {
              id: "postal_code",
              name: "Postal Code/Zip Code",
            },
            "input.address.email": { id: "email", name: "Email Address" },
          };
          res.data.errors.forEach((err: { message: string }) => {
            console.log("CURRENT_ERROR", { error: err });
            if (
              typeof err === "object" &&
              err !== null &&
              "extensions" in err &&
              typeof err.extensions === "object" &&
              err.extensions !== null &&
              "validation" in err.extensions &&
              typeof err.extensions.validation === "object" &&
              err.extensions.validation !== null
            ) {
              const validationErrors = err.extensions.validation as {
                [key: string]: string[];
              };

              Object.keys(validationErrors).forEach((key) => {
                const eId = sampleErrorMap[key];
                let msg = validationErrors[key][0].replaceAll(
                  key,
                  eId.name.toLowerCase(),
                );

                if (eId.other) {
                  msg = msg.replaceAll(eId.other, eId.name.toLowerCase());
                }

                // this is a simple hack to replace the phone error message
                // with a more user friendly message
                if (
                  eId.id === "phone" &&
                  msg == "The phone number format is invalid."
                ) {
                  msg = "The phone number must be in the format 123-456-7890.";
                }

                errors.push({
                  id: eId.id,
                  message: msg,
                });
              });
            } else {
              errors.push({
                message: err.message,
                id: "name", // this is lazy, but will be fixed later. NB: Errors are unique to each field, can't just assume every error is for the name field
              });
            }
          });

          setBackendErrors(errors);
          setSuccessMessage(null);
        } else {
          setBackendErrors([]);
          setSuccessMessage(res.data.data.orderSample.message);

          // clear all the form fields
          formRef.current?.reset();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getProductPage("free-product").then((response) => {
      console.log("PRODUCT", response.data.data.productBySlug);
      // redirect to 404 if product is not found
      if (!response.data.data.productBySlug) {
        //window.location.href = "/404";
        return;
      }
      setProduct(response.data.data.productBySlug);
    });
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        <section className="page-section section-about">
          <div className="fixed-container">
            {loading && (
              <div className="form-overlay">
                <IonSpinner name="lines" />
              </div>
            )}
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">
                    Request a Free Wristband Sample
                  </span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid>
              <p>* Required Fields</p>
              <form
                id="sampleRequestForm"
                method="POST"
                onSubmit={handleSubmit(submitSampleRequestData)}
                ref={formRef}
              >
                {backendErrors.length > 0 &&
                  backendErrors.map((error, index) => (
                    <React.Fragment key={error.id}>
                      <span className="errorText">{error.message}</span>
                      {index < backendErrors.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                {successMessage && (
                  <span className="successText">{successMessage}</span>
                )}
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">Your Name *</IonLabel>
                      <Controller
                        control={control}
                        name="name"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonInput={field.onChange}
                            className={`customInput ${
                              errors.name ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.name && (
                          <span className="errorText">
                            {errors.name.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">Company Name</IonLabel>
                      <Controller
                        control={control}
                        name="company"
                        rules={{ required: false }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonInput={field.onChange}
                            className={`customInput ${
                              errors.company ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.company && (
                          <span className="errorText">
                            {errors.company.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">Phone Number *</IonLabel>
                      <Controller
                        control={control}
                        name="phone"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonInput={field.onChange}
                            className={`customInput ${
                              errors.phone ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.phone && (
                          <span className="errorText">
                            {errors.phone.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">
                        Email Address *
                      </IonLabel>
                      <Controller
                        control={control}
                        name="email"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonInput={field.onChange}
                            className={`customInput ${
                              errors.email ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.email && (
                          <span className="errorText">
                            {errors.email.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">
                        Address Line 1 *
                      </IonLabel>
                      <Controller
                        control={control}
                        name="address1"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonInput={field.onChange}
                            className={`customInput ${
                              errors.address1 ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.address1 && (
                          <span className="errorText">
                            {errors.address1.message ||
                              "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">Address Line 2</IonLabel>
                      <Controller
                        control={control}
                        name="address2"
                        rules={{ required: false }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonChange={field.onChange}
                            className={`customInput ${
                              errors.address2 ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.address2 && (
                          <span className="errorText">
                            {errors.address2.message ||
                              "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">City *</IonLabel>
                      <Controller
                        control={control}
                        name="city"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonChange={field.onChange}
                            className={`customInput ${
                              errors.city ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.city && (
                          <span className="errorText">
                            {errors.city.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">State *</IonLabel>
                      <Controller
                        control={control}
                        name="state"
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonSelect
                            id="state"
                            name="state"
                            value={value}
                            onIonChange={onChange}
                            onBlur={onBlur}
                            className={`customSelect ${
                              errors.state ? "errorBorder" : ""
                            }`}
                          >
                            <IonSelectOption value="" disabled hidden>
                              Select a State
                            </IonSelectOption>
                            {US_STATES.map((state) => (
                              <IonSelectOption key={state} value={state}>
                                {state}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        )}
                      />
                      <IonLabel>
                        {errors.state && (
                          <span className="errorText">
                            {errors.state.message || "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">
                        Postal Code/Zip Code *
                      </IonLabel>
                      <Controller
                        control={control}
                        name="postal_code"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <IonInput
                            type="text"
                            value={field.value}
                            onIonChange={field.onChange}
                            className={`customInput ${
                              errors.postal_code ? "errorBorder" : ""
                            }`}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.postal_code && (
                          <span className="errorText">
                            {errors.postal_code.message ||
                              "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <div className="field">
                      <IonLabel className="fieldLabel">Comments</IonLabel>
                      <Controller
                        control={control}
                        name="comments"
                        rules={{ required: false }}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonTextarea
                            id="comments"
                            name="comments"
                            value={value}
                            onIonChange={onChange}
                            onBlur={onBlur}
                            className={`customTextarea ${
                              errors.comments ? "errorBorder" : ""
                            }`}
                            rows={4}
                          />
                        )}
                      />
                      <IonLabel>
                        {errors.comments && (
                          <span className="errorText">
                            {errors.comments.message ||
                              "This field is required"}
                          </span>
                        )}
                      </IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              </form>
            </IonGrid>
          </div>
          <div className="fixed-container">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <Button
                    id="requestBtn"
                    text="Send My Sample Pack"
                    onClick={handleButtonClick}
                    disabled={isValid === false}
                  />
                </IonCol>
                <IonCol size="12" size-md="6">
                  <IonCol size="12" size-md="6">
                    <div className="international-contact">
                      <IonIcon className="contact-icon" icon={globe} />
                      For international sample requests please contact us at
                      <a className="contact-link" href="tel:+1(800)523-8078">
                        +1(800)523-8078
                      </a>
                    </div>
                  </IonCol>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default FreeSample;
