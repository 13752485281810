/*
 *Displaying color combination on the receipt
 */

import { IColorCombination } from "../../../../../../types/IColorCombination";
import "./OrderItemColorCombination.css";

interface IOrderItemColorCombinationParams {
  orderItemColorCombination: IColorCombination;
}

const OrderItemColorCombination: React.FC<IOrderItemColorCombinationParams> = ({
  orderItemColorCombination,
}) => {
  return (
    <div className="cart-item-colorcombination-line">
      {/* Full width on desktop, half width on mobile */}
      {/* Quantity and Color Block */}
      <div className="quantity-first-arrow-block">
        <div className="quantity-image-block">
          <div className="color-block-items">
            <img
              className="preview-icon-cart"
              src="../../../../../../assets/img/preview.png"
              alt="Preview Icon"
            />
            <div className="order-color-block-item">
              <p className="quantity-size-label">Band Color:</p>
              <div className="color-preview-container-cart">
                <img
                  className="color-preview-cart"
                  src={`storage/${orderItemColorCombination.color?.attributeOptionPreview.image}`}
                  alt="Band Color"
                />
              </div>
            </div>
            <div className="order-color-block-item">
              <p className="quantity-size-label">Text Color:</p>
              <div className="color-preview-container-cart">
                <div
                  className="color-preview-cart"
                  style={{
                    backgroundColor:
                      orderItemColorCombination.textColor
                        ?.attributeOptionPreview.hexCodes,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Full width on desktop, half width on mobile */}
      {/* Quantity Fields */}
      <div className="quantity-size-arrow">
        {["youth", "adult", "large"].map((size) => (
          <div className="quantity-size-input-container" key={size}>
            <span className="quantity-size-label">
              {size.charAt(0).toUpperCase() + size.slice(1)}:
            </span>
            <span>{orderItemColorCombination[size] || 0}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderItemColorCombination;
