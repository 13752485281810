/*
 *Widget with the list of products, which is displaying left to product description
 */

import { IonList, IonCard, IonCardHeader, IonCardTitle } from "@ionic/react";
import React, { useEffect, useState } from "react";
import LinksWidgetItem from "./components/LinksWidgetItem";
import { ILinks } from "../../types/ILinks";
import getProducts from "../../services/getProducts";
import { IProducts } from "../../types/IProduct";
import "./LinksWidget.css";

const LinksWidget: React.FC = () => {
  const productListLinks: ILinks[] = [];
  const [productList, setProductList] = useState([]);
  useEffect(() => {
    getProducts().then((response) => {
      setProductList(response.data.data.data);
    });
  }, []);

  productList.forEach((element: IProducts) => {
    //creating the array of products for widget on ristband style pages
    //it exclude tyvek page.
    if (
      element.attributes["product.url"] !== "tyvek" &&
      element.attributes["product.url"] !== "in-stock" &&
      element.attributes["product.url"] !== "1-Ink-Injected"
    ) {
      productListLinks.push({
        text: element.attributes["product.label"],
        path: element.attributes["product.url"],
      });
    }
  });

  return (
    <IonCard className="card-widget">
      <IonCardHeader color="primary">
        <IonCardTitle className="widget-link-title">
          Silicone Wristband Styles
        </IonCardTitle>
      </IonCardHeader>
      <IonList lines="none" class="widget-list">
        {productListLinks.map((item: ILinks) => (
          <LinksWidgetItem key={item.text} item={item} />
        ))}
      </IonList>
    </IonCard>
  );
};

export default LinksWidget;
