import { IonCol, IonRouterLink } from "@ionic/react";
import { CallToActionProp } from "../../types/CallToActionProp";

const pStyle = { marginBottom: "-10px" };
export const Action = (props: CallToActionProp) => (
  <IonCol size="12">
    <p style={pStyle}>
      {props.message}
      <IonRouterLink className="custom-link" routerLink={props.link}>
        {" "}
        {props.text}
      </IonRouterLink>
    </p>
  </IonCol>
);
