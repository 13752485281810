/*
 *Clipart Bar for displaying on ocassions pages.
 */

import { IonGrid, IonCol, IonRow } from "@ionic/react";
import { IImages } from "../../types/IImages";
import ClipartList from "../../fixtures/ClipartList";
import "./ClipartBar.css";

const ClipartBar: React.FC = () => {
  return (
    <IonGrid className="clipart-container">
      <IonRow>
        {ClipartList.map((item: IImages, index: number) => (
          <IonCol size="4" size-md="2" className="clipart-item" key={index}>
            <img src={item.src} alt={item.alt}></img>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default ClipartBar;
