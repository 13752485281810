/*
 *The form to add cliparts
 */

import { useEffect, useState } from "react";
import { IonModal, IonIcon } from "@ionic/react";
import { IFile } from "../../../../types/IFile";
import AddClipartButton from "./components/addClipartButton/AddClipartButton/AddClipartButton";
import search from "../../../../assets/icon/search.svg";
import upload from "../../../../assets/icon/upload.svg";
import closeButton from "../../../../assets/icon/close_button.svg";

import OptionButton from "../../../../components/Store/OptionButton/OptionButton";
import AdditionalButton from "../../../../components/Store/AdditionalButton/AdditionalButton";
import "./ClipArtForm.css";
import { IProductOptions } from "../../../../types/IProductOptions";
import { IAddClipartOptions } from "../../../../types/IAddClipartOption";
import ClipartSearchBlock from "./components/ClipartSearch/ClipartSearchBlock";
import { IClipartSearchItem } from "../../../../types/IClipartSearchItem";

interface IClipArtFormParams {
  frontStartImage: IFile | undefined;
  setFrontStartImage: (file: IFile | undefined) => void;
  frontEndImage: IFile | undefined;
  setFrontEndImage: (file: IFile | undefined) => void;
  backStartImage: IFile | undefined;
  setBackStartImage: (file: IFile | undefined) => void;
  backEndImage: IFile | undefined;
  setBackEndImage: (file: IFile | undefined) => void;
  wrapStartImage: IFile | undefined;
  setWrapStartImage: (file: IFile | undefined) => void;
  wrapEndImage: IFile | undefined;
  setWrapEndImage: (file: IFile | undefined) => void;
  messageStyle: IProductOptions | undefined;
  frontStartClipartData: IProductOptions | undefined;
  frontEndClipartData: IProductOptions | undefined;
  backStartClipartData: IProductOptions | undefined;
  backEndClipartData: IProductOptions | undefined;
  wrapStartClipartData: IProductOptions | undefined;
  wrapEndClipartData: IProductOptions | undefined;
}

const ClipArtForm: React.FC<IClipArtFormParams> = (props) => {
  const [activeClipart, setActiveClipart] = useState<IProductOptions>();
  const [showClipartModal, setShowClipartModal] = useState<boolean>(false);
  const [showClipartSearchModal, setShowClipartSearchModal] =
    useState<boolean>(false);
  const [messageStyle, setMessageStyle] = useState<string>();
  const [iconFromSearch, setIconFromSearch] = useState<IClipartSearchItem>();

  useEffect(() => {
    if (props.messageStyle) {
      setMessageStyle(props.messageStyle.swatchValue);
    }
  }, [props.messageStyle]);

  const addClipartImage: IAddClipartOptions[] = [
    { id: 1, name: "search", icon: search },
    { id: 2, name: "upload", icon: upload },
  ];
  const [activeAddClipartImage, setActiveAddClipartImage] =
    useState<IAddClipartOptions>();

  //Saving images for cliparts
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Check if files are selected
    if (!event.target.files || event.target.files.length === 0) {
      alert("No file selected!");
      return;
    }

    // Get the first file
    const file = event.target.files[0];

    // File size validation (300KB)
    if (file.size > 300 * 1024) {
      alert("File size exceeds 300KB!");
      return;
    }

    // List of accepted image formats
    const acceptedImageFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
    ];

    // File type validation (Only specified image formats)
    if (!acceptedImageFormats.includes(file.type)) {
      alert("Only jpg, jpeg, png, and gif formats are allowed!");
      return;
    }

    // Update the state
    const imageUrl = URL.createObjectURL(file);

    if (!activeClipart) {
      return; // If there's no active clipart, there's nothing more to do.
    }

    switch (activeClipart.code) {
      case "front_start":
        props.setFrontStartImage({ url: imageUrl, file: file });
        break;
      case "front_end":
        props.setFrontEndImage({ url: imageUrl, file: file });
        break;
      case "back_start":
        props.setBackStartImage({ url: imageUrl, file: file });
        break;
      case "back_end":
        props.setBackEndImage({ url: imageUrl, file: file });
        break;
      case "wrap_start":
        props.setWrapStartImage({ url: imageUrl, file: file });
        break;
      case "wrap_end":
        props.setWrapEndImage({ url: imageUrl, file: file });
        break;
      default:
        // Optionally, handle any unexpected values of activeClipart.code
        console.warn("Unexpected clipart code:", activeClipart.code);
    }

    setShowClipartModal(false);
  };

  const triggerFileInput = () => {
    // Safely access the file input element
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  // Save the icon from search to the appropriate location
  const saveIconFromSearch = () => {
    if (!iconFromSearch) {
      return;
    }
    const imageUrl = iconFromSearch.url;
    const term = iconFromSearch.term;
    const id = iconFromSearch.id;

    if (!activeClipart) {
      return; // If there's no active clipart, there's nothing more to do.
    }
    switch (activeClipart.code) {
      case "front_start":
        props.setFrontStartImage({
          url: imageUrl,
          term: term,
          id: id,
          search: true,
        });
        break;
      case "front_end":
        props.setFrontEndImage({
          url: imageUrl,
          term: term,
          id: id,
          search: true,
        });
        break;
      case "back_start":
        props.setBackStartImage({
          url: imageUrl,
          term: term,
          id: id,
          search: true,
        });
        break;
      case "back_end":
        props.setBackEndImage({
          url: imageUrl,
          term: term,
          id: id,
          search: true,
        });
        break;
      case "wrap_start":
        props.setWrapStartImage({
          url: imageUrl,
          term: term,
          id: id,
          search: true,
        });
        break;
      case "wrap_end":
        props.setWrapEndImage({
          url: imageUrl,
          term: term,
          id: id,
          search: true,
        });
        break;
      default:
        console.warn("Unexpected clipart code:", activeClipart.code);
    }
    setIconFromSearch(undefined);
  };

  //if chosen icon is changed, initiate the saveIconFromSearch function
  useEffect(() => {
    saveIconFromSearch();
  }, [iconFromSearch]);

  useEffect(() => {
    if (activeAddClipartImage?.id === 1) {
      setShowClipartSearchModal(true);
    }
  }, [activeAddClipartImage]);

  activeAddClipartImage?.id === 1;

  return (
    <div className="order-options-container">
      <section className="order-section">
        <div className="order-section-header">
          <div className="order-page-container">
            <h2 className="order-section-title">Clipart:</h2>
          </div>
        </div>

        {messageStyle === "front_and_back" ? (
          <div className="front-back-cliparts-container ">
            <div className="inside-clipart-container">
              <AddClipartButton
                image={props.frontStartImage}
                clipartPosition={props.frontStartClipartData}
                setActiveClipart={setActiveClipart}
                setShowClipartModal={setShowClipartModal}
              />
              <AddClipartButton
                image={props.frontEndImage}
                clipartPosition={props.frontEndClipartData}
                setActiveClipart={setActiveClipart}
                setShowClipartModal={setShowClipartModal}
              />
            </div>
            <div className="inside-clipart-container">
              <AddClipartButton
                image={props.backStartImage}
                clipartPosition={props.backStartClipartData}
                setActiveClipart={setActiveClipart}
                setShowClipartModal={setShowClipartModal}
              />
              <AddClipartButton
                image={props.backEndImage}
                clipartPosition={props.backEndClipartData}
                setActiveClipart={setActiveClipart}
                setShowClipartModal={setShowClipartModal}
              />
            </div>
          </div>
        ) : (
          <div className="wrap-around-cliparts-container ">
            <AddClipartButton
              image={props.wrapStartImage}
              clipartPosition={
                props.wrapStartClipartData
                  ? props.wrapStartClipartData
                  : undefined
              }
              setActiveClipart={setActiveClipart}
              setShowClipartModal={setShowClipartModal}
            />

            <AddClipartButton
              image={props.wrapEndImage}
              clipartPosition={
                props.wrapEndClipartData ? props.wrapEndClipartData : undefined
              }
              setActiveClipart={setActiveClipart}
              setShowClipartModal={setShowClipartModal}
            />
          </div>
        )}
      </section>
      <IonModal
        style={{ "--backdrop-opacity": "0" }}
        isOpen={showClipartModal}
        onDidDismiss={() => setShowClipartModal(false)}
        className="modal-clipart-container"
      >
        <section className="order-section">
          <div className="clipart-modal-container">
            <div className="order-section-header">
              <div className="order-page-container">
                <div className="title-with-button position-space-between">
                  <h2 className="order-section-title">
                    {activeClipart?.adminName
                      ? `Clipart ${activeClipart.adminName}`
                      : ""}
                  </h2>
                  <IonIcon
                    className="close-button"
                    slot="end"
                    icon={closeButton}
                    onClick={() => {
                      setShowClipartModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-clipart-content-container">
              <div className="modal-clipart-button-container">
                <div className="message-options-style-container">
                  {addClipartImage.map((item, key) => (
                    <OptionButton
                      key={key}
                      selectedOptionItem={activeAddClipartImage}
                      optionItem={item}
                      setOptionItem={setActiveAddClipartImage}
                    />
                  ))}
                </div>
                <div className="file-input-container">
                  <input
                    type="file"
                    id="fileInput"
                    hidden
                    onChange={(e) => handleFileChange(e)}
                  />
                  {activeAddClipartImage?.id === 2 && (
                    <>
                      <AdditionalButton
                        text="Choose from Your Computer"
                        event={triggerFileInput}
                      />
                      <span className="explanation-text">
                        Accepted File Types: jpeg, jpg, gif, bmp, png, & svg
                      </span>
                    </>
                  )}
                  {activeAddClipartImage?.id === 1 && (
                    <ClipartSearchBlock
                      activeClipart={activeClipart}
                      showModal={showClipartSearchModal}
                      setShowClipartModal={setShowClipartModal}
                      setIconFromSearch={setIconFromSearch}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </IonModal>
    </div>
  );
};

export default ClipArtForm;
