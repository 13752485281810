import axios, { AxiosResponse } from "axios";

const applyCoupon = (
  code: string,
  token: string,
  guestId: string | null = null,
  cartId: number | string = 0,
): Promise<AxiosResponse> => {
  const headers = {
    Authorization: token,
    "Content-type": "application/json",
  };
  const cart_id =
    cartId === 0 || typeof cartId === "number" ? 0 : parseInt(cartId, 10);

  if (guestId && !token.includes("Bearer")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["Guest-Identifier"] = guestId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["x-cart-id"] = cart_id;
  }

  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers,
    data: JSON.stringify({
      query: ` mutation applyCoupon ($code: String!) {
        applyCoupon(input: {
            code: $code
        }) {
            success
            message
            cart {
                id
                customerEmail
                customerFirstName
                customerLastName
                shippingMethod
                taxTotal
                grandTotal
                baseGrandTotal
                discountAmount
                selectedShippingRate {
                  price
                }
                itemsQty
                itemsCount
                isTaxExempt
                subTotal
                items {
                  id
                  quantity
                  sku
                  type
                  name
                  price
                  basePrice
                  total
                  baseTotal
                  taxPercent
                  taxAmount
                  baseTaxAmount
                  cartId
                  productId
                  productFlat {
                    id
                  }
                  designPersonalizations {
                    attributeId
                    textValue
                    jsonValue
                    integerValue
                    attributeOption {
                      id
                      adminName
                      swatchValue
                      attributeOptionPreview{
                        image
                        hexCodes
                      }
                    }
                    attribute {
                      adminName
                      type
                      code
                    }
                  }
                  additional 
              }  
            }
        }
    }
      `,
      variables: {
        code: code,
      },
    }),
  });
};

export default applyCoupon;
