import { IonCol } from "@ionic/react";
import { ITrustingCompany } from "../../../../types/ITrustingCompany";
import "./TrustingCompaniesItem.css";

interface ITrustingCompaniesItemParams {
  item: ITrustingCompany;
}

const TrustingCompaniesItem: React.FC<ITrustingCompaniesItemParams> = ({
  item,
}) => {
  return (
    <IonCol
      className="img-container"
      size="6"
      size-md="4"
      size-lg="3"
      size-xl="2"
    >
      <img className="trusted-img" src={item.img} alt={item.img} />
    </IonCol>
  );
};

export default TrustingCompaniesItem;
