/*
 *Fundriser Calculator - commented to avoid errors, needs to be updated with new GRAPQl
 */

import React, { useState } from "react";
import {
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonIcon,
  IonItem,
  IonInput,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import { calculatorOutline } from "ionicons/icons";
import { IProducts } from "../../types/IProduct";
//import getProductPrices from "../../services/getProductPrices";
//import getProductSizes from "../../functions/getProductSizes";
import SectionTitleLeft from "../Titles/SectionTitleLeft";
import Button from "../Button/Button";
//import getPricesForAmount from "../../functions/getPricesForAmount";
import "./FundCalculator.css";

//variable which is needed to provide select options
const compareWith = (o1: IProducts, o2: IProducts) => {
  return o1 && o2 ? o1.id === o2.id : o1 === o2;
};

const FundCalculator: React.FC = () => {
  const [productList, setProductList] = useState<any[]>([]);
  const [wristband, setWristband] = useState<any>();
  const [wristbandSizes, setWristbandSizes] = useState<any>();
  const [wristbandSize, setWristbandSize] = useState<any>();
  const [raiseGoal, setRaiseGoal] = useState<number>(500);
  const [salePrice, setSalePrice] = useState<number>(5);
  //const [tempWristAmount, setTempWristAmount] = useState<any>();
  //const [pricesVariants, setPricesVarianrts] = useState([]);
  //const [price, setPrice] = useState();
  const [wristbandsAmount, setWristbandsAmount] = useState<number>(0);
  const [customerCost, setCustomerCost] = useState<number>(0);
  const [customerRevenue, setCustomerRevenue] = useState<number>(0);
  const customerProfit = customerRevenue - customerCost;

  //Getting data from products
  /*useEffect(() => {
    getProductPrices().then((response) => {
      setProductList(response.data.data.data);
      setWristband(response.data.data.data[0]);
    });
  }, []);

  //Getting Data from Included
  const [productData, setProductData] = useState<any[]>([]);
  useEffect(() => {
    getProductPrices().then((response) => {
      setProductData(response.data.data.included);
    });
  }, []);

  //Setting sizes
  useEffect(() => {
    if (wristband && productData) {
      const sizes = getProductSizes(wristband, productData);
      setWristbandSizes(sizes);
      setWristbandSize(sizes[0]);
    }
  }, [wristband, productData]);

  //setting prices variants
  useEffect(() => {
    if (wristbandSize && productData) {
      const prices = updatePricesVariants();
      setPricesVarianrts(prices);
    }
  }, [wristbandSize, productData]);

  //Function to return prices list
  function updatePricesVariants() {
    const priceList: any = [];
    if (productData && wristbandSize) {
      const priceArray = productData.filter((item) => item.type == "price");
      const priceIds: string[] = [];
      const priseListItems = wristbandSize.relationships.price.data;
      for (let i = 0; i < priseListItems.length; i++) {
        priceIds.push(priseListItems[i].attributes["product.lists.refid"]);
      }
      priceIds.forEach((element) => {
        priceList.push(priceArray.find((item: any) => item.id == element));
      });
      return priceList;
    }
  }

  //setting prices for certain amount
  useEffect(() => {
    if (pricesVariants && salePrice) {
      setPrice(getPricesForAmount(pricesVariants, tempWristAmount));
    }
  }, [pricesVariants, tempWristAmount, salePrice]);

  //showing error if salePrice<1
  const [errorClass, setErrorClass] = useState("error-no-show");
  useEffect(() => {
    setErrorClass("error-no-show");
    if (salePrice >= 1) {
      setTempWristAmount(Math.ceil(raiseGoal / salePrice));
    } else {
      setErrorClass("error-show");
    }
  }, [raiseGoal, salePrice]);

  //showing error if salePrice< or = the real price
  const [errorPrice, setErrorPrice] = useState("no-error-price");
  useEffect(() => {
    setErrorClass("no-error-price");
    if (price && salePrice && salePrice > price) {
      setWristbandsAmount(Math.round(raiseGoal / (salePrice - price)));
      setErrorPrice("no-error-price");
    } else if (price && salePrice && salePrice <= price) {
      setErrorPrice("error-price");
    }
  }, [price, salePrice, raiseGoal, wristbandsAmount]);

  //setting customer cost
  useEffect(() => {
    if (price && wristbandsAmount) {
      setCustomerCost(Math.round(wristbandsAmount * price));
    }
  }, [price, wristbandsAmount, raiseGoal]);

  //setting customer revenue
  useEffect(() => {
    if (salePrice && wristbandsAmount && salePrice >= 1) {
      setCustomerRevenue(Math.round(salePrice * wristbandsAmount));
    }
  }, [salePrice, wristbandsAmount]);*/

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <div className="calc-title">
            {" "}
            <IonIcon
              slot="start"
              icon={calculatorOutline}
              color="primary"
              size="large"
            ></IonIcon>
            <SectionTitleLeft
              title={<h3>Fundraising Calculator</h3>}
              class="title-dark"
            />
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonItem lines="none" className="calc-data">
            <IonLabel class="ion-text-wrap" className="calc-label">
              How much do you want to raise?
            </IonLabel>
            $
            <IonInput
              className="calc-input"
              type="number"
              value={raiseGoal}
              onIonChange={(e) => setRaiseGoal(parseInt(e.detail.value!, 10))}
            ></IonInput>
            <div className="btn-calc-container">
              <Button text="Order Now"></Button>
            </div>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12" size-md="7" size-lg="5">
          {wristband && (
            <IonItem lines="none" className="calc-item">
              <div className="input-container">
                <IonLabel class="ion-text-wrap">
                  Which style do you like?
                </IonLabel>
                <IonSelect
                  className="calc-select calc-select-long"
                  interface="popover"
                  value={wristband}
                  placeholder={wristband.attributes["product.label"]}
                  compareWith={compareWith}
                  onIonChange={(e) => setWristband(e.detail.value)}
                >
                  {productList.map((option) => (
                    <IonSelectOption key={option.id} value={option}>
                      {option.attributes["product.label"]}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
            </IonItem>
          )}
        </IonCol>
        <IonCol size="12" size-md="5" size-lg="4">
          {wristbandSize && (
            <IonItem lines="none" className="calc-item">
              <IonLabel class="ion-text-wrap">What size?</IonLabel>
              <IonSelect
                className="calc-select"
                value={wristbandSize}
                interface="popover"
                compareWith={compareWith}
                onIonChange={(e) => setWristbandSize(e.detail.value)}
              >
                {wristbandSizes.map((option: any) => (
                  <IonSelectOption key={option.id} value={option}>
                    {option.attributes["product.label"]}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          )}
        </IonCol>
        <IonCol size="12" size-md="3" className="btn-calc">
          <Button text="Order Now"></Button>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard className="calc" color="light">
            <IonCardTitle>
              <h4>Fundraising Calculations:</h4>
            </IonCardTitle>
            <IonCardContent>
              <IonItem className="calc-item" color="light" lines="none">
                <IonLabel class="ion-text-wrap" className="calc-factors-label">
                  To reach your total, you will need to sell approximately
                  <strong> {wristbandsAmount}</strong> wristbands for
                </IonLabel>
                $
                <IonInput
                  type="number"
                  required
                  className="calc-input"
                  value={salePrice}
                  min="1"
                  onIonChange={(e) =>
                    setSalePrice(parseInt(e.detail.value!, 10))
                  }
                ></IonInput>
                each.
              </IonItem>
              {/*<div className={errorClass}>*/}
              <p className="error-calc">
                Your sale price should be more or equal to $1
              </p>
              {/*</div>
              <div className={errorPrice}>*/}
              <p className="error-calc">
                Please change Raise Goal or Sale Price
              </p>
              {/* </div>*/}
              <h5 className="calc-title">*Calculation Factors:</h5>
              {wristband && (
                <IonItem color="light" lines="none">
                  The above fundraising pricing is based on our{" "}
                  {wristband.attributes["product.label"]} style and does NOT
                  include shipping or additional added features (like interior
                  messages, additional characters, etc.).
                </IonItem>
              )}
              <IonItem color="light" lines="none">
                <ul>
                  <li>
                    Your Wristband Cost:{" "}
                    <span className="cost">${customerCost}</span>
                  </li>
                  <li>
                    Your Revenue:{" "}
                    <span className="profit">${customerRevenue}</span>
                  </li>
                  <li>
                    Your Profit:{" "}
                    <span className="profit">${customerProfit}</span>
                  </li>
                </ul>
              </IonItem>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default FundCalculator;
