/*
 * Function to process the update cart item response from server
 */

import { AxiosResponse } from "axios";
import { ICartItem } from "../../types/Cart/ICartItem";
import { IProductOptions } from "../../types/IProductOptions";

export const handleUpdateItemResponse = (
  response: AxiosResponse,
  setCart: any,
  dispatch: any,
) => {
  return new Promise<void>((resolve, reject) => {
    if (response.data.errors) {
      alert(response.data.errors[0].debugMessage);
      reject(new Error("Error processing the response"));
      return;
    }

    setCart(JSON.stringify(response.data.data.updateItemToCart.cart));
    dispatch({
      type: "UPDATE_ITEM",
      payload: response.data.data.updateItemToCart.cart,
    });
    alert(response.data.data.updateItemToCart.message);

    const items = response.data.data.updateItemToCart.cart.items;
    let message = "";

    items.forEach((item: ICartItem) => {
      item.designPersonalizations.forEach(
        (personalization: IProductOptions) => {
          const { adminName } = personalization.attribute;
          const value =
            personalization.textValue ||
            personalization.attributeOption?.swatchValue ||
            personalization.integerValue;
          message += `${adminName} - ${value}\n`;
        },
      );
    });
    resolve(); // Resolve the promise when all the operations are done.
  });
};
