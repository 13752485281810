/*
 *Displaying the pair: quantity-price
 */
import React from "react";
import { IPriceItem } from "../../../../../../types/IPriceItem";

interface IProductPriceItemParams {
  priceItem: IPriceItem;
}

/***Geting every sinle pair: quanyity-price*/
const ProductPriceItem: React.FC<IProductPriceItemParams> = (props) => {
  return (
    <div className="price-col">
      <div className="price-inside-row">{props.priceItem.qty}</div>
      <div className="price-inside-row price-inside-row-gray">
        {props.priceItem.value}
      </div>
    </div>
  );
};

export default ProductPriceItem;
