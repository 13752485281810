import axios, { AxiosResponse } from "axios";

const addTaxExemption = (
  cart_id: number,
  guestId: string | null = null,
  is_tax_exempt: boolean,
  token: string,
): Promise<AxiosResponse> => {
  const headers = {
    Authorization: token,
    "Content-type": "application/json",
    Accept: "application/json",
  };

  if (guestId && !token.includes("Bearer")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["Guest-Identifier"] = guestId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["x-cart-id"] = cart_id;
  }

  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers,
    data: JSON.stringify({
      query: `
        mutation addTaxExemption($cart_id: Int!, $is_tax_exempt: Boolean!) {
          addTaxExemption(input: { cart_id: $cart_id, is_tax_exempt: $is_tax_exempt }) {
            success
            message
            cart {
              id
              customerEmail
              customerFirstName
              customerLastName
              shippingMethod
              selectedShippingRate {
                price
              }
              discountAmount
              taxTotal
              grandTotal
              baseGrandTotal
              itemsQty
              itemsCount
              isTaxExempt
              subTotal
              items {
                id
                quantity
                sku
                type
                name
                price
                basePrice
                total
                baseTotal
                taxPercent
                taxAmount
                baseTaxAmount
                cartId
                productId
                productFlat {
                  id
                }
                designPersonalizations {
                  attributeId
                  textValue
                  jsonValue
                  integerValue
                  attributeOption {
                    id
                    adminName
                    swatchValue
                    attributeOptionPreview {
                      image
                      hexCodes
                    }
                  }
                  attribute {
                    adminName
                    type
                    code
                  }
                }
                additional 
              }
            }
          }
        }
      `,
      variables: {
        cart_id: cart_id,
        is_tax_exempt: is_tax_exempt,
      },
    }),
  });
};

export default addTaxExemption;
