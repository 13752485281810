import axios, { AxiosResponse } from "axios";

const updateCustomerInfo = (
  token: string,
  firstName: string,
  lastName: string,
  email: string,
): Promise<AxiosResponse> => {
  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: `  mutation updateAccount ($firstName: String!, $lastName: String!, $email: String! ){
            updateAccount(input: {
                firstName: $firstName
                lastName: $lastName
                email: $email
            }) {
            customer{
                id
                firstName
                lastName         
                email
                addresses {
                    id
                    address1
                    address2
                    city
                    state
                    postcode
                  }
            }
            }
        }
      `,
      variables: {
        firstName: `${firstName}`,
        lastName: `${lastName}`,
        email: `${email}`,
      },
    }),
  });
};

export default updateCustomerInfo;
