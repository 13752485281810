/*
 *Getting data about Product Page
 */
import axios, { AxiosResponse } from "axios";

/*Getting Access Token*/
const getWristbandAttributes = (slug: string): Promise<AxiosResponse> => {
  /*Getting the product by slug*/
  return axios
    .request({
      method: "POST",
      url: `/graphql`,
      headers: {
        "Content-type": "application/json",
      },
      data: JSON.stringify({
        query: `    query product ($slug: String!){
         productBySlug(slug: $slug) {
          id
          description
          shortDescription
          urlKey
          name
      
          product {
            superAttributes {
              code
              adminName
              options {
                id
                adminName
                swatchValue
              }
            }
            variants {
              id
              superAttributesValues {
                code
                admin_name
                value
              }
              productFlats {
                price
                name
              }
            }
          }    
         }
       }
   `,
        variables: {
          slug: `${slug}`,
        },
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export default getWristbandAttributes;
