/**
 * Gallery with the pictures
 * */

import { IonContent, IonPage, IonGrid, IonRow } from "@ionic/react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SectionTitle from "../../components/Titles/SectionTitle";
import AdBanner from "../../components/AddBanner/AddBanner";
import GalleryPicture from "./components/GalleryPicture/GalleryPicture";
import { IImages } from "../../types/IImages";
//Import Mocs with the pictures
import GalleryList from "../../fixtures/GalleryMocs";

const Gallery: React.FC = () => {
  return (
    <IonPage className="gallery">
      <Header />
      <IonContent color="light">
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">
                    View Custom Wristband Samples & Types{" "}
                  </span>
                </h2>
              }
              class="title-dark"
            />
            <AdBanner />
          </div>
          <div className="fixed-container fixed-container-white">
            <IonGrid>
              <IonRow>
                {/**Showing the images from the gallery */}
                {GalleryList.map((item: IImages) => (
                  <GalleryPicture item={item} key={item.id} current={item.id} />
                ))}
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Gallery;
