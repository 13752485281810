import React from "react";

interface IProductImageParams {
  image: string;
  title: string;
}

const ProductImage: React.FC<IProductImageParams> = (props) => {
  return (
    <img
      className="prices-img"
      src={`storage/${props.image}`}
      alt={props.title}
    />
  );
};

export default ProductImage;
