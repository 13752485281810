import axios, { AxiosResponse } from "axios";

const getShippingMethods = (
  token: string,
  guestId: string | null = null,
  cartId: number | string = 0,
): Promise<AxiosResponse> => {
  const cart_id =
    cartId === 0 || typeof cartId === "number" ? 0 : parseInt(cartId, 10);

  const headers = {
    Authorization: token,
    "Content-type": "application/json",
  };

  if (guestId && !token.includes("Bearer")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["Guest-Identifier"] = guestId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headers["x-cart-id"] = cart_id;
  }

  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers,
    data: JSON.stringify({
      query: ` query shippingMethods  {
        shippingMethods {
          success
          shippingMethods {
                    title
                    methods {
                        code
                        label
                        price
                        formattedPrice
                        basePrice
                        formattedBasePrice
                    }
                }
                cart {
                  id
                  customerEmail
                  customerFirstName
                  customerLastName
                  shippingMethod
                  discountAmount
                  selectedShippingRate {
                    price
                  }
                  taxTotal
                  grandTotal
                  baseGrandTotal
                  itemsQty
                  itemsCount
                  isTaxExempt
                  subTotal
                  items {
                    id
                    quantity
                    sku
                    type
                    name
                    price
                    basePrice
                    total
                    baseTotal
                    taxPercent
                    taxAmount
                    baseTaxAmount
                    cartId
                    productId
                    productFlat {
                      id
                    }
                    designPersonalizations {
                      attributeId
                      textValue
                      jsonValue
                      integerValue
                      attributeOption {
                        id
                        adminName
                        swatchValue
                        attributeOptionPreview{
                          image
                          hexCodes
                        }
                      }
                      attribute {
                        adminName
                        type
                        code
                      }
                    }
                    additional 
                }  
              }
        }
      }
      `,
    }),
  });
};

export default getShippingMethods;
