import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonSpinner,
} from "@ionic/react";
import { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { CurrentCustomerContext } from "../../../contexts/currentCustomer";
import useLocalStorage from "../../../hooks/useLocalStorage";
import updateCustomerPassword from "../../../services/customer/updateCustomerPassword";
import { ICustomer } from "../../../types/ICustomer";
import { IFormInputError } from "../../../types/IFormInputError";
import "./AccountForm.css";

// Define the types of the form inputs
interface IPasswordFormInputs {
  oldpassword: string;
  password: string;
  passwordConfirmation: string;
}

// Define the types of the parameters passed to the component
interface IPasswordFormParams {
  currentCustomer: ICustomer;
}

// Define the PasswordForm component
const PasswordForm: React.FC<IPasswordFormParams> = ({ currentCustomer }) => {
  // Set up the form using useForm from react-hook-form
  const {
    control, // to control the form inputs
    handleSubmit, // to handle form submission
    watch, // to watch changes in the form inputs
    formState: { errors, isValid }, // to keep track of form errors and validity
  } = useForm<IPasswordFormInputs>({ mode: "all" });

  // Get access to the current customer data and set up the context
  const [, dispatch] = useContext(CurrentCustomerContext);

  // Get the token and customer data from the local storage using a custom hook
  const [token] = useLocalStorage("token");
  const [, setCustomer] = useLocalStorage("customer");

  // Set up state for error messages and success status
  const [backendErrors, setBackendErrors] = useState<IFormInputError[]>([]);
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const onSubmit = (data: IPasswordFormInputs) => {
    setLoading(true);
    // Call the updateCustomerPassword service to update the customer's password
    updateCustomerPassword(
      token,
      currentCustomer.firstName,
      currentCustomer.lastName,
      currentCustomer.email,
      data.oldpassword,
      data.password,
      data.passwordConfirmation,
    )
      .then((response) => {
        setLoading(false);
        if (response.data.errors) {
          const errors: IFormInputError[] = [];
          response.data.errors.forEach((err: { message: string }) => {
            errors.push({
              message: err.message,
              id: "password",
            });
          });

          setBackendErrors(errors);
        } else {
          setBackendErrors([]); // if no errors then remove them
        }

        // Update the customer data in the local storage and context
        if (
          response.data.data &&
          response.data.data.updateAccount &&
          response.data.data.updateAccount.customer
        ) {
          setCustomer(
            JSON.stringify(response.data.data.updateAccount.customer),
          );
          dispatch({
            type: "SET_AUTHORIZED",
            payload: response.data.data.updateAccount.customer,
          });
          setIsSaved(true);
          setBackendErrors([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <IonCard className="account-form-card">
      <IonCardTitle className="account-form-card-title">
        Change Password
      </IonCardTitle>
      <IonCardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isSaved && <p>Your password has been changed.</p>}
          {backendErrors.length > 0 &&
            backendErrors.map((error) => (
              <span className="account-form-error" key={error.id}>
                {error.message}
              </span>
            ))}
          <Controller
            control={control}
            name="oldpassword"
            rules={{ required: true }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">Old Password</IonLabel>
                <IonInput
                  type="password"
                  value={field.value}
                  onIonInput={field.onChange}
                />
              </IonItem>
            )}
          />
          {errors.oldpassword && (
            <span className="account-form-error">This field is required</span>
          )}

          <Controller
            control={control}
            name="password"
            rules={{ minLength: 6 }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">New Password</IonLabel>
                <IonInput
                  type="password"
                  value={field.value}
                  onIonInput={field.onChange}
                />
              </IonItem>
            )}
          />
          {errors.password && (
            <span className="account-form-error">
              The password should be at least 6 symbols
            </span>
          )}
          <Controller
            control={control}
            name="passwordConfirmation"
            rules={{
              required: true,
              validate: (value) =>
                value === watch("password") || "Passwords do not match",
            }}
            render={({ field }) => (
              <IonItem>
                <IonLabel position="floating">Confirm New Password</IonLabel>
                <IonInput
                  type="password"
                  value={field.value}
                  onIonInput={field.onChange}
                />
              </IonItem>
            )}
          />
          {errors.passwordConfirmation && (
            <span className="account-form-error">
              {errors.passwordConfirmation.message}
            </span>
          )}
          <div className="account-form-button-container">
            <IonButton type="submit" disabled={isValid === false}>
              <span className="button-text">Change Password</span>
            </IonButton>
            {loading && <IonSpinner name="lines" />}
          </div>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default PasswordForm;
