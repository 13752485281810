import axios, { AxiosResponse } from "axios";

const addToCart = (
  productId: number,
  quantity: number,
  selectedConfigurableOption: number,
  token?: string,
): Promise<AxiosResponse> => {
  return axios.request({
    method: "POST",
    url: "/graphql",
    withCredentials: true,
    headers: {
      Authorization: token ? token : "",
      "Content-type": "application/json",
    },
    data: JSON.stringify({
      query: `  mutation addItemToCart ($productId: ID!, $quantity: Int!, $selectedConfigurableOption: Int!) {
        addItemToCart(input: {
            productId: $productId
            quantity: $quantity
          selectedConfigurableOption: $selectedConfigurableOption
        }) {
            status
            message
            cart {
                id
                customerEmail
                customerFirstName
                customerLastName
                shippingMethod
                grandTotal
                baseGrandTotal
                isTaxExempt
                subTotal
                items {
                  id
                  quantity
                  sku
                  type
                  name
                  price
                  basePrice
                  total
                  baseTotal
                  taxPercent
                  taxAmount
                  baseTaxAmount
                  cartId
                  additional 
              }
                
            }
        }
    }
      `,
      variables: {
        productId: productId,
        quantity: quantity,
        selectedConfigurableOption: selectedConfigurableOption,
      },
    }),
  });
};

export default addToCart;
