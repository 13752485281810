import React, { useEffect, useRef } from "react";
import { IColorCombination } from "../../../../types/IColorCombination";
import ColorCombinationBlock from "./components/colorCombinationBlock/ColorCombinationBlock";
import AddBlockButton from "./components/AddBlockButton/AddBlockButton";
import { IProductOptions } from "../../../../types/IProductOptions";
import { IAttributes } from "../../../../types/IAttributes";
import "./QuantityForm.css";

interface IQuantityFormParams {
  colorCombinations: IColorCombination[];
  setColorCombinations: any;
  selectCombination: (index: number) => void;
  setStyleColorSelected: (style: IAttributes | undefined) => void;
  styleColorSelected: IAttributes | undefined;
  colorsCollection: IProductOptions[] | undefined;
  textColorOptions: IProductOptions[];
  selectedCombination: number;
  setSelectedCombination: (index: number) => void;
  updateCombination: any;
  addColorCombination: () => void;
  addColorCombinations: () => void;
  quantity: number;
  eachCost: number | undefined;
  totalCost: number;
}

const QuantityForm: React.FC<IQuantityFormParams> = (props) => {
  //Function to delete color combination
  const handleDeleteColorCombination = (index: number) => {
    const updatedCombinations = [...props.colorCombinations];
    updatedCombinations.splice(index, 1);
    props.setColorCombinations(updatedCombinations);
  };

  //changing selected combination if it is deleted
  useEffect(() => {
    if (props.colorCombinations.length <= props.selectedCombination) {
      props.setSelectedCombination(0);
    }
  }, [props.colorCombinations]);

  //automatic scroll to the new added combination
  const colorCombinationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (colorCombinationRef.current) {
      const { current } = colorCombinationRef;
      current.scrollTop = current.scrollHeight;
    }
  }, [props.colorCombinations.length]);

  return (
    <section className="order-section">
      <div className="order-section-header">
        <div className="order-page-container">
          <h2 className="order-section-title">Your Quantities and Sizes:</h2>
        </div>
      </div>

      <div>
        <div className="color-combination-blocks" ref={colorCombinationRef}>
          {props.colorCombinations &&
            props.colorCombinations.length > 0 &&
            props.colorCombinations.map(
              (combination: IColorCombination, index: number) => {
                return (
                  <div
                    onClick={() => props.selectCombination(index)}
                    key={index}
                  >
                    <ColorCombinationBlock
                      colorCombination={combination}
                      setStyleColorSelected={props.setStyleColorSelected}
                      styleColorSelected={props.styleColorSelected}
                      colorsCollection={props.colorsCollection}
                      textColorOptions={props.textColorOptions}
                      selectedCombination={props.selectedCombination}
                      setSelectedCombination={props.selectCombination}
                      updateCombination={props.updateCombination}
                      addColorCombination={props.addColorCombination}
                      colorCombinations={props.colorCombinations}
                      deleteColorCombination={() =>
                        handleDeleteColorCombination(index)
                      }
                      blockIndex={index}
                    />
                  </div>
                );
              },
            )}
        </div>
        <AddBlockButton addColorCombination={props.addColorCombination} />
      </div>
      <div className="order-page-container">
        <div className="prices-line">
          <div className="price-block">
            <span className="quantity-size-label">Quantity:</span>
            <span className="price-amount">{`${props.quantity}`}</span>
          </div>
          <div className="price-block">
            <span className="quantity-size-label">Price(each):</span>
            <span className="price-amount"> {`$${props.eachCost}`}</span>
          </div>
          <div className="price-block">
            <span className="quantity-size-label">Total:</span>
            <span className="price-amount">{`$ ${props.totalCost}`}</span>
          </div>
        </div>
      </div>
    </section>
  );
};
export default QuantityForm;
