// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
    text-align: center;
}
.btn-slider,
.btn-slider:hover,
.btn-slider:active {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    max-width: 100px;
    margin-top: 30px;
}
.btn-divider {
    --ripple-color: transparent;
}

@media (max-width:500px) {
    .btn-slider,
    .btn-slider:hover,
    .btn-slider:active {
        font-size: 22px;
        margin-top: 20px;
    }
}
@media (max-width:400px) {
    .btn-slider,
    .btn-slider:hover,
    .btn-slider:active {
        font-size: 18px;
        margin-top: 15px;
    }
}
@media (max-width:320px) {
    .btn-slider,
    .btn-slider:hover,
    .btn-slider:active {
        font-size: 16px;
        margin-top: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ProductList/ProductListSlider.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;;;IAGI,WAAW;IACX,oCAAoC;IACpC,gBAAgB;IAChB,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI;;;QAGI,eAAe;QACf,gBAAgB;IACpB;AACJ;AACA;IACI;;;QAGI,eAAe;QACf,gBAAgB;IACpB;AACJ;AACA;IACI;;;QAGI,eAAe;QACf,aAAa;IACjB;AACJ","sourcesContent":[".slider {\r\n    text-align: center;\r\n}\r\n.btn-slider,\r\n.btn-slider:hover,\r\n.btn-slider:active {\r\n    color: #fff;\r\n    font-family: \"Open Sans\", sans-serif;\r\n    font-weight: 700;\r\n    font-size: 30px;\r\n    text-transform: uppercase;\r\n    max-width: 100px;\r\n    margin-top: 30px;\r\n}\r\n.btn-divider {\r\n    --ripple-color: transparent;\r\n}\r\n\r\n@media (max-width:500px) {\r\n    .btn-slider,\r\n    .btn-slider:hover,\r\n    .btn-slider:active {\r\n        font-size: 22px;\r\n        margin-top: 20px;\r\n    }\r\n}\r\n@media (max-width:400px) {\r\n    .btn-slider,\r\n    .btn-slider:hover,\r\n    .btn-slider:active {\r\n        font-size: 18px;\r\n        margin-top: 15px;\r\n    }\r\n}\r\n@media (max-width:320px) {\r\n    .btn-slider,\r\n    .btn-slider:hover,\r\n    .btn-slider:active {\r\n        font-size: 16px;\r\n        margin-top: 0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
