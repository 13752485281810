/*
 *Component is to displasy every single cart item
 *Cart items are mapping from the cart
 */
import { IonIcon, IonModal, IonButton } from "@ionic/react";
import { useState, useContext, useEffect, useCallback } from "react";
//Icons
import trashCan from "../../../../assets/icon/trash_can.svg";
import chevronUp from "../../../../assets/icon/chevron-up.svg";
import chevronDown from "../../../../assets/icon/chevron-down.svg";
import closeButton from "../../../../assets/icon/close_button.svg";
//Components
import ColorCombination from "./components/colorCombinationBlock/ColorCombination";
//functions, services and custom hooks
import updateCartItems from "../../../../services/cart/updateCartItems";
import removeCartItem from "../../../../services/cart/removeCartItem";
import updateCombination from "../../../../functions/cart/updateCombination";
import { handleUpdateItemResponse } from "../../../../functions/cart/handleUpdateResponse";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import useDebounce from "../../../../hooks/useDebounce";
//Types
import { CartContext } from "../../../../contexts/cart";
import { AxiosResponse } from "axios";
import { ICartItemPersonalization } from "../../../../types/Cart/ICartItemPersonalization";
import { IColorCombination } from "../../../../types/IColorCombination";
import { ICartItem } from "../../../../types/Cart/ICartItem";
import { IProductOptions } from "../../../../types/IProductOptions";
import "./CartItem.css";
import { IQuantityToUpdate } from "../../../../types/Cart/IQuantityToUpdate";

interface ICartItemParams {
  cartItem: ICartItem;
  cartId: number;
}

const CartItem: React.FC<ICartItemParams> = ({ cartItem, cartId }) => {
  const [token] = useLocalStorage("token");

  //variables to store updated cart in context and local storage
  const [, setCart] = useLocalStorage("cart");
  const [, dispatch] = useContext(CartContext);

  //retriving information about cart item
  const jsonCartData = JSON.parse(cartItem.additional);
  const messageType = cartItem.designPersonalizations.find(
    (item: IProductOptions) => item.attribute.code === "band_message_type",
  )?.attributeOption.swatchValue;
  const textValueForBandFrontMessage =
    cartItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "band_front_message",
    )?.textValue || "Your Front Message can be here";
  const textValueForBandBackMessage =
    cartItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "band_back_message",
    )?.textValue || "Your Back Message can be here";
  const textValueForWrapMessage =
    cartItem.designPersonalizations.find(
      (item: IProductOptions) =>
        item.attribute.code === "band_wrap_around_message",
    )?.textValue || "Your Message can be here";
  const textValueForInsideMessage =
    cartItem.designPersonalizations.find(
      (item: IProductOptions) => item.attribute.code === "band_inside_message",
    )?.textValue || "Your Message can be here";
  const [colorCombinations, setColorCombinations] = useState<
    IColorCombination[]
  >([]);
  const [guestId] = useLocalStorage("guestId");

  useEffect(() => {
    const colorCombosJson = cartItem.designPersonalizations.find(
      (p) => p.attribute.code === "band_color_combinations",
    )?.jsonValue;
    if (colorCombosJson) {
      try {
        const colorCombos = JSON.parse(colorCombosJson);
        setColorCombinations(colorCombos);
      } catch (error) {
        console.error("Failed to parse color combinations:", error);
        setColorCombinations([]);
      }
    }
  }, [cartItem]);

  //Getting color combination attribute Id (it is needed to send update request for quantities);
  const colorCombinationsAttributeId =
    cartItem.designPersonalizations.find(
      (item: IProductOptions) =>
        item.attribute.code === "band_color_combinations",
    )?.attributeId || null;

  //variable to initiate item updation, when it is ready
  const [readyToUpdate, setReadyToUpdate] = useState<boolean>(false);

  //Variables which are using to store different kinds of updates
  const [quantityArray, setQuantityArray] = useState<IQuantityToUpdate[]>(); // form nessasary array to update quantity for the item (adult+large+youth)
  const [designPersonalizationsArray, setDesignPersonalizationsArray] =
    useState<[ICartItemPersonalization]>(); //updates design personalizations, inside cart item component only color combinations can be updated

  /*Handling quantities*/
  const [quantity, setQuantity] = useState<number>(0);
  //setting quantity array when quantity is changed
  useEffect(() => {
    setQuantityArray([{ cartItemId: cartItem.id, quantity: quantity }]);
  }, [quantity]);

  //update the quantity
  useEffect(() => {
    // Initialize a variable to hold the total sum
    let totalSum = 0;

    // Iterate over each combination and add the values
    colorCombinations.forEach((combination: IColorCombination) => {
      totalSum +=
        (Number(combination.youth) || 0) +
        (Number(combination.large) || 0) +
        (Number(combination.adult) || 0);
    });
    // Update the quantity state with the total sum
    setQuantity(totalSum);
  }, [colorCombinations]); // Dependency array to run this effect when colorCombinations changes

  /*Handling color combinations*/

  //variable to define the active color combination, it can be needed for color combination update or removal
  const [selectedCombination, setSelectedCombination] = useState<
    number | undefined
  >(undefined);

  //function to define selected combination
  const selectCombination = (index: number) => {
    setSelectedCombination(index);
  };

  type CombinationField = "color" | "textColor" | "youth" | "adult" | "large";

  //update color combinations
  const handleUpdateCombination = useCallback(
    (index: number, field: CombinationField, value: string) => {
      updateCombination(
        colorCombinations,
        setColorCombinations,
        index,
        field,
        value,
      );
    },
    [colorCombinations, setColorCombinations],
  );

  //forming design personalization array
  useEffect(() => {
    if (colorCombinationsAttributeId && colorCombinations && cartItem) {
      setDesignPersonalizationsArray([
        {
          cartItemId: cartItem.id,
          designPersonalizations: [
            {
              attributeId: +colorCombinationsAttributeId,
              jsonValue: JSON.stringify(colorCombinations),
            },
          ],
        },
      ]);
    }
  }, [colorCombinations, colorCombinationsAttributeId]);

  //Automated updating the quantities when quantities for adult, youth or large are changed by customer
  useEffect(() => {
    if (readyToUpdate && quantityArray && designPersonalizationsArray) {
      updateItemToCart(quantityArray, designPersonalizationsArray);
    }
  }, [designPersonalizationsArray, quantityArray]);

  //update quantities for color combinations
  const updateItemToCart = useDebounce(
    (
      quantity: IQuantityToUpdate[],
      designPersonalizations: ICartItemPersonalization[],
    ) => {
      const authToken = token || ""; // Use an empty string if token is not available
      const guestIdentifier = guestId || ""; // Use an empty string if guestId is not available
      updateCartItems(
        quantity,
        designPersonalizations,
        authToken,
        guestIdentifier,
        cartId,
      )
        .then((response) => {
          handleUpdateItemResponse(response, setCart, dispatch)
            .then(() => {
              console.log("Update was successful!");
            })
            .catch((error) => {
              console.error("Error after updating item response:", error);
            });
        })
        .catch((error) => {
          console.error("Error updating cart items:", error);
        });
      setReadyToUpdate(false);
    },
    500,
  );

  //Handle remove item response
  const handleRemoveItemResponse = (response: AxiosResponse) => {
    if (response.data.errors) {
      alert(response.data.errors[0].debugMessage);
      return;
    }
    setCart(JSON.stringify(response.data.data.removeCartItem.cart));
    dispatch({
      type: "UPDATE_ITEM",
      payload: response.data.data.removeCartItem.cart,
    });
  };

  // delete color combination, if no color combinations left, remove the item
  const handleDeleteColorCombination = (index: number) => {
    const updatedCombinations = [...colorCombinations];
    updatedCombinations.splice(index, 1);
    if (updatedCombinations.length > 0) {
      setColorCombinations(updatedCombinations);
    } else {
      removeCartItemFromTheCart();
    }
  };

  //remove item from the cart
  const removeCartItemFromTheCart = () => {
    removeCartItem(cartItem.id, token || "", guestId || "", cartId)
      .then((response) => {
        handleRemoveItemResponse(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //Modal window to confirm if the customer really want to delete the item from the cart
  const [showCartItemModal, setShowCartItemModal] = useState<boolean>(false);
  const handleAgreeToRemoveItem = () => {
    removeCartItemFromTheCart();
    setShowCartItemModal(false);
  };

  //the variavle is to control expanding and hiding color combinations
  const [showCombos, setShowCombos] = useState<boolean>(true);

  return (
    <div className="cart-item">
      <div className="cart-item-main-info">
        <div className="wide-options-container cart-item-content">
          <div className="cart-item-info-content">
            <div className="cart-item-main-part-one">
              <p className="cart-item-variant-data">
                <span>{jsonCartData.attributes.band_width.option_label}</span>
                <span>{jsonCartData.attributes.band_style.option_label}</span>
              </p>
              <p className="cart-item-data">
                <span>Font:</span>
                <span>
                  {cartItem.designPersonalizations.find(
                    (item: IProductOptions) =>
                      item.attribute.code === "font_swatch",
                  )?.attributeOption.adminName || "Default Value"}
                </span>
              </p>
              <p className="cart-item-data">
                <span>Font Size:</span>
                <span>
                  {cartItem.designPersonalizations.find(
                    (item: IProductOptions) =>
                      item.attribute.code === "band_text_size",
                  )?.attributeOption.adminName || "Default Value"}
                </span>
              </p>
              <p className="cart-item-data">
                <span>Quantity:</span>
                <span>{cartItem.quantity}</span>
              </p>
            </div>
            <div>
              {messageType === "front_and_back" && (
                <>
                  <p className="cart-item-message">
                    {textValueForBandFrontMessage}
                  </p>
                  <p className="cart-item-message">
                    {textValueForBandBackMessage}
                  </p>
                </>
              )}
              {messageType === "wrap_around" && (
                <p className="cart-item-message">{textValueForWrapMessage}</p>
              )}

              <p className="cart-item-message">{textValueForInsideMessage}</p>
              <p className="cart-item-variant-data">
                Price: ${cartItem.price}/each
              </p>
            </div>
            <div className="cart-icon-block">
              <IonIcon
                className="trash-can-button"
                slot="end"
                icon={trashCan}
                onClick={() => {
                  setShowCartItemModal(true);
                }}
              />
              <IonIcon
                className="trash-can-button blue-icon-button"
                slot="end"
                icon={showCombos ? chevronUp : chevronDown}
                onClick={() => setShowCombos(!showCombos)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="order-page-container">
        <div className="cart-items-colorcombinations-list">
          {showCombos &&
            colorCombinations.map(
              (colorCombination: IColorCombination, index: number) => (
                <div key={index}>
                  <div onClick={() => selectCombination(index)}>
                    <ColorCombination
                      colorCombination={colorCombination}
                      colorCombinations={colorCombinations}
                      colorCombimbinationsAtributeId={
                        colorCombinationsAttributeId
                      }
                      cartItemId={cartItem.id}
                      selectCombination={selectCombination}
                      selectedCombination={selectedCombination}
                      setSelectedCombination={setSelectedCombination}
                      updateCombination={handleUpdateCombination}
                      setReadyToUpdate={setReadyToUpdate}
                      deleteColorCombination={handleDeleteColorCombination}
                      cartItem={cartItem}
                    />
                  </div>
                </div>
              ),
            )}
        </div>
      </div>
      <IonModal
        style={{ "--backdrop-opacity": "0" }}
        isOpen={showCartItemModal}
        onDidDismiss={() => setShowCartItemModal(false)}
        className="modal-cart-item-container"
      >
        <section className="order-section">
          <div className="clipart-modal-container">
            <div className="order-section-header">
              <div className="order-page-container">
                <div className="title-with-button position-end">
                  <IonIcon
                    className="close-button"
                    slot="end"
                    icon={closeButton}
                    onClick={() => {
                      setShowCartItemModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-clipart-content-container">
              <div className="modal-clipart-button-container">
                <p className="modal-cart-item-text">
                  Are you sure you want to delete this option?
                </p>
                <div className="message-options-style-container message-options-style-container-narrow">
                  <IonButton
                    fill="clear"
                    className="option-button option-button-cart-modal ion-no-margin option-button-deleted"
                    onClick={() => handleAgreeToRemoveItem()}
                  >
                    Yes
                  </IonButton>
                  <IonButton
                    fill="clear"
                    className="option-button option-button-cart-modal ion-no-margin"
                    onClick={() => setShowCartItemModal(false)}
                  >
                    No
                  </IonButton>
                </div>
              </div>
            </div>
          </div>
        </section>
      </IonModal>
    </div>
  );
};

export default CartItem;
