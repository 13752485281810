// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clipart-container {
  margin-bottom: 20px;
}
.clipart-item {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.clipart-item img {
  height: 80px;
  width: 80px;
  object-fit: contain;
}

/***Media***/

@media (max-width: 1000px) {
  .clipart-item img {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 767px) {
  .clipart-item img {
    height: 70px;
    width: 70px;
  }
}

@media (max-width: 500px) {
  .clipart-item img {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 350px) {
  .clipart-item img {
    height: 50px;
    width: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ClipartBar/ClipartBar.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA,YAAY;;AAEZ;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".clipart-container {\n  margin-bottom: 20px;\n}\n.clipart-item {\n  display: flex;\n  justify-content: center;\n  margin-top: 15px;\n}\n.clipart-item img {\n  height: 80px;\n  width: 80px;\n  object-fit: contain;\n}\n\n/***Media***/\n\n@media (max-width: 1000px) {\n  .clipart-item img {\n    height: 60px;\n    width: 60px;\n  }\n}\n\n@media (max-width: 767px) {\n  .clipart-item img {\n    height: 70px;\n    width: 70px;\n  }\n}\n\n@media (max-width: 500px) {\n  .clipart-item img {\n    height: 60px;\n    width: 60px;\n  }\n}\n\n@media (max-width: 350px) {\n  .clipart-item img {\n    height: 50px;\n    width: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
