import { IonPage } from "@ionic/react";

import axios from "axios";

const Webhook: React.FC = () => {
  let errorExists = true;
  const fetchSitemapFromBackend = async () => {
    return await axios
      .request({
        method: "GET",
        url: "/sitemap",
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data) {
          console.log("response_returned", { dat: res.data });
          errorExists = false;
        }
      })
      .catch((err) => {
        if (errorExists) {
          console.log("ERROR_FETCHING_SITEMAP", {
            error: (err.response && err.response.data) || {},
          });
        }
      });
  };

  setTimeout(() => {
    fetchSitemapFromBackend();
  }, 50);
  return (
    <IonPage>
      <h2>Received</h2>
    </IonPage>
  );
};

export default Webhook;
