// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-link {
    font-size:16px;
    font-family: 'Open Sans', sans-serif;
    margin-right: 10px;
    line-height: 1em;
}
.link-selected {
    font-weight: 800;
}`, "",{"version":3,"sources":["webpack://./src/components/LinksWidget/components/LinksWidgetItem.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,oCAAoC;IACpC,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".item-link {\n    font-size:16px;\n    font-family: 'Open Sans', sans-serif;\n    margin-right: 10px;\n    line-height: 1em;\n}\n.link-selected {\n    font-weight: 800;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
