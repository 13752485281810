import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useEffect, useState, FormEvent, useContext } from "react";
import { useParams } from "react-router";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import CustomField from "../../components/FormInputs/CustomField";
import Header from "../../components/Header/Header";
import SectionTitle from "../../components/Titles/SectionTitle";
import { useResetFields } from "../../functions/authInputFields";
import "./Auth.css";
import { IFormInputError } from "../../types/IFormInputError";
import { validateForm } from "../../functions/utils";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { IFormValidationRule } from "../../types/IFormValidationRule";
import { IFormField } from "../../types/IFormField";
import useLocalStorage from "../../hooks/useLocalStorage";
import { CurrentCustomerContext } from "../../contexts/currentCustomer";
import { CartContext } from "../../contexts/cart";
import getCart from "../../services/cart/getCart";

const ResetPassword: React.FC = () => {
  const params = useParams();
  const fields = useResetFields();
  const initialErrors: IFormInputError[] = [];
  const [errors, setErrors] = useState(initialErrors);
  const history = useHistory();
  const [token, setToken] = useLocalStorage("token");
  const [customer, setCustomer] = useLocalStorage("customer");
  const [, dispatch] = useContext(CurrentCustomerContext);
  const [, dispatchCart] = useContext(CartContext);
  const [cart, setCart] = useLocalStorage("cart");
  const [loading, setLoading] = useState(false);
  const [successMessage] = useState("");
  const { token: encodedToken } = useParams<{ token: string }>();

  const [customerEmail, setCustomerEmail] = useState("");
  const [decodedToken, setDecodedToken] = useState("");

  useEffect(() => {
    if (encodedToken) {
      const verificationToken = atob(encodedToken);
      const expandedToken = verificationToken.split(":");

      setCustomerEmail(expandedToken[1]);
      setDecodedToken(expandedToken[0]);
    }
  }, [encodedToken]);

  useEffect(() => {
    if (customerEmail !== "") {
      const emailInput = document.getElementById("email") as HTMLInputElement;

      if (emailInput) {
        emailInput.value = customerEmail;

        emailInput.dispatchEvent(new Event("ionChange", { bubbles: true }));
        emailInput.dispatchEvent(new Event("ionInput", { bubbles: true }));

        emailInput.readOnly = true;
        emailInput.disabled = true;
      }
    }
  }, [customerEmail, decodedToken]);

  useEffect(() => {
    if (token && customer && cart !== undefined) {
      history.push("/account");
    }
  }, [token, customer, cart, history]);

  const getValidationRules = (): IFormValidationRule[] => {
    const rules: IFormValidationRule[] = [];

    const passwordRules: IFormValidationRule = {
      fieldId: "password",
      validate(field: IFormField): IFormInputError | null | undefined {
        const password = field.input.state.value;
        const id = "password";

        if (!(password && password.length)) {
          return { id, message: "The password is required." };
        }

        if (password.length < 6) {
          return { id, message: "Password must be at least 6 characters." };
        }

        return null;
      },
    };

    const emailRule: IFormValidationRule = {
      fieldId: "email",
      validate(field: IFormField): IFormInputError | null | undefined {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const id = "email";
        const email: string | undefined = field.input.state.value;

        if (!(email && email.length && email.trim() !== "")) {
          return { id, message: "The email is required." };
        }

        if (!email.match(emailRegex)) {
          return { id, message: "Please enter a valid email address." };
        }

        return null;
      },
    };

    rules.push(passwordRules);
    rules.push(emailRule);

    return rules;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      login(event as unknown as FormEvent<HTMLFormElement>);
    }
  };

  const login = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitResetData(event);
  };

  const submitResetData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validateForm(fields, getValidationRules());
    setErrors(errors);

    if (errors.length) {
      return;
    }
    setLoading(true);
    const formData: { [key: string]: string | boolean | undefined } = {};

    fields.forEach((field) => {
      formData[field.name] = field.input.state.value;
    });

    // add the email and verification token to the form data
    formData["email"] = customerEmail;
    formData["token"] = decodedToken;

    axios
      .request({
        method: "POST",
        url: "/graphql",
        headers: {
          "Content-type": "application/json",
        },
        data: JSON.stringify({
          query: `mutation ($token: String!, $email: String!, $password: String!, $password_confirmation: String!) {
            resetPassword(input : {
              email: $email
              password: $password
              password_confirmation: $password_confirmation
              token: $token
            }) 
            {
              status
              success
              accessToken
              customer {
                id
                firstName
                lastName
                email
                addresses {
                  id
                  address1
                  address2
                  city
                  state
                  postcode
                }
              }
            }
          }`,
          variables: {
            email: `${formData.email}`,
            password: `${formData.password}`,
            password_confirmation: `${formData.password_confirmation}`,
            token: `${formData.token}`,
          },
        }),
      })
      .then((res) => {
        setLoading(false);
        if (res.data && res.data.errors) {
          if (res.data.errors[0] && res.data.errors[0].debugMessage) {
            throw Error(res.data.errors[0].debugMessage);
          } else {
            throw Error(JSON.stringify(res.data.errors[0]));
          }
        } else {
          if (res.data && res.data.data) {
            /** RESPONSE FORMAT of res.data.data
               * {
                "resetPassword": {
                  "status": true,
                  "accessToken": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vcmFwaWQyLXdlYnNlcnZlci9ncmFwaHFsIiwiaWF0IjoxNjc4MjkxNzc0LCJleHAiOjE2NzgyOTUzNzQsIm5iZiI6MTY3ODI5MTc3NCwianRpIjoiaEgzalpqaERoWDdLR2JLcCIsInN1YiI6IjIiLCJwcnYiOiI4ZmNhMDg4YWJhZTJmOWE4Zjg0YTVmMGJmNmE2NTI0NDkwNTViZTAwIn0.ZTq_OvSVLYSuLTpTH5u7POhYz6_LIxYGbPM2eYOjIF0",
                  "customer": {
                    "id": "1",
                    "firstName": "Jane",
                    "lastName": "Doe",
                    "email": "jane@hello.com"
                  }
                }
              }
                */
            if (res.data.data.resetPassword.status) {
              setToken(res.data.data.resetPassword.accessToken);
              setCustomer(JSON.stringify(res.data.data.resetPassword.customer));
              //sending data in customer context
              dispatch({
                type: "SET_AUTHORIZED",
                payload: res.data.data.resetPassword.customer,
              });
              //getting cart data
              getCart(res.data.data.resetPassword.accessToken)
                .then((response) => {
                  console.log(response.data.data.cartDetail);
                  setCart(JSON.stringify(response.data.data.cartDetail));
                  dispatchCart({
                    type: "SAVE_CART",
                    payload: response.data.data.cartDetail,
                  });
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        const inputErrors: IFormInputError[] = [];
        if (err.message && typeof err.message === "string") {
          const tryParsingJson = (jsonString: string) => {
            try {
              const obj = JSON.parse(jsonString);

              if (obj && typeof obj === "object") {
                return obj;
              }
            } catch (e) {}

            return false;
          };
          const testObj = tryParsingJson(err.message);

          const checkForWords = (str: string, words: string[]) => {
            for (let i = 0; i < words.length; ++i) {
              if (str.toLowerCase().includes(words[i])) {
                return {
                  found: true,
                  word: words[i],
                };
              }
            }

            return { found: false, word: null };
          };

          const handleGenericErrors = () => {
            for (const [, value] of Object.entries(fields)) {
              if (typeof value === "object" && value.id) {
                inputErrors.push({
                  id: value.id,
                  message: "Error processing request.",
                });
              }
            }
          };

          if (typeof testObj === "object" && Object.keys(testObj).length > 0) {
            // if it contains things like extensions and locations, it shouldn't be shown to the user
            if ("extensions" in testObj && "locations" in testObj) {
              let fieldError = false;

              if (testObj.message && typeof testObj.message === "string") {
                const keys = fields.map((item) => item.id);
                const found = checkForWords(testObj.message, keys);

                if (found.found && found.word) {
                  fieldError = true;
                  inputErrors.push({
                    id: found.word,
                    message: testObj.message,
                  });
                }
              }

              if (!fieldError) {
                for (const [, value] of Object.entries(fields)) {
                  if (typeof value === "object" && value.id) {
                    const msg =
                      (testObj.extensions && testObj.extensions.reason) ||
                      "Error processing request.";
                    inputErrors.push({
                      id: value.id,
                      message: msg.toLowerCase(),
                    });
                  }
                }
              }
            } else {
              handleGenericErrors();
            }
          } else {
            handleGenericErrors();
          }
        } else {
          console.log("NOT_DECIDED_ON_THE_AUTH_ERRORS", {
            error: err.response ? err.response : err,
          });
        }

        setErrors(inputErrors);
      });
  };

  useEffect(() => {
    return () => {
      fields.forEach((field) => field.input.state.reset(""));
      setErrors([]);
    };
  }, [params]);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen color="light">
        {loading && (
          <div className="form-overlay">
            <IonSpinner name="lines" />
          </div>
        )}
        <section className="page-section">
          <div className="fixed-container">
            <SectionTitle
              title={
                <h2>
                  <span className="title-bold">Reset Password</span>
                </h2>
              }
              class="title-dark"
            />
          </div>
          <div className="fixed-container fixed-container-white small-paddings">
            <IonGrid className="ion-padding">
              <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
                {successMessage != "" && <h4>{successMessage}</h4>}
                <IonCol
                  size="12"
                  size-md="6"
                  size-lg="6"
                  offset-lg="3"
                  offset-md="3"
                  className="text-align-left"
                >
                  <form id="resetForm" onSubmit={submitResetData}>
                    {fields.map((field) => {
                      return (
                        <CustomField
                          key={field.id}
                          field={field}
                          errors={errors}
                          {...(field.id === "password_confirmation"
                            ? { onKeyDown: handleKeyPress }
                            : {})}
                        />
                      );
                    })}
                    <Button
                      type="submit"
                      className="auth-cta"
                      id="resetPasswordBtn"
                      text="Reset Password"
                    ></Button>
                  </form>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
