/*
 *Function saves files in storage/design derictory and return URL
 */

import axios from "axios";

const saveDesignFiles = async (file: File, token: string) => {
  if (file) {
    const operations = JSON.stringify({
      query: `
          mutation ($file: Upload!) {
            uploadDesignFiles(file: $file)
          }
        `,
      variables: { file: file },
    });

    const map = JSON.stringify({ "0": ["variables.file"] });

    const formData = new FormData();

    formData.append("operations", operations);
    formData.append("map", map);
    formData.append("0", file);

    try {
      const response = await axios.post("/graphql", formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      // Set the URL received from the server
      return response.data.data.uploadDesignFiles;
    } catch (error) {
      console.error("An error occurred during file upload:", error);
    }
  }
};

export default saveDesignFiles;
