const AboutFirstBlockMocs: JSX.Element = (
  <div>
    <p>
      Rapidwristbands.com is a premier manufacturer of 100% custom-made silicone
      and Tyvek® wristbands for customers all over the world.
    </p>
    <img src="../../assets/img/about/1.png" />
    <p>
      We help thousands of customers increase their brand awareness, promote
      their causes, raise money for fundraisers, and expand membership numbers
      and affiliations.
    </p>
  </div>
);

export default AboutFirstBlockMocs;
