import Button from "./../Button/Button";
import SectionSubTitle from "../Titles/SectionSubTitle";
import "./TextBlock.css";

interface ITextBlockParams {
  text: JSX.Element;
}

const TextBlockGray: React.FC<ITextBlockParams> = ({ text }) => {
  return (
    <div className="text-block text-block-gray">
      <SectionSubTitle class="title-dark" subtitle={<h3>Need Help?</h3>} />
      {text}
      <Button text="FAQS" />
    </div>
  );
};

export default TextBlockGray;
