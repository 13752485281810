import { IonContent, IonPage } from "@ionic/react";
import Header from "../../components/Header/Header";
import FundCalculator from "../../components/FundCalculator/FundCalculator";

const Resources: React.FC = () => {
  return (
    <IonPage>
      <Header />
      <IonContent ion-padding>
        <h2>Resources</h2>
        <FundCalculator />
      </IonContent>
    </IonPage>
  );
};

export default Resources;
