import { IonButton } from "@ionic/react";
import "./Button.css";

interface IButtonParams {
  text: string;
}

const MainButton: React.FC<IButtonParams> = (props) => {
  return (
    <IonButton
      color="secondary"
      className="button main-button filled-btn ion-text-wrap"
    >
      <span className="button-text">{props.text}</span>
    </IonButton>
  );
};

export default MainButton;
