import { IonCard, IonSpinner } from "@ionic/react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import DeafaultClipartList from "./DefaultClipartList";
import useDebounce from "../../../../../../hooks/useDebounce";
import "./ClipartSearchBlock.css";
import { IClipartSearchItem } from "../../../../../../types/IClipartSearchItem";
import { IProductOptions } from "../../../../../../types/IProductOptions";

interface IClipartSearchParams {
  activeClipart: IProductOptions | undefined;
  showModal: boolean;
  setShowClipartModal: (show: boolean) => void;
  setIconFromSearch: (icon: IClipartSearchItem) => void;
}

const ClipartSearchBlock: React.FC<IClipartSearchParams> = (props) => {
  const [clipartSearchResults, setClipartSearchResults] = useState<
    IClipartSearchItem[]
  >([]); //results from clipart search
  const [inputedIcon, setInputedIcon] = useState<string>(""); //inputed icon from search
  const [deafaultCliparts, setDefautlCliparts] =
    useState<IClipartSearchItem[]>(DeafaultClipartList); //the list of cliparts to display by default
  const [activeCustomerSearch, setActiveCustomerSearch] =
    useState<boolean>(false); // true if the user is searching for a clipart
  const [nextPageToken, setNextPageToken] = useState<string>(""); //token for the next page of search results
  const [iconListToDisplay, setIconListToDisplay] = useState<
    IClipartSearchItem[]
  >([]); //list of icons to display (it can be results from search or default cliparts)
  const [loading, setLoading] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<string>("");

  // Function to perform the GraphQL query to fetch searched icons or popular icons for default list
  const fetchIcons = async (searchIcon: string, nextPageToken: string) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/graphql", // Replace with your actual GraphQL endpoint
        {
          query: `
            query {
              searchIcons(term: "${searchIcon}", nextPageToken: "${nextPageToken}") {
                nextPageToken
                icons {
                  id
                  url
                  term
                }
              }
            }
          `,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      // If the user is searching for a clipart, add the new icons to the existing list
      if (inputedIcon) {
        setClipartSearchResults((prevResults) => [
          ...prevResults, // Keep the existing icons
          ...response.data.data.searchIcons.icons, // Add the new icons
        ]);
      } else {
        setDefautlCliparts((prevResults) => [
          ...prevResults, // Keep the existing icons
          ...response.data.data.searchIcons.icons, // Add the new icons
        ]);
      }
      setNextPageToken(response.data.data.searchIcons.nextPageToken);
    } catch (error) {
      console.error("Error fetching icons:", error);
      setSearchError("There are no search results to display.");
    }
    setLoading(false);
  };

  //set icons to display depending on the active customer search
  useEffect(() => {
    if (activeCustomerSearch) {
      setIconListToDisplay(clipartSearchResults);
    } else {
      setIconListToDisplay(deafaultCliparts);
    }
  }, [activeCustomerSearch, clipartSearchResults, deafaultCliparts]);

  //handling the scroll event (should display more icons if the user scrolled to the bottom);
  const contentRef = useRef<HTMLDivElement>(null);
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollElement = e.target as HTMLDivElement;
    const { scrollTop, scrollHeight, clientHeight } = scrollElement;
    // Check if scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      if (activeCustomerSearch) {
        fetchIcons(inputedIcon, nextPageToken);
      } else {
        fetchIcons("popular", nextPageToken);
      }
    }
  };

  //handle click on search button and press enter
  const handleInitiateSearch = () => {
    if (!inputedIcon) {
      return;
    }
    setActiveCustomerSearch(true);
    setClipartSearchResults([]);
    setNextPageToken("");

    // Call fetchIcons after nextPageToken is set
    fetchIcons(inputedIcon, "");
  };

  const debouncedScrollHandler = useDebounce(handleScroll, 300);

  //save chosen icon to the parent component
  const handleIconClick = (icon: IClipartSearchItem) => {
    props.setIconFromSearch(icon);
    props.setShowClipartModal(false);
  };

  return (
    <>
      <div className="order-page-container">
        <p className="clipart-search-text">
          We guarantee that no one offers as many Clipart symbols and images as
          RapidWristbands.com! Our designers have stockpiled over 2 million
          unique pieces of Clipart.
        </p>

        <div className="clipart-option-search-container">
          <div className="clipart-input-container">
            <input
              type="text"
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                const target = event.target;
                if (target && target.value) {
                  setInputedIcon(target.value);
                }
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter") {
                  handleInitiateSearch();
                }
              }}
              placeholder="Search Clipart"
              className="clipart-input"
            />

            <button
              className="cliipart-search-button"
              onClick={handleInitiateSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div
        className="wide-options-container color-card-content clipart-search-container"
        ref={contentRef}
        onScroll={debouncedScrollHandler}
      >
        {iconListToDisplay &&
          iconListToDisplay.map((icon, index) => (
            <IonCard
              key={index}
              className="clipart-search-card"
              ion-no-margin
              onClick={() => handleIconClick(icon)}
            >
              <img
                src={icon.url}
                alt={`Icon ${icon.id}`}
                className="clipart-search-image"
              />
            </IonCard>
          ))}
        {loading && <IonSpinner name="lines" />}
      </div>
      {searchError && <p>{searchError}</p>}
    </>
  );
};
export default ClipartSearchBlock;
