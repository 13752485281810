const AboutThirdBlockMocs: JSX.Element = (
  <div>
    <h3>Orders</h3>
    <p>
      Order as many as you want, in bulk or separately thanks to our no order
      minimum! With our rush production and next day shipping, you can receive
      your order in as little as one day. Regular orders typically take up to 6
      days to deliver.
    </p>
    <img src="../../assets/img/about/3.png" />
  </div>
);

export default AboutThirdBlockMocs;
